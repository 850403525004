import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// import { useToken } from "../Auth/useToken";
import { Link } from "react-router-dom";

function AddPicture() {

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  // const [token, setToken] = useToken();
  //   const [titre, setTitre] = useState(null);

  return (
    <section className="content">
      <div className="row">
        <div className="col-12">
          <div className="box">
            <div className="box-body">
              <form>
                <div className="form-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t('Libelle')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Titre"
                        />
                      </div>
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t('Description')}
                        </label>
                        <textarea
                          className="form-control p-20"
                          rows="4"
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <h4 className="box-title mt-20">{t('Uploaded Image')}</h4>
                      <div className="product-img text-left">
                        <img
                          src="../images/product/product-9.png"
                          alt=""
                          className="mb-15"
                        />
                        <p>{t('Upload Anonther Image')}</p>
                        <div className="btn btn-info mb-20 choose-file">
                          <input type="file" className="upload" />
                        </div>
                        <div className="pic-actions">
                          <button className="btn btn-success left-button">
                            {t('Edit')}
                          </button>
                          <button className="btn btn-danger">{t('Delete')}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-actions mt-10">
                  <Link to="/menus">
                    <button
                      type="submit"
                      className="btn btn-primary left-button"
                    >
                      {" "}
                      <i className="fa fa-check"></i> Save / Add
                    </button>
                    <button type="button" className="btn btn-danger">
                      Cancel
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default AddPicture;
