import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../Auth/useUser";
import { useOrganisations } from "./../../Auth/useOrganisations";
import { changeLanguage } from "i18next";

function Header() {
  const [isDark, setisDark] = useState(false);
  const [lang, setLang] = useState("Fr");
  const user = useUser();
  const [infoUser] = useState(user.result);
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  console.log(organisations);
  /**
   * The function clears the local storage and reloads the page after 2 seconds
   */
  const handleLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      window.location.reload(false);
    }, 2000);
  };

  /**
   * If the user has dark mode enabled, remove the dark-skin class from the body element and set the is_dark local storage item to false. If the user has
   * dark mode disabled, add the dark-skin class to the body element and set the is_dark local storage item to true
   */
  const handleSetDarkMode = () => {
    if (isDark) {
      document.body.classList.remove("dark-skin");
      localStorage.setItem("is_dark", false);
      setisDark(!isDark);
    } else {
      document.body.classList.add("dark-skin");
      localStorage.setItem("is_dark", true);
      setisDark(!isDark);
    }
  };
  useEffect(() => {
    if (
      infoUser &&
      !usedOrganisations &&
      organisations &&
      organisations.length > 0
    ) {
      setUsedOrganisations(organisations[0].id);
      window.location.reload(true);
    }
  }, []);
  return (
    <header className="main-header">
      <div className="d-flex align-items-center logo-box justify-content-start">
        <a
          href="#"
          className="waves-effect waves-light nav-link d-none d-md-inline-block mx-10 push-btn bg-transparent hover-primary"
          data-toggle="push-menu"
          role="button"
        >
          <span className="icon-Align-left">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
          </span>
        </a>
        {/* Logo */}
        <a href="/" className="logo" style={{ paddingLeft: "0" }}>
          {/* logo*/}
          <div className="logo-lg">
            <span className="light-logo" style={{ width: "140px" }}>
              <img src="../images/logo-long-dark.png" alt="logo" />
            </span>
            <span className="dark-logo" style={{ width: "140px" }}>
              <img src="../images/logo-long.png" alt="logo" />
            </span>
          </div>
        </a>
      </div>
      {/* Header Navbar */}
      <nav className="navbar navbar-static-top">
        {/* Sidebar toggle button*/}
        <div className="app-menu">
          <ul className="header-megamenu nav">
            <li className="btn-group nav-item d-md-none">
              <a
                href="#"
                className="waves-effect waves-light nav-link push-btn btn-info-light"
                data-toggle="push-menu"
                role="button"
              >
                <span className="icon-Align-left">
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                </span>
              </a>
            </li>
            {/* <li className="btn-group nav-item d-none d-xl-inline-block">
              <div className="app-menu">
                <div className="search-bx mx-5">
                  <form>
                    <div className="input-group">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="button-addon2"
                      />
                      <div className="input-group-append">
                        <button
                          className="btn"
                          type="submit"
                          id="button-addon3"
                        >
                          <i className="ti-search" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </li> */}
          </ul>
        </div>
        <div className="navbar-custom-menu r-side">
          <ul className="nav navbar-nav">
            <li className="btn-group nav-item d-lg-inline-flex d-none">
              <a
                href="#"
                data-provide="fullscreen"
                className="waves-effect waves-light nav-link full-screen btn-info-light"
                title="Full Screen"
              >
                <i className="icon-Expand-arrows">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </a>
            </li>

            <li className="btn-group nav-item d-lg-inline-flex d-none">
              <Link
                to="#"
                onClick={handleSetDarkMode}
                className="waves-effect waves-light nav-link full-screen btn-success-light"
                title="Full Screen"
              >
                <i className="icon-Flashlight">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </Link>
            </li>

            {/* <li className="dropdown notifications-menu">
              <span className="label label-danger">5</span>
              <a
                href="#"
                className="waves-effect waves-light dropdown-toggle btn-danger-light"
                data-toggle="dropdown"
                title="Notifications"
              >
                <i className="icon-Notifications">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </a>
              <ul className="dropdown-menu animated bounceIn">
                <li className="header">
                  <div className="p-20">
                    <div className="flexbox">
                      <div>
                        <h4 className="mb-0 mt-0">Notifications</h4>
                      </div>
                      <div>
                        <a href="#" className="text-danger">
                          Clear All
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <ul className="menu sm-scrol">
                    <li>
                      <a href="#">
                        <i className="fa fa-users text-info" /> Curabitur id
                        eros quis nunc suscipit blandit.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-warning text-warning" /> Duis
                        malesuada justo eu sapien elementum, in semper diam
                        posuere.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-users text-danger" /> Donec at nisi
                        sit amet tortor commodo porttitor pretium a erat.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-shopping-cart text-success" /> In
                        gravida mauris et nisi
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-user text-danger" /> Praesent eu
                        lacus in libero dictum fermentum.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-user text-primary" /> Nunc fringilla
                        lorem
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-user text-success" /> Nullam euismod
                        dolor ut quam interdum, at scelerisque ipsum imperdiet.
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="footer">
                  <a href="#">View all</a>
                </li>
              </ul>
            </li>
            
            <li className="dropdown messages-menu">
              <span className="label label-danger">5</span>
              <a
                href="#"
                className="dropdown-toggle btn-danger-light"
                data-toggle="dropdown"
                title="Messages"
              >
                <i className="icon-Incoming-mail">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </a>
              <ul className="dropdown-menu animated bounceIn">
                <li className="header">
                  <div className="p-20">
                    <div className="flexbox">
                      <div>
                        <h4 className="mb-0 mt-0">Messages</h4>
                      </div>
                      <div>
                        <a href="#" className="text-danger">
                          Clear All
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <ul className="menu sm-scrol">
                    <li>
                      <a href="#">
                        <div className="pull-left">
                          <img
                            src="../images/user2-160x160.jpg"
                            className="rounded-circle"
                            alt="User Image"
                          />
                        </div>
                        <div className="mail-contnet">
                          <h4>
                            Lorem Ipsum
                            <small>
                              <i className="fa fa-clock-o" /> 15 mins
                            </small>
                          </h4>
                          <span>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div className="pull-left">
                          <img
                            src="../images/user3-128x128.jpg"
                            className="rounded-circle"
                            alt="User Image"
                          />
                        </div>
                        <div className="mail-contnet">
                          <h4>
                            Nullam tempor
                            <small>
                              <i className="fa fa-clock-o" /> 4 hours
                            </small>
                          </h4>
                          <span>
                            Curabitur facilisis erat quis metus congue viverra.
                          </span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div className="pull-left">
                          <img
                            src="../images/user4-128x128.jpg"
                            className="rounded-circle"
                            alt="User Image"
                          />
                        </div>
                        <div className="mail-contnet">
                          <h4>
                            Proin venenatis
                            <small>
                              <i className="fa fa-clock-o" /> Today
                            </small>
                          </h4>
                          <span>
                            Vestibulum nec ligula nec quam sodales rutrum sed
                            luctus.
                          </span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div className="pull-left">
                          <img
                            src="../images/user3-128x128.jpg"
                            className="rounded-circle"
                            alt="User Image"
                          />
                        </div>
                        <div className="mail-contnet">
                          <h4>
                            Praesent suscipit
                            <small>
                              <i className="fa fa-clock-o" /> Yesterday
                            </small>
                          </h4>
                          <span>
                            Curabitur quis risus aliquet, luctus arcu nec,
                            venenatis neque.
                          </span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div className="pull-left">
                          <img
                            src="../images/user4-128x128.jpg"
                            className="rounded-circle"
                            alt="User Image"
                          />
                        </div>
                        <div className="mail-contnet">
                          <h4>
                            Donec tempor
                            <small>
                              <i className="fa fa-clock-o" /> 2 days
                            </small>
                          </h4>
                          <span>
                            Praesent vitae tellus eget nibh lacinia pretium.
                          </span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="footer">
                  <a href="#">See all e-Mails</a>
                </li>
              </ul>
            </li> */}
            <li className="btn-group nav-item">
              {/* <span className="label label-primary">5</span> */}
              <Link
                to={"/organisation/edit"}
                title="Setting"
                className="waves-effect waves-light nav-link full-screen btn-primary-light"
              >
                <i className="icon-Settings-2" />
              </Link>
            </li>
            <li className="btn-group nav-item d-xl-none d-inline-flex">
              <a
                href="#"
                className="push-btn right-bar-btn waves-effect waves-light nav-link full-screen btn-info-light"
              >
                <span className="icon-Layout-left-panel-1">
                  <span className="path1" />
                  <span className="path2" />
                </span>
              </a>
            </li>
            <li className="dropdown user user-menu">
              <a
                href="#"
                className="dropdown-toggle p-0 text-dark hover-primary ml-md-30 ml-10"
                style={{ borderLeft: "none" }}
                data-toggle="dropdown"
                title="User"
              >
                <span className="pl-30 d-md-inline-block d-none">
                  {lang === "Fr" ? (
                    <>
                      <img
                        src="../images/france.png"
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      Fr
                    </>
                  ) : lang === "En" ? (
                    <>
                      <img
                        src="../images/england.png"
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      En
                    </>
                  ) : lang === "Ar" ? (
                    <>
                      <img
                        src="../images/tun.png"
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      Ar
                    </>
                  ) : null}{" "}
                </span>{" "}
              </a>
              <ul className="dropdown-menu animated flipInX">
                <li className="user-body">
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={(e) => {
                      setLang("Fr");
                      changeLanguage("fr");
                    }}
                  >
                    <img
                      src="../images/france.png"
                      style={{ width: "30px", marginRight: "10px" }}
                    />
                    Fr
                  </a>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={(e) => {
                      setLang("En");
                      changeLanguage("en");
                    }}
                  >
                    <img
                      src="../images/england.png"
                      style={{ width: "30px", marginRight: "10px" }}
                    />
                    En
                  </a>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={(e) => {
                      setLang("Ar");
                      changeLanguage("ar");
                    }}
                  >
                    <img
                      src="../images/tun.png"
                      style={{ width: "30px", marginRight: "10px" }}
                    />
                    Ar
                  </a>
                </li>
              </ul>
            </li>
            <li className="dropdown user user-menu">
              <a
                href="#"
                className="dropdown-toggle p-0 text-dark hover-primary ml-md-30 ml-10"
                data-toggle="dropdown"
                title="User"
              >
                <span className="pl-30 d-md-inline-block d-none">Salut, </span>{" "}
                <strong className="d-md-inline-block d-none">
                  {infoUser?.nom}
                </strong>
                <img
                  src="../images/avatar/avatar-13.png"
                  className="user-image rounded-circle avatar bg-white mx-10"
                  alt="User Image"
                />
              </a>
              <ul className="dropdown-menu animated flipInX">
                <li className="user-body">
                  {/* <a className="dropdown-item" href="#">
                    <i className="ti-user text-muted mr-2" /> Profile
                  </a> */}
                  {organisations &&
                    organisations.map((value, index) => {
                      return (
                        <Link
                          className={
                            "dropdown-item " +
                            (value.id == usedOrganisations ? "active" : "")
                          }
                          to="#"
                          onClick={(e) => {
                            setUsedOrganisations(value.id);
                            window.location.reload();
                          }}
                        >
                          <i className="ti-wallet text-muted mr-2" />{" "}
                          {value.nom}
                        </Link>
                      );
                    })}
                  <Link className="dropdown-item" to="/addorganization">
                    <i className="ti-plus text-muted mr-2" /> Ajouter un
                    organization
                  </Link>

                  <div className="dropdown-divider" />
                  <Link className="dropdown-item" to="#" onClick={handleLogout}>
                    <i className="ti-lock text-muted mr-2" /> Deconnexion
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
export default Header;
