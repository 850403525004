import React, { useEffect, useState } from "react";
import TemplateCleaner from "./TemplateCleaner";
import greenTick from "../MenusStyle/green-tick.png";
import { Link, useParams } from "react-router-dom";

function ConfirmCart() {
  const { id, table } = useParams();
  return (
    <>
      <TemplateCleaner />
      <div class="my-5">
        <div class="text-center">
          <div class="mb-3 px-5">
            <img src={greenTick} class="img-fluid px-5" alt="#" />
          </div>
          <div class="h1 text-success mb-1">Commande confirmée ! </div>
          <div class="fs-5">Delivery Soon</div>
        </div>
      </div>
      {/* buttons */}
      <div class="d-grid fixed-bottom gap-2 p-3">
        <Link
          to={"/menu5/" + id + "/" + table}
          class="btn btn-outline-danger btn-lg"
        >
          Ok
        </Link>
      </div>
    </>
  );
}
export default ConfirmCart;
