import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  editOrganisation,
  getOrganisationById,
} from "../Services/OrganizationApi";
import { useOrganisations } from "./../Auth/useOrganisations";

function Receptionist() {
  const [nom, setNom] = useState(null);
  const [prenom, setPrenom] = useState(null);
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [gender, setGender] = useState("homme");
  const [date_naissance, setDateNaissance] = useState(null);
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  
  const onSubmit = async (e) => {
    e.preventDefault();

    //@ToDo: Test all input errors here.
  };
  useEffect(() => {}, []);
  return (
    <section className="content">
      <div className="row">
        <div className="col-12">
          <div className="box" style={{ padding: "15px" }}>
            <div className="box-body">
            <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-user" />
                          </span>
                        </div>
                        <input
                          value={nom}
                          onChange={(e) => setNom(e.currentTarget.value)}
                          type="text"
                          className="form-control pl-15 bg-transparent"
                          placeholder="Nom"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-user" />
                          </span>
                        </div>
                        <input
                          value={prenom}
                          onChange={(e) => setPrenom(e.currentTarget.value)}
                          type="text"
                          className="form-control pl-15 bg-transparent"
                          placeholder="Prénom"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-email" />
                          </span>
                        </div>
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.currentTarget.value)}
                          type="email"
                          className="form-control pl-15 bg-transparent"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-addon">
                          <i className="fa fa-phone"></i>
                        </div>
                        <input
                          value={mobile}
                          onChange={(e) => setMobile(e.currentTarget.value)}
                          type="text"
                          className="form-control"
                          placeholder="99 99 99 99"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="demo-radio-button">
                          <div className="demo-radio-button">
                            <input
                              name="group4"
                              type="radio"
                              id="radio_7"
                              className="radio-col-primary"
                              value={"homme"}
                              onClick={(e) => setGender(e.currentTarget.value)}
                              checked
                            />
                            <label for="radio_7">Homme</label>
                            <input
                              name="group4"
                              type="radio"
                              id="radio_9"
                              className="radio-col-success"
                              value={"femme"}
                              onClick={(e) => setGender(e.currentTarget.value)}
                            />
                            <label for="radio_9">Femme</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <input
                          value={date_naissance}
                          onChange={(e) =>
                            setDateNaissance(e.currentTarget.value)
                          }
                          className="form-control"
                          type="date"
                          name="date"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-lock" />
                          </span>
                        </div>
                        <input
                          value={password}
                          onChange={(e) => setPassword(e.currentTarget.value)}
                          type="password"
                          className="form-control pl-15 bg-transparent"
                          placeholder="Password"
                        />
                      </div>
                    </div>
                    {/* <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-lock" />
                          </span>
                        </div>
                        <input
                          type="password"
                          className="form-control pl-15 bg-transparent"
                          placeholder="Retype Password"
                        />
                      </div>
                    </div> */}
                    <div className="row">
                      
                      {/* /.col */}
                      <div className="col-12 text-center">
                        <button
                          type="submit"
                          className="btn btn-info margin-top-10"
                        >
                          S'inscrire
                        </button>
                      </div>
                      {/* /.col */}
                    </div>
                  </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Receptionist;
