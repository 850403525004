import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PropTypes } from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Inscription({ callback, user, ...props }) {
  const [nom, setNom] = useState();
  const [logo, setLogo] = useState();
  const [color, setColor] = useState("#1a2734");
  const [numero, setNumero] = useState();
  const [type, setType] = useState("Restaurant");
  const [description, setDescription] = useState();
  const [tables, setTables] = useState(1);
  const [wifi, setWifi] = useState(" ");
  const [menu, setMenu] = useState(1);
  const [currency, setCurrency] = useState("TND");
  const paymentMethods = ["Cash", "Credit Card", "Tickets Restau", "Chéque"];
  const [checked, setChecked] = useState([]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    console.log(user);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    // Validate nom
    if (!nom) {
      toast.error("Veuillez entrer un nom pour votre entreprise!!");
      return false;
    }

    // Validate numero
    const numeroRegex = /^\d{8}$/;
    if (!numeroRegex.test(numero)) {
      toast.error(
        "Veuillez entrer un numéro de téléphone valide avec exactement 8 chiffres!!"
      );
      return false;
    }
    // Validate currency
    if (!currency) {
      toast.error("Veuillez sélectionner une devise pour votre entreprise!!");
      return false;
    }

    callback(
      nom,
      logo,
      color,
      numero,
      description,
      tables,
      wifi,
      menu,
      currency,
      paymentMethods,
      type
    );

    return false;
  };

  return (
    <div className="container h-p100" style={{ marginTop: "50px" }}>
      <div className="row align-items-center justify-content-md-center h-p100">
        <div className="col-12">
          <div className="row justify-content-center no-gutters">
            <div className="col-lg-5 col-md-5 col-12">
              <div className="bg-white rounded30 shadow-lg">
                <div className="content-top-agile p-20 pb-0">
                  <h2 className="text-primary">Commencez avec nous</h2>
                  <p className="mb-0">Enregistrez une nouvelle organization</p>
                </div>
                <div className="p-40">
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-user" />
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control pl-15 bg-transparent"
                          placeholder="Nom"
                          value={nom}
                          onChange={(e) => setNom(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-addon">
                          <i className="fa fa-phone"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="99 99 99 99"
                          value={numero}
                          onChange={(e) => setNumero(e.currentTarget.value)}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <h5>Choisir votre type de vente</h5>
                      <div className="input-group mb-3">
                        <div className="demo-radio-button">
                          <div
                            className="demo-radio-button"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <input
                              name="type"
                              type="radio"
                              id="type1"
                              className="radio-col-primary"
                              value={"Restaurant"}
                              onClick={(e) => setType(e.currentTarget.value)}
                              defaultChecked={type === "Restaurant"}
                            />
                            <label for="type1">Restaurant</label>
                            <input
                              name="type"
                              type="radio"
                              id="type2"
                              className="radio-col-success"
                              value={"Catalogue"}
                              defaultChecked={type === "Catalogue"}
                              onClick={(e) => setType(e.currentTarget.value)}
                            />
                            <label for="type2">Catalogue</label>
                            <input
                              name="type"
                              type="radio"
                              id="type3"
                              className="radio-col-success"
                              value={"Showroom"}
                              defaultChecked={type === "Showroom"}
                              onClick={(e) => setType(e.currentTarget.value)}
                            />
                            <label for="type3">Showroom</label>
                            <input
                              name="type"
                              type="radio"
                              id="type4"
                              className="radio-col-success"
                              value={"Events"}
                              defaultChecked={type === "Events"}
                              onClick={(e) => setType(e.currentTarget.value)}
                            />
                            <label for="type4">Planning / Événement</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="font-weight-700 font-size-16">
                        Description
                      </label>
                      <textarea
                        className="form-control p-20"
                        rows="4"
                        value={description}
                        onChange={(e) => setDescription(e.currentTarget.value)}
                      ></textarea>
                    </div>

                    <div className="product-img text-left">
                      <h4 className="box-title" style={{ textAlign: "center" }}>
                        Télecharger votre logo
                      </h4>
                      <div className="product-img text-left">
                        <img
                          src={
                            logo
                              ? URL.createObjectURL(logo)
                              : "../images/product/no-image.png"
                          }
                          alt=""
                          className="mb-15"
                        />
                        <div className="btn btn-info mb-20 choose-file">
                          <input
                            onChange={(e) => setLogo(e.currentTarget.files[0])}
                            type="file"
                            name="logo"
                            className="upload"
                          />
                        </div>
                      </div>
                    </div>

                    {/* choix menu */}

                    <div className="row">
                      {/* /.col */}
                      <div className="col-12 text-center">
                        <button
                          type="submit"
                          className="btn btn-info margin-top-10"
                        >
                          S'inscrire
                        </button>
                      </div>
                      {/* /.col */}
                    </div>
                  </form>
                  <div className="text-center">
                    <p className="mt-15 mb-0">
                      Vous avez déjà un compte?
                      <Link to="/login" className="text-danger ml-5">
                        {" "}
                        Se connecter
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inscription;
