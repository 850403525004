import axios from "axios";
import { getSession } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API;

/* Setting the header for the axios request. */
const config = {
  headers: { Authorization: `Bearer ${getSession("token")}` },
};

//to add in every api
const currentOrg = getSession("usedorganisations");

export async function addCarte(libelle, logo) {
  const frmData = new FormData();
  frmData.append("logo", logo);
  frmData.append("libelle[fr]", libelle.fr);
  frmData.append("libelle[en]", libelle.en);
  frmData.append("libelle[ar]", libelle.ar);
  frmData.append("organisation_id", currentOrg);
  const httpHeaders = {
    headers: {
      Authorization: `Bearer ${getSession("token")}`,
      "Content-Type": `multipart/form-data; boundary=${frmData._boundary}`,
    },
  };
  try {
    const response = await axios.post(api_url + "cartes", frmData, httpHeaders);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getCartes(id) {
  if (id == null) {
    id = currentOrg;
  }
  try {
    const response = await axios.get(api_url + "cartes/organisation/" + id);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function editCarte(libelle, logo, id) {
  const frmData = new FormData();
  frmData.append("libelle[fr]", libelle.fr);
  frmData.append("libelle[en]", libelle.en);
  frmData.append("libelle[ar]", libelle.ar);
  frmData.append("logo", logo);
  frmData.append("id", id);
  frmData.append("organisation_id", currentOrg);

  const httpHeaders = {
    headers: {
      Authorization: `Bearer ${getSession("token")}`,
      "Content-Type": `multipart/form-data; boundary=${frmData._boundary}`,
    },
  };

  try {
    const response = await axios.patch(
      api_url + "cartes",
      frmData,
      httpHeaders
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getCarteById(id) {
  try {
    const response = await axios.get(api_url + "cartes/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteCartes(id) {
  try {
    const response = await axios.delete(api_url + "cartes/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
