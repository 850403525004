import axios from "axios";
import { getSession } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API;

/* Setting the header for the axios request. */
const config = {
  headers: { Authorization: `Bearer ${getSession("token")}` },
};

//to add in every api
const currentOrg = getSession("usedorganisations");

export async function addCart(cart, id) {
  const httpHeaders = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(
      api_url + "cart/" + id,
      cart,
      httpHeaders
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function editCart(status, id, cancelMotif) {
  const body = {
    status: status,
    cancelMotif: cancelMotif,
    id: id,
  };
  const httpHeaders = {
    Authorization: `Bearer ${getSession("token")}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.patch(api_url + "cart", body, httpHeaders);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getCarts(id) {
  if (id == null) {
    id = currentOrg;
  }
  try {
    const response = await axios.get(api_url + "cart/organisation/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getTopSoldMenus(id) {
  if (id == null) {
    id = currentOrg;
  }
  try {
    const response = await axios.get(api_url + "cart/getTopSoldMenus/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getCartById(id) {
  try {
    const response = await axios.get(api_url + "cart/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getLatestActiveCarts(id) {
  if (id == null) {
    id = currentOrg;
  }
  try {
    const response = await axios.get(api_url + "cart/latest/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getTableOrders(id, table) {
  if (id == null) {
    id = currentOrg;
  }
  try {
    const response = await axios.get(
      api_url + "cart/organisation/" + id + "/" + table,
      config
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function closeOrders(table, organisation_id) {
  const body = {
    table: table,
    organisation_id: organisation_id,
  };
  const httpHeaders = {
    Authorization: `Bearer ${getSession("token")}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.patch(
      api_url + "cart/close",
      body,
      httpHeaders
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getTableNotPaidOrders(id, table) {
  if (id == null) {
    id = currentOrg;
  }
  try {
    const response = await axios.get(
      api_url + "cart/notpaid/" + id + "/" + table,
      config
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getAllNotPaidOrders(id) {
  if (id == null) {
    id = currentOrg;
  }
  try {
    const response = await axios.get(api_url + "cart/notpaid/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
