import axios from "axios";
import { getSession } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API;

/* Setting the header for the axios request. */
const config = {
  headers: { Authorization: `Bearer ${getSession("token")}` },
};

/**
 * It sends a POST request to the server with the form data
 * @param nom - nom,
 * @param prenom - "test"
 * @param email - "test@test.com"
 * @param password - "123456"
 * @param role - "user"
 * @returns The response object.
 */
export async function inscription(
  nom,
  prenom,
  email,
  password,
  role,
  mobile,
  gender,
) {
  const body = {
    nom: nom,
    prenom: prenom,
    email: email,
    password: password,
    role: role,
    mobile: mobile,
    gender: gender,
    isactiv: "1",
  };

  const httpHeaders = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(
      api_url + "register-email",
      body,
      httpHeaders
    );
    return response;
  } catch (error) {
    return error?.response;
  }
}


/**
 * It takes an email and password, and sends a POST request to the server with the email and password as the body.
 *
 * If the request is successful, it returns the response. If the request is unsuccessful, it logs the error to the console.
 * @param email - email,
 * @param password - "password"
 * @returns The response object from the server.
 */
export async function login(email, password) {
  const body = { email: email, password: password };
  try {
    const response = await axios.post(api_url + "login-email", body);
    return response;
  } catch (error) {
    return error?.response;
  }
}

/**
 * It gets a user by id.
 * @param id - The id of the user you want to get.
 * @returns The response object contains the data, headers, and status code.
 */
export async function getUserById(id) {
  try {
    const response = await axios.get(api_url + "users/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get all users by code
 * @returns The response is an array of users.
 */
export async function getUsersByCode(code) {
  try {
    const response = await axios.get(
      api_url + "users/codealluseractif/" + code,
      config
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteUserById(id) {
  try {
    const response = await axios.delete(api_url + "users/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function forgetPassword(email) {
  try {
    const response = await axios.get(
      api_url + "forgot-password/" + email,
      config
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getUsersByEmail(email) {
  try {
    const response = await axios.get(api_url + "users/email/" + email, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getUsersById(id) {
  try {
    const response = await axios.get(api_url + "users/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function updatePassword(token, password) {
  const body = {
    password: password,
  };

  const httpHeaders = {
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.put(api_url + token, body, httpHeaders);
    return response;
  } catch (error) {
    console.error(error);
  }
}


