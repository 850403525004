import React, { useEffect, useState } from "react";
import TemplateCleaner from "./TemplateCleaner";
import { Link, Navigate, useParams } from "react-router-dom";
import NewMenuHeader from "./NewMenuHeader";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import { getEvents } from "../../Services/OrganizationApi";
import { getMenus } from "../../Services/MenuApi";
import fr from "../MenusStyle/france.png";
import eng from "../MenusStyle/england.png";
import ar from "../MenusStyle/tun.png";
import NewMenuFooter from "./NewMenuFooter";

function Events() {
  const { id, table } = useParams();

  useEffect(() => {
    setTimeout(() => {
      var onPageSetupDoneEvent = new Event("onPageSetupDone");
      document.dispatchEvent(onPageSetupDoneEvent);
    }, 2000);
  }, []);
  const [eventsOrg, setEventsOrg] = useState();
  const [events, setEvents] = useState();
  const [currency, setCurrency] = useState();
  const [lang, setLang] = useState("fr");
  const [img, setImg] = useState();

  const { t } = useTranslation();
  const handleLanguageChange = (selectedLanguage) => {
    setLang(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);

    // Use the changeLanguage function from i18next
    changeLanguage(selectedLanguage);

    // You can also trigger any language-specific layout changes here if needed.
  };
  useEffect(() => {
    changeLanguage(localStorage.getItem("selectedLanguage"));
    getEvents(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setEventsOrg(res.data.relatedOrganizations[0]);
        setCurrency(res.data.relatedOrganizations[0].currency);

        console.log(res.data.relatedOrganizations[0]);
        getMenus(res.data.relatedOrganizations[0].id).then((response) => {
          const success = response.data.success;
          if (success === "0") {
            alert("error");
          } else {
            setEvents(response.data.data);
            console.log(response.data.data);
          }
        });
      }
    });
  }, []);
  const currencyTranslation = (currency) => {
    if (lang === "fr" && currency === "TND") {
      return "TND";
    } else if (lang === "en" && currency === "TND") {
      return "TND";
    } else return " دت";
  };

  const goBack = () => {
    Navigate(-1);
  };
  return (
    <>
      <TemplateCleaner />

      <div className="bg-white shadow-sm p-3">
        <div className="d-flex align-items-center">
          <div className="gap-3 d-flex align-items-center">
            <a
              onClick={(e) =>
                (window.location.href = "/menu5/" + id + "/" + table)
              }
            >
              <i className="bi bi-arrow-left d-flex text-danger h2 m-0 back-page"></i>
            </a>
            <h3 className="fw-bold m-0">{t("our events")}</h3>
          </div>
          <div className="ms-auto gap-3 d-flex align-items-center">
            <div className="btn-group">
              <button
                className="btn btn-rounded dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                style={{
                  backgroundColor: "white",
                  display: "flex",
                  padding: "0",
                }}
              >
                <div className="btn-text">
                  {lang === "fr" ? (
                    <>
                      <img
                        src={fr}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      Fr
                    </>
                  ) : lang === "en" ? (
                    <>
                      <img
                        src={eng}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      En
                    </>
                  ) : lang === "ar" ? (
                    <>
                      <img
                        src={ar}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      Ar
                    </>
                  ) : null}
                </div>
              </button>
              <div
                className="dropdown-menu dropdown-menu-right"
                style={{ backgroundColor: "white" }}
              >
                <a
                  className="dropdown-item"
                  onClick={() => {
                    handleLanguageChange("fr");
                  }}
                >
                  <img
                    src={fr}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  Fr
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    handleLanguageChange("en");
                  }}
                >
                  <img
                    src={eng}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  En
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    handleLanguageChange("ar");
                  }}
                >
                  <img
                    src={ar}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  Ar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h3 className="fw-bold mb-0"> {eventsOrg && eventsOrg.nom} </h3>
        </div>
        <div className="row g-3">
          {/* 1st product */}
          {events &&
            events.map((value, index) => {
              return (
                <div className="col-12 col-md-4" key={index}>
                  <Link
                    onClick={(e) =>
                      (window.location.href =
                        "/menu3/" +
                        (eventsOrg && eventsOrg.id) +
                        "/" +
                        value.id)
                    }
                    className="text-decoration-none link-dark"
                  >
                    <div className="card rounded-4 shadow border-0 overflow-hidden">
                      <div className="position-relative">
                        <img
                          src={
                            value && value.image.length
                              ? process.env.REACT_APP_API_BASE_URL +
                                value.image[0]
                              : ""
                          }
                          alt=""
                          className="img-fluid rounded-top"
                        />
                      </div>
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div className="h4 fw-bold"> {value.titre.fr} </div>
                        </div>
                        <div className="d-flex justify-content-between text-muted h6 m-0 fw-normal">
                          <div> {value.titre.fr} </div>
                          <div>
                            {" "}
                            {value.prix > 0
                              ? value.prix +
                                " " +
                                currencyTranslation(currency) +
                                " " +
                                t("for one")
                              : t("free")}{" "}
                          </div>
                        </div>
                      </div>
                      {/* <div className="card-footer p-3 border-0">
                        <div className="d-flex align-items-center gap-2">
                          <div>
                            <img
                              src="/newmenu/img/graph-up.png"
                              alt=""
                              className="img-fluid ch-30 cw-30"
                            />
                          </div>
                          <div className="text-muted">
                            4300+ orders pplaced from here recently
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </Link>
                </div>
              );
            })}
          {events &&
            events.map((value, index) => {
              <div className="col-12 col-md-4" key={index}>
                <Link
                  to="/newmenu/store"
                  className="text-decoration-none link-dark"
                >
                  <div className="card rounded-4 shadow border-0 overflow-hidden">
                    <div className="position-relative">
                      <div className="product-veg bg-success  opacity-75 text-center text-white p-2">
                        <span>
                          <i className="fa-brands fa-envira"></i>
                        </span>
                        &nbsp;Pure Veg Resturant
                      </div>
                      <img
                        src="/newmenu/img/1.jpeg"
                        alt=""
                        className="img-fluid rounded-top"
                      />
                    </div>
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="h4 fw-bold">Domino's Pizza</div>
                        <div>
                          <span className="badge bg-success">
                            4.5&nbsp;<i className="fa-solid fa-star"></i>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between text-muted h6 m-0 fw-normal">
                        <div>Burger, Fast Food, Beverages</div>
                        <div>$150 for one</div>
                      </div>
                    </div>
                    <div className="card-footer p-3 border-0">
                      <div className="d-flex align-items-center gap-2">
                        <div>
                          <img
                            src="/newmenu/img/graph-up.png"
                            alt=""
                            className="img-fluid ch-30 cw-30"
                          />
                        </div>
                        <div className="text-muted">
                          4300+ orders pplaced from here recently
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>;
            })}
        </div>
      </div>
      {/* Sort by */}
      {/* Modal */}

      <NewMenuFooter id={id} table={table} />
    </>
  );
}

export default Events;
