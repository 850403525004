import React, { useEffect, useState } from "react";
import { getPlans } from "../Services/PlansApi";
import { getPaymentData } from "../Services/PaymentApi";

function Plans({ org, ...props }) {
  const [plans, setPlans] = useState([]);
  useEffect(() => {
    document.body.style.backgroundColor = "#f3f6f9";
  }, []);
  useEffect(() => {
    getPlans().then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setPlans(res.data.data);
      }
    });
  }, []);

  const proceedPayment = (plan) => {
    getPaymentData(org.id, plan.id, ( plan.credit * 1000)).then((data) => {
      if(data.data && data.data.result && data.data.result.link) {
        window.location.href = data.data.result.link;
      }
    });
  }
  return (
    <div className="container h-p100" style={{ marginTop: "50px" }}>
      <div className="row align-items-center justify-content-md-center h-p100">
        <div className="col-12">
          <div className="row justify-content-center no-gutters">
            <div className="col-lg-12 col-md-5 col-12">
              <div className="bg-white rounded30 shadow-lg">
                <div className="content-top-agile p-20 pb-0">
                  <h2 className="text-primary">Plans</h2>
                </div>
                <div className="p-40">
                  <div className="row no-gutters"> 
                    {plans &&
                      plans.map((value, index) => (
                        <div className="col-lg-5" key={index} style={{marginRight:"40px"}}>
                          <div
                            // className={
                            //   index === 1
                            //     ? "box px-40 py-80 text-center bg-info"
                            //     : "box box-shadowed box-round text-center p-40"
                            // }
                            className="box px-40 py-80 text-center bg-info"
                            style={{ marginTop: "-40px" }}
                          >
                            <h4 className="text-white text-uppercase">
                              {value.description}
                            </h4>
                            <h3 className="price text-white">
                              <sup>TND</sup>{value.credit}
                              <span className="text-white">{value.dure} mois</span>
                            </h3>
                            <a
                              className="btn btn-outline btn-round btn-white"
                              href="#"
                              onClick={e => proceedPayment(value)}
                            >
                              Choisir
                            </a>

                            {/* <ul className="list-unstyled mt-10 mb-0">
                              <li className="py-10">
                                <b>10GB</b> Disk Space
                              </li>
                              <li className="py-10">
                                <b>100GB</b> Monthly Bandwidth
                              </li>
                              <li className="py-10">
                                <b>20</b> Email Accounts
                              </li>
                              <li className="pt-10">
                                <b>Unlimited</b> subdomains
                              </li>
                            </ul> */}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Plans;
