import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { verifyPayment } from "../Services/PaymentApi";

export default function FlouciSuccess() {

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const paymentId = urlParams.get('payment_id');
        verifyPayment(paymentId);
    }, [])
    return (
        <div className="container h-p100" style={{ marginTop: "150px" }}>
            <div className="row align-items-center justify-content-md-center h-p100">
                <div className="col-12">
                    <div className="row justify-content-center no-gutters">
                        <div className="col-lg-5 col-md-5 col-12">
                            <div className="bg-white rounded30 shadow-lg">
                                <div className="content-top-agile p-20 pb-0">
                                    <h2 className="text-primary">paiement accepter</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

}
