import React, { useEffect, useState } from "react";
import { getMenus } from "../Services/MenuApi";
// import { useToken } from "../Auth/useToken";
import { Link } from "react-router-dom";
import { getCategories } from "../Services/CategrieApi";

import swal from "sweetalert";

import { useUser } from "../Auth/useUser";
import {
  addPopups,
  deleteImage,
  editPopup,
  getPopup,
} from "../Services/PopupApi";
import GifLoader from "../Components/loader";
import { useTranslation } from "react-i18next";
import { useOrganisations } from "../Auth/useOrganisations";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // A function that increment ðŸ‘†ðŸ» the previous state like here
  // is better than directly setting setValue(value + 1)
}

function Popups() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  // const [token, setToken] = useToken();
  const [adImage, setAdImage] = useState();
  const [categorie, setCategorie] = useState(null);
  const [menu, setMenu] = useState(null);
  const [suggestion, setSuggestion] = useState(null);
  const [popup, setPopup] = useState();
  const [selectedCategorie, setSelectedCategorie] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const user = useUser();
  const [infoUser] = useState(user.result);
  const [displayed, setDisplayed] = useState(false);
  const [loading, setLoading] = useState(true);
  const forceUpdate = useForceUpdate();
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();

  const promptForDelete = () => {
    swal({
      title: "Pouf ! Votre article a été ajouté avec succès !",
      icon: "success",
    }).then(function () {
      window.location = "/menus";
    });
  };
  useEffect(() => {
    getCategories(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        console.log(res.data.data);
        setCategorie(res.data.data);
      }
    });
    getMenus(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        console.log(res.data.data);
        setMenu(res.data.data);
      }
    });
    getPopup(usedOrganisations).then((res) => {
      setLoading(false);
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setPopup(res.data.data);
        setSelectedCategorie(
          res.data.data.category_id ? res.data.data.category_id._id : null
        );
        setSelectedMenu(
          res.data.data.menu_id ? res.data.data.menu_id._id : null
        );
        setSuggestion(
          res.data.data.suggestion ? res.data.data.suggestion : null
        );
      }
    });
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    //@ToDo: Test all input errors here.
    if (popup && popup.id) {
      editPopup(
        adImage,
        selectedCategorie,
        selectedMenu,
        suggestion,
        popup.id
      ).then((res) => {
        const success = res.data.success;
        if (success === "0") {
          alert("error");
        } else {
          promptForDelete();
        }
      });
    } else {
      addPopups(adImage, selectedCategorie, selectedMenu).then((res) => {
        const success = res.data.success;
        if (success === "0") {
          alert("error");
        } else {
          promptForDelete();
        }
      });
    }

    return false;
  };

  const removePopupImage = (e) => {
    e.preventDefault();
    if (popup && popup.id) {
      deleteImage(popup.id).then((value) => {
        let tempPopup = popup;
        tempPopup.adimage = null;
        setPopup(tempPopup);
      });
    }
    setAdImage(null);
    forceUpdate();
  };

  // the game
  const handleCategorieChange = (e) => {
    setSelectedCategorie(e.target.value);
    setSelectedMenu("");
    setSuggestion("");
  };

  const handleMenuChange = (e) => {
    setSelectedMenu(e.target.value);
    setSelectedCategorie("");
    setSuggestion("");
  };

  const handleSuggestionChange = (e) => {
    setSelectedCategorie("");
    setSelectedMenu("");
    setSuggestion(e.currentTarget.value);
  };
  return (
    <section className="content">
      <GifLoader loading={loading} />
      <div className="row">
        <div className="col-12">
          <div className="box">
            <div className="box-body">
              <form onSubmit={onSubmit}>
                <div className="form-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t('Suggestion Categorie')}
                        </label>
                        <select
                          value={selectedCategorie}
                          defaultValue={selectedCategorie}
                          onChange={handleCategorieChange}
                          className="form-control"
                          data-placeholder="Choose a Category"
                          tabindex="1"
                        >
                          <option value={""}>Désactiver</option>
                          {categorie &&
                            categorie.map((value, index) => (
                              <option value={value._id} key={index}>
                                {value.libelle.fr}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t('Suggestion produit')}
                        </label>
                        <select
                          value={selectedMenu}
                          defaultValue={selectedMenu}
                          onChange={handleMenuChange}
                          className="form-control"
                          data-placeholder="Choose a Category"
                          tabindex="1"
                        >
                          <option value={""}>Désactiver</option>
                          {menu &&
                            menu.map((value, index) => (
                              <option value={value.id} key={index}>
                                {value.titre.fr}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t('Message de proposition')}
                        </label>
                        <textarea
                          className="form-control"
                          value={suggestion}
                          onChange={handleSuggestionChange}
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <h4 className="box-title mt-20">
                        {t('Ajouter un image de pub')}
                      </h4>
                      <div className="product-img text-left">
                        <img
                          src={
                            adImage
                              ? URL.createObjectURL(adImage)
                              : popup && popup.adimage
                              ? process.env.REACT_APP_API_BASE_URL +
                                popup.adimage
                              : "../images/product/no-image.png"
                          }
                          alt=""
                          className="mb-15"
                        />
                        <p>{t("Changer l'image")}</p>
                        <div className="btn btn-info mb-20 choose-file">
                          <input
                            onChange={(e) =>
                              setAdImage(e.currentTarget.files[0])
                            }
                            type="file"
                            name="logo"
                            className="upload"
                          />
                        </div>
                        {adImage || (popup && popup.adimage) ? (
                          <div className="pic-actions">
                            <button
                              className="btn btn-danger"
                              onClick={(e) => removePopupImage(e)}
                            >
                              {t('Delete')}
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-actions mt-10">
                  <Link to="/">
                    <button type="button" className="btn btn-primary">
                      {t('Annuler')}
                    </button>
                  </Link>
                  <button type="submit" className="btn btn-danger left-button">
                    {" "}
                    <i className="fa fa-check"></i> {t('Ajouter')} / modifier
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Popups;
