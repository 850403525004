import React, { useEffect, useState } from "react";
import { useOrganisations } from "../Auth/useOrganisations";
import { getLatestActiveCarts, getTableOrders } from "../Services/CartApi";
import { getOrganisationById } from "../Services/OrganizationApi";
import GifLoader from "../Components/loader";
import { Link } from "react-router-dom";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Tables() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [tables, setTables] = useState(0);
  const [orders, setOrders] = useState([]);
  const [tableOrders, setTableOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentTable, setCurrentTable] = useState();
  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);

  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  const getTableColor = (table) => {
    const filtredData = orders.filter((arr) => arr.cart.table === table);

    if (filtredData.length) {
      return "full";
    } else {
      return "available";
    }
  };
  useEffect(() => {
    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setTables(res.data.data.tables);
      }
    });

    getLatestActiveCarts(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrders(res.data.data);
      }
      setLoading(false);
    });
  }, []);

  const getTableOrderss = (table) => {
    getTableOrders(usedOrganisations, table).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setTableOrders(res.data.data);
        console.log(res.data.data);
      }
    });
  };

  return (
    <section className="">
      <GifLoader loading={loading} />

      <div className="row">
        {/* <h3 className="page-title">Les tables occupÃ©s sont en rouge et les tables libres sont en vert</h3> */}
        {Array.from({ length: tables }, (_, i) => i).map((item, index) => (
          <div
            className="col-xxxl-4 col-xl-4 col-lg-6 col-12"
            key={index}
            onClick={(e) => {
              if (getTableColor(item + 1) === "full") {
                window.location.href = "/tables/orders/" + (item + 1);
              }
            }}
          >
            <div className="box overflow-h">
              <div className="box-body p-0" style={{ position: "relative" }}>
                <Link>
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img
                      className="rounded img-fluid"
                      src={"./images/" + getTableColor(item + 1) + ".png"}
                      style={{ padding: "50px" }}
                    />
                    <div
                      className="number"
                      style={{
                        position: "absolute",
                        top: "77%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                        width: "100%",
                        color: "black",
                        fontWeight: "bold",
                        width: "30px",
                        height: "30px",
                        backgroundColor: "white",
                        padding: "5px",
                        borderRadius: "12px",
                      }}
                    >
                      {item + 1}
                    </div>
                  </div>
                </Link>
              </div>
              {/* <div className="box-body">
                <div className="info-content">
                  <Link>
                    <h4 className="mb-10 mt-0">{item + 1}</h4>
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
export default Tables;
