import React, { useEffect } from "react";

function Qr() {


    useEffect(() => {
      //organisatino
      //menu
      //ingrediane
      
    

    }, [])
    
  return <div>Qr</div>;


}

export default Qr;
