import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";
import { useOrganisations } from "./../../Auth/useOrganisations";
import { getOrganisationById } from "../../Services/OrganizationApi";
import { useUser } from "./../../Auth/useUser";
import { changeLanguage } from "../../i18n";
import { useTranslation } from "react-i18next";

function SideBar() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [org, setOrg] = useState();
  const [logo, setLogo] = useState();
  const [menu, setMenu] = useState();
  const user = useUser();
  const [infoUser] = useState(user.result);
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  useEffect(() => {
    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
        setLogo(res.data.data.logo);
        setMenu(res.data.data.menu);
      }
    });
  }, []);

  return (
    <section className="sidebar position-relative">
      <div className="multinav" style={{ overflowY: "auto" }}>
        <div className="multinav-scroll" style={{ height: "100%" }}>
          {/* sidebar menu*/}
          <ul className="sidebar-menu" data-widget="tree">
            <li>
              <Link onClick={(e) => (window.location.href = "/")}>
                <i className="icon-Home" />
                <span>{t("Dashboard")}</span>
              </Link>
            </li>
            {org && org.type === "Restaurant" ? (
              <>
                <li>
                  <Link to="/cartes">
                    <i className="icon-Dinner">
                      <span className="path1" />
                      <span className="path2" />
                    </i>
                    <span>{t("Categories")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/categories">
                    <i className="icon-Dinner">
                      <span className="path1" />
                      <span className="path2" />
                    </i>
                    <span>{t("Sous categories")}</span>
                  </Link>
                </li>
              </>
            ) : null}

            {org && org.type !== "Rating" ? (
              <li>
                <Link to="/menus">
                  {org && org.type === "Restaurant" ? (
                    <i className="icon-Dinner">
                      <span className="path1" />
                      <span className="path2" />
                    </i>
                  ) : org && org.type === "Events" ? (
                    <i class="fa-solid fa-calendar-days">
                      <span className="path1" />
                      <span className="path2" />
                    </i>
                  ) : (
                    <i class="fa-solid fa-boxes-stacked">
                      <span className="path1" />
                      <span className="path2" />
                    </i>
                  )}
                  <span>
                    {org && org.type === "Events"
                      ? t("Événements")
                      : t("Articles")}
                  </span>
                </Link>
              </li>
            ) : null}
            {org && org.type === "Restaurant" ? (
              <>
                <li>
                  <Link to="/liste-tables">
                    <i className="icon-Commit">
                      <span className="path1" />
                      <span className="path2" />
                    </i>
                    <span>{t("Tables")}</span>
                  </Link>
                </li>
              </>
            ) : (
              <></>
            )}
            {org && org.type === "Restaurant" ? (
              <li>
                <Link to="/waiters">
                  <i className="icon-Group">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                  <span>{t("Serveurs")}</span>
                </Link>
              </li>
            ) : (
              <></>
            )}
            {org && org.type === "Restaurant" ? (
              <li>
                <Link to="/popups">
                  <i className="fa fa-rectangle-ad">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                  <span>{t("Popups")}</span>
                </Link>
              </li>
            ) : (
              <></>
            )}
            {org && org.type === "Restaurant" ? (
              <li>
                <Link to="/orders">
                  <i className="icon-Cart">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                  <span>{t("Commandes")}</span>
                </Link>
              </li>
            ) : (
              <></>
            )}
            {org && org.type === "Restaurant" ? (
              <li>
                <Link to={"/qrcodes/" + usedOrganisations}>
                  <i className="icon-Printer">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                  <span>{t("Impression des codes")}</span>
                </Link>
              </li>
            ) : (
              <></>
            )}

            {/* <li>
              <Link to="/receptionist">
                <i className="icon-Cart">
                  <span className="path1" />
                  <span className="path2" />
                </i>
                <span>Utilisateurs</span>
              </Link>
            </li> */}
          </ul>
          {org && org.type !== "Showroom" && org.type !== "Rating" ? (
            <div className="sidebar-widgets">
              <div className="side-bx bg-primary bg-food-dark rounded20">
                <div className="text-center">
                  <img
                    src="../images/res-menu.png"
                    className="sideimg"
                    alt=""
                  />

                  <div className="sidebar-widgets">
                    <QRCodeCanvas
                      value={
                        org && org.menu === 5
                          ? document.location.origin +
                            "/menu5" +
                            "/" +
                            usedOrganisations +
                            "/" +
                            btoa((1).toString())
                          : document.location.origin +
                            "/menuredirect" +
                            "/" +
                            usedOrganisations +
                            "/" +
                            btoa((1).toString())
                      }
                      size={200}
                      bgColor={"#ffffff"}
                      level={"H"}
                      onClick={(e) =>
                        window
                          .open(
                            org && org.menu === 5
                              ? document.location.origin +
                                  "/menu5" +
                                  "/" +
                                  usedOrganisations +
                                  "/" +
                                  btoa((1).toString())
                              : document.location.origin +
                                  "/menuredirect" +
                                  "/" +
                                  usedOrganisations +
                                  "/" +
                                  btoa((1).toString())
                          )
                          .focus()
                      }
                      style={{ cursor: "pointer" }}
                      // imageSettings={{
                      //   src: "https://via.placeholder.com/100/000000/ffffff/?text=1",
                      //   x: undefined,
                      //   y: undefined,
                      //   height: 26,
                      //   width: 26,
                      //   excavate: true,
                      // }}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="copyright text-left m-25">
								<p><strong className="d-block">Riday Admin Dashboard</strong> © 2023 All Rights Reserved</p>
							</div> */}
            </div>
          ) : null}
          {org && org.type === "Rating" ? (
            <div className="sidebar-widgets">
              <div className="side-bx bg-primary bg-food-dark rounded20">
                <div className="text-center">
                  <img
                    src="../images/res-menu.png"
                    className="sideimg"
                    alt=""
                  />

                  <div className="printablediv">
                    <QRCodeCanvas
                      value={
                        document.location.origin +
                        "/satisfaction" +
                        "/" +
                        usedOrganisations
                      }
                      size={200}
                      bgColor={"#ffffff"}
                      level={"H"}
                      onClick={(e) =>
                        window
                          .open(
                            document.location.origin +
                              "/satisfaction" +
                              "/" +
                              usedOrganisations,
                            "_blank",
                            "tab"
                          )
                          .focus()
                      }
                      style={{ cursor: "pointer" }}
                    />

                    {/* Add the print button */}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
}

export default SideBar;
