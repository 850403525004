import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";
import { useOrganisations } from "./../../Auth/useOrganisations";
import { getOrganisationById } from "../../Services/OrganizationApi";
import { useUser } from "./../../Auth/useUser";

function SideBarWaiter() {
  const [org, setOrg] = useState();
  const [logo, setLogo] = useState();
  const [menu, setMenu] = useState();
  const user = useUser();
  const [infoUser] = useState(user.result);
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  useEffect(() => {
    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
        setLogo(res.data.data.logo);
        setMenu(res.data.data.menu);
      }
    });
  }, []);
  return (
    <section className="sidebar position-relative">
      <div className="multinav" style={{ overflowY: "auto" }}>
        <div className="multinav-scroll" style={{ height: "100%" }}>
          {/* sidebar menu*/}
          <ul className="sidebar-menu" data-widget="tree">
            <li>
              <Link onClick={(e) => (window.location.href = "/")}>
                <i className="icon-Home" />
                <span>Acceuil</span>
              </Link>
            </li>
            <li>
              <Link to="/waitertables">
                <i className="icon-Dinner">
                  <span className="path1" />
                  <span className="path2" />
                </i>
                <span>Tables</span>
              </Link>
            </li>
            <li>
              <Link to="/tips">
                <i className="icon-Money">
                  <span className="path1" />
                  <span className="path2" />
                </i>
                <span>Tips</span>
              </Link>
            </li>
           
          </ul>
        </div>
      </div>
    </section>
  );
}

export default SideBarWaiter;
