import React, { useEffect, useState } from "react";

function TemplateCleanerCatalog() {
    useEffect(() => {
        //preventing other menus templates scripts and styles in the new template
        var linkElements = document.querySelectorAll("link[rel='stylesheet']");
        var styleElements = document.querySelectorAll("style");
        for (var i = 0; i < linkElements.length; i++) {
            var linkElement = linkElements[i];
            linkElement.parentNode.removeChild(linkElement);
        }
        for (var i = 0; i < styleElements.length; i++) {
            var styleElement = styleElements[i];
            if (!styleElement.hasAttribute('defer') && !styleElement.hasAttribute('static')) {
                styleElement.parentNode.removeChild(styleElement);
            }
        }
        var scriptElements = document.getElementsByTagName('script');

        for (var i = scriptElements.length - 1; i >= 0; i--) {
            var scriptElement = scriptElements[i];
            if (!scriptElement.hasAttribute('defer') && !scriptElement.hasAttribute('static')) {
                scriptElement.parentNode.removeChild(scriptElement);
            }
        }
        console.clear();
        //Mentioning all the necessary needed scripts and styles in the new menu 
        const cssToAppend = [
            '/newmenu/vender/bootstrap/css/bootstrap.min.css',
            '/newmenu/vender/icons/icofont.min.css',
            '/newmenu/vender/slick/slick/slick.css',
            '/newmenu/vender/slick/slick/slick-theme.css',
            '/newmenu/vender/fontawesome/css/all.min.css',
            '/newmenu/vender/sidebar/demo.css',
            '/newmenu/css/styleCatalog.css',
            '/newmenu/css/sweetalert.css',
            '/newmenu/css/toastify.css'
        ];

        var headElement = document.head || document.getElementsByTagName('head')[0];
        cssToAppend.forEach(cssFile => {
            var linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet'; // Specify the relationship (stylesheet)
            linkElement.type = 'text/css';   // Specify the type (CSS)
            linkElement.href = cssFile;      // Set the URL to the CSS file
            headElement.appendChild(linkElement);
        });

        const jsToAppend = [
            '/newmenu/vender/bootstrap/js/bootstrap.bundle.min.js',
            '/newmenu/vender/slick/slick/slick.min.js',
            '/newmenu/js/custom.js',
        ];
        var targetElement = document.body;
        jsToAppend.forEach(jsFile => {
            var scriptElement = document.createElement('script');
            scriptElement.src = jsFile;
            targetElement.appendChild(scriptElement);
        });

        var resize = new Event('resize');
        document.dispatchEvent(resize);
    }, [])

    return(
        <></>
    )
}

export default TemplateCleanerCatalog;
