import React from "react";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  return (
    <footer className="main-footer">
      © 2023 <a href="https://www.clediss.com/">CLEDISS</a>.{" "}
      {t("Tous droits réservés.")}
    </footer>
  );
}

export default Footer;
