import React, { useEffect, useState } from "react";
import { useOrganisations } from "../Auth/useOrganisations";
import { closeOrders, getAllNotPaidOrders } from "../Services/CartApi";
import { getOrganisationById } from "../Services/OrganizationApi";
import GifLoader from "../Components/loader";
import { Link } from "react-router-dom";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import swal from "sweetalert";
import { useUser } from "../Auth/useUser";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TablesWaiter() {
  const [tables, setTables] = useState([]);
  const [tableOrders, setTableOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentTable, setCurrentTable] = useState();
  const [open, setOpen] = useState(false);
  const [tableStatus, setTableStatus] = useState("available");
  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);
  const [rand, setRand] = useState(0);
  const [notPaidOrders, setNotPaidOrders] = useState([]);
  const user = useUser();
  const [infoUser] = useState(user.result);
  const forceUpdate = useForceUpdate();

  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();

  useEffect(() => {
    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setTables(res.data.data.tables);
        setLoading(false);
      }
    });
    getAllNotPaidOrders(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setNotPaidOrders(res.data.data);
      }
    });
  }, [rand]);

  function getTableStatus(table) {
    for (let i = 0; i < notPaidOrders.length; i++) {
      if (notPaidOrders[i].table === table) {
        return "full";
      }
    }
    return "available";
  }

  const promptForPaid = (table) => {
    swal({
      title: "Voulez-vous clôturer cette table ?",
      text: "Cette action va changer les statuts de tous les commandes à 'Payée'",
      icon: "warning",
      buttons: ["Non", "Oui"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        closeOrders(table, usedOrganisations).then((res) => {
          const success = res.data.success;
          if (success !== "0") {
            swal("Table clôturée !", {
              icon: "success",
            });
            setRand(rand + 1);
          }
        });
      } else {
        swal("Table encore occupée ! ");
      }
    });
  };

  return (
    <section className="">
      <GifLoader loading={loading} />
      <div className="row">
        {infoUser &&
          infoUser.tables.map((item, index) => (
            <div
              className="col-xxxl-4 col-xl-4 col-lg-6 col-12"
              key={index}
              onClick={(e) => {
                if (getTableStatus(item) === "full") {
                  window.location.href = "/waitertables/orders/" + item;
                }
              }}
            >
              <div className="box overflow-h">
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    className="rounded img-fluid"
                    src={`./images/${getTableStatus(item)}.png`}
                    style={{ padding: "50px" }}
                  />
                  <div
                    className="number"
                    style={{
                      position: "absolute",
                      top: "77%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      textAlign: "center",
                      width: "100%",
                      color: "black",
                      fontWeight: "bold",
                      width: "30px",
                      height: "30px",
                      backgroundColor: "white",
                      padding: "5px",
                      borderRadius: "12px",
                    }}
                  >
                    {item}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
}

export default TablesWaiter;
