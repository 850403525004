import React, { useEffect, useState } from "react";
import TemplateCleaner from "./TemplateCleaner";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getEvents } from "../../Services/OrganizationApi";
import { getMenus } from "../../Services/MenuApi";

function NewMenuFooter({ id, table, ...props }) {
  const addition = JSON.parse(localStorage.getItem("addition"));
  const [eventsOrg, setEventsOrg] = useState();
  const [events, setEvents] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    changeLanguage(localStorage.getItem("selectedLanguage"));
    getEvents(id).then((res) => {
      const success = res.data.success;
      if (success === "0" || !res.data.relatedOrganizations.length) {
      } else {
        setEventsOrg(res.data);
        getMenus(res.data.relatedOrganizations[0]._id).then((response) => {
          const success = response.data.success;
          if (success === "0") {
            alert("error");
          } else {
            setEvents(response.data.data);
          }
        });
      }
    });
  }, []);
  return (
    <>
      <div className="pt-4 pb-5"></div>
      <div className="fixed-bottom bg-white shadow px-3 py-2 osahan-nav-bottom">
        <div className="row links">
          <div className="col-3 text-center">
            <Link to={"/menu5/" + id + "/" + table}>
              <span>
                <i className="fa-solid fa-utensils fa-lg"></i>
              </span>
              <p className="m-0">{t("menu")}</p>
            </Link>
          </div>
          <div className="col-3 p-0 text-center">
            <Link to={"/menu5/" + id + "/" + table + "/cart"}>
              <span>
                <i class="fa-solid fa-cart-shopping fa-lg"></i>
              </span>
              <p className="m-0">{t("Cart")}</p>
            </Link>
          </div>
          <div className="col-3 p-0 text-center">
            {addition && addition.prix > 0 ? (
              <Link to={"/menu5/" + id + "/" + table + "/addition"}>
                <span>
                  <i className="fa-solid fa-file-invoice fa-lg"></i>
                </span>
                <p className="m-0">{t("Addition")}</p>
              </Link>
            ) : (
              <Link
                onClick={(e) => {
                  if (addition && addition.prix <= 0) {
                    toast.error(t("Passez une commande d'abord !"));
                  }
                }}
              >
                <span>
                  <i className="fa-solid fa-file-invoice fa-lg"></i>
                </span>
                <p className="m-0">{t("Addition")}</p>
              </Link>
            )}
          </div>
          <div className="col-3 p-0 text-center">
            {eventsOrg && events ? (
              <Link to={"/menu5/" + id + "/" + table + "/events"}>
                <span>
                  <i className="fa-solid fa-calendar-days fa-lg"></i>
                </span>
                <p className="m-0">{t("events")}</p>
              </Link>
            ) : (
              <Link
                onClick={(e) => {
                  toast.error(t("no events available"));
                }}
              >
                <span>
                  <i className="fa-solid fa-calendar-days fa-lg"></i>
                </span>
                <p className="m-0">{t("events")}</p>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NewMenuFooter;
