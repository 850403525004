import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { forgetPassword, getUsersByEmail } from "../Services/UserApi";

function ForgotPassword() {
  const [email, setEmail] = useState();

  const promptForDelete = () => {
    swal({
      title: "we sent you a mail !",
      icon: "success",
    }).then(function () {
      // window.location = "/categories";
    });
  };
  const onSubmit = async (e) => {
    //getUsersByEmail
    e.preventDefault();

    // alert("ajouté avec succees");
    forgetPassword(email).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        // alert("ajouté avec succees");
        promptForDelete();
      }
    });
  };
  return (
    <div className="container h-p100" style={{ marginTop: "150px" }}>
      <div className="row align-items-center justify-content-md-center h-p100">
        <div className="col-12">
          <div className="row justify-content-center no-gutters">
            <div className="col-lg-5 col-md-5 col-12">
              <div className="bg-white rounded30 shadow-lg">
                <div className="content-top-agile p-20 pb-0">
                  <h3 className="mb-0 text-primary">Recover Password</h3>
                </div>
                <div className="p-40">
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-email"></i>
                          </span>
                        </div>
                        <input
                          type="email"
                          className="form-control pl-15 bg-transparent"
                          placeholder="Email ou mobile"
                          onChange={(e) => setEmail(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <button
                          type="submit"
                          className="btn btn-info margin-top-10"
                        >
                          Continuer
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
