import React, { useEffect, useState } from "react";
import { deleteCategorie, getCategories } from "../Services/CategrieApi";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { useOrganisations } from "./../Auth/useOrganisations";
import { getMenuByCategoryId } from "../Services/MenuApi";
import { getOrganisationById } from "../Services/OrganizationApi";
import GifLoader from "../Components/loader";
import { useTranslation } from "react-i18next";

function CategoryList() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [filter, setFilter] = useState("");
  const [categorie, setCategorie] = useState([]);
  const [displayedCategory, setDisplayedCategory] = useState();
  const [rand, setRand] = useState(0);
  const [currency, setCurrency] = useState();
  const [loading, setLoading] = useState(true);
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();

  const categoryLibelle = (libelle) => {
    if (typeof libelle === "object") {
      return libelle.fr;
    } else {
      return libelle;
    }
  };

  const promptForDelete = (category_id) => {
    swal({
      title: "Etes-vous sûr ?",
      text: "Une fois supprimé, vous le perdrez !",
      icon: "warning",
      buttons: ["Non", "Oui"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteCategorie(category_id).then((res) => {
          if (res && res.data.success) {
            swal("Pouf ! Votre catégorie a été supprimée !", {
              icon: "success",
            });
            setRand(rand + 1);
          } else {
            swal("Erreur lors de la suppression de la catégorie !", {
              icon: "error",
            });
          }
        });
      } else {
        swal("Votre catégorie est en sécurité !");
      }
    });
  };

  useEffect(() => {
    getCategories(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        const finalCatList = [];
        setCategorie(res.data.data);
        console.log(categorie);
      }
    });
    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setCurrency(res.data.data.currency);
        setLoading(false);
      }
    });
  }, [rand, usedOrganisations]);

  return (
    <section className="content">
      <GifLoader loading={loading} />
      <div className="row">
        <div className="col-8">
          <form>
            <div className="input-group">
              <input
                type="search"
                className="form-control"
                placeholder="Recherche ..."
                aria-label="Search"
                aria-describedby="button-addon2"
                style={{ padding: "10px", marginTop: "9px" }}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
          </form>
        </div>

        <div className="col-4">
          <Link
            to={"/categories/add"}
            className="btn btn-success mt-10 d-block text-center"
            style={{ right: "0", float: "right", marginBottom: "20px" }}
          >
            <i className="fa fa-plus mr-5"></i>
            {t("Ajouter")}
          </Link>
        </div>
      </div>
      <div className="row">
        {categorie &&
          categorie.map((value, index) => (
            <>
              {categoryLibelle(value.libelle)
                .toLowerCase()
                .includes(filter.toLowerCase()) ? (
                <div
                  className="col-xxxl-4 col-xl-4 col-lg-6 col-12"
                  key={index}
                >
                  <div className="box overflow-h">
                    <div
                      className="box-body p-0"
                      style={{ position: "relative" }}
                    >
                      <div className="act-btn d-flex justify-content-between right-action-bar">
                        <div className="text-center mx-5">
                          <button
                            className="waves-effect waves-circle btn btn-circle btn-success-light btn-xs mb-5"
                            data-toggle="modal"
                            data-target="#modal-center"
                            onClick={(e) => setDisplayedCategory(value)}
                          >
                            <i className="fa fa-eye"></i>
                          </button>
                        </div>
                        <div className="text-center mx-5">
                          <Link
                            to={"/categories/edit/" + value._id}
                            className="waves-effect waves-circle btn btn-circle btn-danger-light btn-xs mb-5"
                          >
                            <i className="fa fa-edit"></i>
                          </Link>
                        </div>
                        <div className="text-center mx-5">
                          <button
                            className="waves-effect waves-circle btn btn-circle btn-primary-light btn-xs mb-5"
                            onClick={(e) => promptForDelete(value._id)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </div>
                      </div>
                      <Link to={"/categories/edit/" + value._id}>
                        <div>
                          <img
                            className="rounded img-fluid"
                            src={
                              value.logo
                                ? process.env.REACT_APP_API_BASE_URL +
                                  value.logo
                                : "../images/product/no-image.png"
                            }
                            // style={{ width: "200px", height: "200px" }}
                          />
                        </div>
                      </Link>
                    </div>
                    <div className="box-body">
                      <div className="info-content">
                        <Link to={"/categories/edit/" + value._id}>
                          <h4 className="mb-10 mt-0">
                            {categoryLibelle(value.libelle)}
                          </h4>
                        </Link>
                        <div className="d-flex justify-content-between align-items-center">
                          <h5 className="mb-0 text-primary">
                            {value.minPrice} {currency} - {value.maxPrice}{" "}
                            {currency}
                          </h5>
                          <div className="d-flex align-items-center">
                            <span className="font-size-18 mx-10 text-primary icon-Miso-soup">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </span>
                            <Link to={"/categories/" + value._id}>
                              <h5 className="text-black mb-0">
                                {value.productCount} {t("Articles")}
                              </h5>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ))}
      </div>
      {/* Modal */}
      <div className="modal center-modal fade" id="modal-center" tabindex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t("Details de la categorie")}</h5>
              <button type="button" className="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="box box-body b-1 text-center no-shadow">
                    <img
                      src={
                        displayedCategory && displayedCategory.logo
                          ? process.env.REACT_APP_API_BASE_URL +
                            displayedCategory.logo
                          : "../images/food/sandwich.jpg"
                      }
                      id="product-image"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="col-md-8 col-sm-6">
                  <h2 className="box-title mt-0">
                    {displayedCategory &&
                      categoryLibelle(displayedCategory.libelle)}
                  </h2>

                  <p>{displayedCategory && displayedCategory.description}</p>
                </div>
              </div>
            </div>
            {/* <div className="modal-footer modal-footer-uniform">
              <Link>
                <button type="button" className="btn btn-primary float-right">
                  Modifier
                </button>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      {/* /.modal */}
    </section>
  );
}
export default CategoryList;
