import React, { useEffect, useState } from "react";
import { addMenu } from "../Services/MenuApi";
// import { useToken } from "../Auth/useToken";
import { Link } from "react-router-dom";
import { getCategories } from "../Services/CategrieApi";
import swal from "sweetalert";
import Repeater from "./../Components/Repeater";
import { useUser } from "../Auth/useUser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GifLoader from "../Components/loader";
import { useOrganisations } from "../Auth/useOrganisations";
import { getOrganisationById } from "../Services/OrganizationApi";
import { useTranslation } from "react-i18next";

function MenuFrom() {
  // const [token, setToken] = useToken();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [titre, setTitre] = useState({
    fr: "",
    en: "",
    ar: "",
  });
  const [description, setDescription] = useState({
    fr: "",
    en: "",
    ar: "",
  });
  const [ordre, setOrdre] = useState(1);
  const [image, setImage] = useState([]);
  const [info, setInfo] = useState([]);
  const [categorie, setCategorie] = useState(null);
  const [selectedCategorie, setSelectedCategorie] = useState(
    "642c156479c34ac820cd6e24"
  );
  const [prix, setPrix] = useState(null);
  const user = useUser();
  const [infoUser] = useState(user.result);
  const [loading, setLoading] = useState(true);
  const [org, setOrg] = useState();

  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();

  const promptForDelete = () => {
    swal({
      title: "Pouf ! Votre article a été ajouté avec succès !",
      icon: "success",
    }).then(function () {
      window.location = "/menus";
    });
  };
  useEffect(() => {
    getCategories(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        console.log(res.data.data);
        setCategorie(res.data.data);
      }
    });
    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
      }
    });
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (org.type === "Restaurant") {
      if (!titre || !selectedCategorie || !prix) {
        toast.error("S'il-vous-plaît remplissez tous les champs requis!");
        return false;
      }
    } else {
      if (!titre) {
        toast.error("S'il-vous-plaît remplissez tous les champs requis!");
        return false;
      }
    }

    // Validate price
    const regex = /^\d+(\.\d{1,2})?$/; // Regular expression to match a valid number with up to 2 decimal places
    const isPriceValid = regex.test(prix);

    if (org.type === "Restaurant") {
      if (!isPriceValid) {
        toast.error("Prix doit etre valide.");
        return false;
      }
    }

    addMenu(
      titre,
      description,
      info,
      image,
      selectedCategorie,
      org.type === "Events" ? 0 : prix,
      true,
      ordre
    ).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        // const link = "/ingredients/" + res.data.data.id;
        // window.location.href = link;
        promptForDelete();
      }
    });
    return false;
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <section className="content">
      <GifLoader loading={loading} />
      <div className="row">
        <div className="col-12">
          <div className="box">
            <div className="box-body">
              <form onSubmit={onSubmit}>
                <div className="form-body">
                  <div className="row">
                    <div className="col-md-6">
                      {org && org.type === "Restaurant" ? (
                        <div className="form-group">
                          <label className="font-weight-700 font-size-16">
                            {t("Titre")}
                          </label>
                          <input
                            value={titre.fr}
                            onChange={(e) =>
                              setTitre({ ...titre, fr: e.target.value })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Titre (français)"
                          />
                          <input
                            value={titre.en}
                            onChange={(e) =>
                              setTitre({ ...titre, en: e.target.value })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Titre (English)"
                          />
                          <input
                            value={titre.ar}
                            onChange={(e) =>
                              setTitre({ ...titre, ar: e.target.value })
                            }
                            type="text"
                            className="form-control"
                            placeholder="العنوان (العربية)"
                          />
                        </div>
                      ) : (
                        <div className="form-group">
                          <label className="font-weight-700 font-size-16">
                            {t("Titre")}
                          </label>
                          <input
                            value={titre.fr}
                            onChange={(e) =>
                              setTitre({
                                ...titre,
                                fr: e.target.value,
                                en: e.target.value,
                                ar: e.target.value,
                              })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Titre"
                          />
                        </div>
                      )}

                      {org && org.type === "Restaurant" ? (
                        <div className="form-group">
                          <label className="font-weight-700 font-size-16">
                            {t("Catégorie")}
                          </label>
                          <select
                            value={selectedCategorie}
                            onChange={(e) =>
                              setSelectedCategorie(e.target.value)
                            }
                            className="form-control"
                            data-placeholder="Choose a Category"
                            tabindex="1"
                          >
                            <option value={null}></option>
                            {categorie &&
                              categorie.map((value, index) => (
                                <option value={value._id} key={index}>
                                  {value.libelle.fr}
                                </option>
                              ))}
                          </select>
                        </div>
                      ) : null}

                      {org && org.type === "Restaurant" ? (
                        <div className="form-group">
                          <label className="font-weight-700 font-size-16">
                            {t("Description")}
                          </label>
                          <textarea
                            value={description.fr}
                            onChange={(e) =>
                              setDescription({
                                ...description,
                                fr: e.target.value,
                              })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Description (français)"
                          ></textarea>
                          <textarea
                            value={description.en}
                            onChange={(e) =>
                              setDescription({
                                ...description,
                                en: e.target.value,
                              })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Description (anglais)"
                          ></textarea>
                          <textarea
                            value={description.ar}
                            onChange={(e) =>
                              setDescription({
                                ...description,
                                ar: e.target.value,
                              })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Description (arabe)"
                          ></textarea>
                        </div>
                      ) : (
                        <div className="form-group">
                          <label className="font-weight-700 font-size-16">
                            {t("Description")}
                          </label>
                          <textarea
                            value={description.fr}
                            onChange={(e) =>
                              setDescription({
                                ...description,
                                fr: e.target.value,
                                en: e.target.value,
                                ar: e.target.value,
                              })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Description"
                          ></textarea>
                        </div>
                      )}
                      {org && org.type === "Restaurant" ? (
                        <>
                          <div className="form-group">
                            <label className="font-weight-700 font-size-16">
                              {t("Prix")}
                            </label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="ti-money"></i>
                              </div>
                              <input
                                value={prix}
                                onChange={(e) => setPrix(e.target.value)}
                                type="text"
                                className="form-control"
                                placeholder="50"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="font-weight-700 font-size-16">
                              {t("Ordre dans menu")}
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={ordre}
                              onChange={(e) => setOrdre(e.currentTarget.value)}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>

                    <div className="col-md-6">
                      <h4 className="box-title mt-20">{t("Uploaded Image")}</h4>
                      <div className="product-img text-left">
                        <img
                          src={
                            image.length
                              ? URL.createObjectURL(image[0])
                              : "../images/product/no-image.png"
                          }
                          alt=""
                          className="mb-15"
                        />
                        <p>{t("Upload Anonther Image")}</p>
                        <div className="btn btn-info mb-20 choose-file">
                          <input
                            onChange={(e) => setImage(e.currentTarget.files)}
                            type="file"
                            name="logo"
                            multiple
                            className="upload"
                          />
                        </div>
                        {image ? (
                          <div className="pic-actions">
                            <button className="btn btn-success left-button">
                              {t("Edit")}
                            </button>
                            <button className="btn btn-danger">
                              {t("Delete")}
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  {org && (org.type === "Showroom" || org.type === "Events") ? (
                    <>
                      <label className="font-weight-700 font-size-16">
                        Informations générales
                      </label>
                      <div
                        className="row mt-10"
                        style={{ flexWrap: "nowrap", display: "flex" }}
                      >
                        <Repeater onChange={setInfo} />
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="form-actions mt-10">
                  <Link to="/">
                    <button type="button" className="btn btn-primary">
                      Annuler
                    </button>
                  </Link>
                  <button type="submit" className="btn btn-danger left-button">
                    {" "}
                    <i className="fa fa-check"></i> {t("Ajouter")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default MenuFrom;
