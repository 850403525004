import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useUser } from "../Auth/useUser";
import {
  editOrganisation,
  getOrganisationById,
} from "../Services/OrganizationApi";
import { useOrganisations } from "./../Auth/useOrganisations";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GifLoader from "../Components/loader";
import {
  getPersonalisationMenu,
  personalisationMenu,
  updateMenuPersonalisation,
} from "../Services/TemplateMenuApi";
import { useTranslation } from "react-i18next";

function PersonalisationMenu() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [menuDetails, setMenuDetails] = useState();
  const [menu, setMenu] = useState(1);
  const [background, setBackground] = useState(1);
  const [font, setFont] = useState();
  const [fontColor, setFontColor] = useState();
  const [themeColor, setThemeColor] = useState();
  const [productImage, setProductImage] = useState(true);
  const [options, setOptions] = useState(true);
  const [image, setImage] = useState(null);

  const user = useUser();
  const [infoUser] = useState(user.result);

  const [loading, setLoading] = useState(true);

  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();

  const promptForDelete = () => {
    swal({
      title: "Pouf ! Votre menu a été mis à jour",
      icon: "success",
    }).then(function () {
      window.location = "/";
    });
  };

  useEffect(() => {
    getPersonalisationMenu().then((res) => {
      setLoading(false);
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        if (res.data.data.length) {
          setMenuDetails(res.data.data[0]);
          setFont(res.data.data[0].font);
          setFontColor(res.data.data[0].fontcolor);
          setThemeColor(res.data.data[0].themecolor);
          setBackground(res.data.data[0].background);
          setProductImage(res.data.data[0].productimage);
          setOptions(res.data.data[0].options);
        }
      }
    });
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    //@ToDo: Test all input errors here.
    if (menuDetails && menuDetails.id) {
      updateMenuPersonalisation(
        menu,
        background,
        font,
        fontColor,
        themeColor,
        productImage,
        options,
        // image,
        menuDetails.id
      ).then((res) => {
        const success = res.data.success;
        if (success === "0") {
          alert("error");
        } else {
          promptForDelete();
        }
      });
    } else {
      personalisationMenu(
        menu,
        background,
        font,
        fontColor,
        themeColor,
        productImage,
        options,
        image
      ).then((res) => {
        const success = res.data.success;
        if (success === "0") {
          alert("error");
        } else {
          promptForDelete();
        }
      });
    }

    return false;
  };

  return (
    <section className="content">
      <GifLoader loading={loading} />
      <div className="row">
        <div className="col-12">
          <div className="box" style={{ padding: "15px" }}>
            <div className="box-body">
              <form onSubmit={onSubmit}>
                <div className="form-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t("Catégorie")}
                        </label>
                        <select
                          value={font}
                          onChange={(e) => setFont(e.target.value)}
                          className="form-control"
                          data-placeholder="Choose a Category"
                          tabindex="1"
                        >
                          <option value="cookie, cursive;">Cookie</option>
                          <option value="Arial, sans-serif;">Arial</option>
                          <option value="Times New Roman, serif;">
                            Times New Roman
                          </option>
                          <option value="Comic Sans MS, cursive;">
                            Comic Sans MS
                          </option>
                          <option value="Verdana, sans-serif;">Verdana</option>
                          <option value="Impact, sans-serif;">Impact</option>
                          <option value="Courier New, monospace;">
                            Courier New
                          </option>
                          <option value="Georgia, serif;">Georgia</option>
                          <option value="Tahoma, sans-serif;">Tahoma</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label col-md-2">{t("Thème")}</label>
                        <div className="col-md-10">
                          <input
                            value={themeColor}
                            type="color"
                            name="color-choice-theme"
                            id="color-choice-theme"
                            list="color-options"
                            onChange={(e) => setThemeColor(e.target.value)}
                          />

                          <datalist id="color-options">
                            <option>#660000</option>
                            <option>#ffffff</option>
                            <option>#ffd265</option>
                            <option>#1a2734</option>
                            <option>#0a7b83</option>
                            <option>#383c3d</option>
                          </datalist>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label col-md-2">
                          {t("Couleur police")}
                        </label>
                        <div className="col-md-10">
                          <input
                            value={fontColor}
                            type="color"
                            name="color-choice-font"
                            id="color-choice-font"
                            list="color-options"
                            onChange={(e) => setFontColor(e.target.value)}
                          />

                          <datalist id="color-options">
                            <option>#660000</option>
                            <option>#ffffff</option>
                            <option>#ffd265</option>
                            <option>#1a2734</option>
                            <option>#0a7b83</option>
                            <option>#383c3d</option>
                          </datalist>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={productImage}
                                onChange={(e) =>
                                  setProductImage(e.target.checked)
                                }
                              />
                            }
                            label="Afficher les images des produits dans le menu"
                          />
                        </FormGroup>
                      </div>
                      <div className="form-group">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={options}
                                onChange={(e) => setOptions(e.target.checked)}
                              />
                            }
                            label="Option commandes && additions"
                          />
                        </FormGroup>
                      </div>
                      {/* <h4 className="box-title mt-20">Uploaded Image</h4>

                      <div className="product-img text-left">
                        <img
                          src={
                            image
                              ? URL.createObjectURL(image)
                              : "../images/product/no-image.png"
                          }
                          alt=""
                          className="mb-15"
                        />
                        <p>Upload Another Image</p>
                        <div className="btn btn-info mb-20 choose-file">
                          <input
                            onChange={(e) => setImage(e.currentTarget.files[0])} // Use e.currentTarget.files[0] to access the selected file
                            type="file"
                            name="logo"
                            multiple
                            className="upload"
                          />
                        </div>
                        {image ? (
                          <div className="pic-actions">
                            <button className="btn btn-success left-button">
                              Edit
                            </button>
                            <button className="btn btn-danger">Delete</button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div> */}
                    </div>
                  </div>
                  <div className="row">
                    <h4
                      className="col-12 box-title mt-20 mb-30"
                      style={{ textAlign: "center" }}
                    >
                      {t("Choisir un arrière plan")}
                    </h4>
                    {[...Array(20)].map((_, index) => (
                      <div
                        className="col-md-12 col-lg-4"
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          className="box"
                          style={{
                            background: background === index + 1 ? "gray" : "",
                          }}
                          onClick={(e) => setBackground(index + 1)}
                        >
                          <div className="box-body p-5">
                            <img
                              src={`../images/auth-bg/backgroundmenu/bg-${
                                index + 1
                              }.jpg`}
                              alt={`welcome email page ${index}`}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="form-actions mt-10">
                  <Link to="/categories">
                    <button type="button" className="btn btn-primary">
                      {t("Annuler")}
                    </button>
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-danger left-button"
                    // onClick={(e) => promptForDelete()}
                  >
                    {" "}
                    <i className="fa fa-check"></i> {t("Enregistrer")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default PersonalisationMenu;