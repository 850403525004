import React, { useEffect, useState } from "react";
import { deleteCategorie, getCategories } from "../Services/CategrieApi";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { useOrganisations } from "../Auth/useOrganisations";
import { getMenuByCategoryId } from "../Services/MenuApi";
import {
  editOrganisation,
  getOrganisationById,
} from "../Services/OrganizationApi";
import { QRCodeCanvas } from "qrcode.react";
import Repeat from "../Components/Shared/Repeat";
import { QRCode } from "react-qrcode-logo";
import { useUser } from "../Auth/useUser";
import GifLoader from "../Components/loader";
import { useTranslation } from "react-i18next";

function ImpressionCodes() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [tables, setTables] = useState();
  const [org, setOrg] = useState();
  const [size, setSize] = useState(400);
  const [numSize, setNumSize] = useState(70);
  const [wifiFontSize, setWifiFontSize] = useState(15);
  const [classes, setClasses] = useState("col-xxxl-4 col-xl-4 col-lg-4 col-12");
  const [paddingCard, setPaddingCard] = useState("10px 10px 10px 10px");
  const [margin, setMargin] = useState("15px");
  const [wifi, setWifi] = useState();
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState();
  const [color, setColor] = useState();
  const [textAlignPrint, setTextAlignPrint] = useState("left");
  const user = useUser();
  const [infoUser] = useState(user.result);
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  useEffect(() => {
    //check user type
    if (infoUser.type !== "Restaurant") {
      window.location.href = "/";
    }
    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
        setTables(res.data.data.tables);
        setWifi(res.data.data.wifi);
        setMenu(res.data.data.menu);
        console.log(res.data.data.menu);
        console.log(res.data.data.tables);
        setLoading(false);
      }
    });
    editOrganisation(color, usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
      }
    });
  }, []);
  function printDiv() {
    setSize(450);
    // setNumSize(75);
    // setWifiFontSize(30);
    // setTextAlignPrint("center");
    setClasses("");
    setPaddingCard("200px 200px 10px 30px");
    setMargin("30px");
    setTimeout(() => {
      window.print();
      setSize(400);
      setNumSize(70);
      setWifiFontSize(15);
      setMargin("15px");
      setClasses("col-xxxl-4 col-xl-4 col-lg-4 col-12");
      setPaddingCard("10px 10px 10px 10px");
    }, 1000);

    // setSize(200);
  }

  return (
    <section className="">
      <GifLoader loading={loading} />
      <div className="row d-print-none">
        <div className="col-8">
          <div className="form-group">
            <label className="col-form-label col-md-2">{t("Thème")}</label>
            <div className="col-md-10">
              <input
                type="color"
                name="color-choice"
                id="color-choice"
                list="color-options"
                onChange={(e) => setColor(e.currentTarget.value)}
              />

              <datalist id="color-options">
                <option>#660000</option>
                <option>#ffffff</option>
                <option>#ffd265</option>
                <option>#1a2734</option>
                <option>#0a7b83</option>
                <option>#383c3d</option>
              </datalist>
            </div>
          </div>
        </div>
        <div className="col-4">
          <button
            className="btn btn-success mt-10 d-block text-center"
            style={{ right: "0", float: "right", marginBottom: "20px" }}
            onClick={(e) => printDiv()}
          >
            <i className="fa fa-solid fa-print mr-5"></i>
            {t("Imprimer")}
          </button>
        </div>
      </div>

      <div className="">
        {Array.from({ length: tables }, (_, i) => i).map((item, index) => (
          <div
            // className={classes + " col-print-4"}
            style={{
              width: "130mm",
              height: "200mm",
              float: "left",
              marginBottom: "5mm",
              marginRight: margin,
            }}
          >
            <div
              className="overflow-h"
              style={{ width: "130mm", height: "200mm", float: "left" }}
            >
              <div
                className="box-body p-0"
                style={{
                  width: "130mm",
                  height: "200mm",
                  float: "left",
                  backgroundColor: "white",
                }}
              >
                <div>
                  <div className="">
                    <div
                      className="box-header with-border"
                      style={{ backgroundColor: color, height: "50mm" }}
                    >
                      <div className="row">
                        <h4 className="box-title col-8">
                          <img
                            className="rounded img-fluid"
                            src="/images/logo-long.png"
                            style={{ width: "55mm" }}
                          />
                        </h4>
                        <div className="avatar avatar-lg col-4" href="#">
                          <img
                            className="rounded img-fluid"
                            style={{ width: "30mm", height: "30mm" }}
                            src={process.env.REACT_APP_API_BASE_URL + org.logo}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="box-body"
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      <p>
                        <QRCodeCanvas
                          id={"qr" + item}
                          value={
                            document.location.origin +
                            "/menuredirect" +
                            "/" +
                            usedOrganisations +
                            "/" +
                            btoa((item + 1).toString())
                          }
                          size={size}
                          bgColor={"#ffffff"}
                          level={"H"}
                          onClick={(e) =>
                            window
                              .open(
                                document.location.origin +
                                  "/menuredirect" +
                                  "/" +
                                  usedOrganisations +
                                  "/" +
                                  btoa((item + 1).toString()),
                                "_blank",
                                "tab"
                              )
                              .focus()
                          }
                          imageSettings={{
                            src:
                              "https://via.placeholder.com/100/000000/ffffff/?text=" +
                              (item + 1),
                            x: undefined,
                            y: undefined,
                            height: numSize,
                            width: numSize,
                            excavate: true,
                          }}
                          style={{ cursor: "pointer" }}
                        />
                        {wifi ? (
                          <div
                            className="input-group"
                            style={{
                              width: size,
                              marginTop: "9px",
                              color: "black",
                              fontSize: wifiFontSize,
                              marginBottom: "15px",
                              textAlign: textAlignPrint,
                            }}
                          >
                            <div className="input-group-prepend">
                              <span className="input-group-text bg-transparent">
                                <i
                                  className="fa fa-solid fa-wifi"
                                  style={{ fontSize: wifiFontSize }}
                                ></i>
                              </span>
                            </div>
                            <div
                              className="form-control"
                              style={{
                                color: "black",
                                backgroundColor: "white",
                                fontSize: wifiFontSize,
                              }}
                            >
                              {wifi}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
export default ImpressionCodes;
