import React, { useEffect, useState } from "react";
import { getCarts } from "../Services/CartApi";
import { useOrganisations } from "./../Auth/useOrganisations";

import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { getOrganisationById } from "../Services/OrganizationApi";
import { useUser } from "../Auth/useUser";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import GifLoader from "../Components/loader";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 4,
};
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // A function that increment ðŸ‘†ðŸ» the previous state like here
  // is better than directly setting setValue(value + 1)
}

function Orders() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [carts, setCarts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 10;
  const [pageCount, setPageCount] = useState(0); //Math.ceil(carts.length / rowsPerPage);
  const [offset, setOffset] = useState(0); //currentPage * rowsPerPage;
  const forceUpdate = useForceUpdate();

  const { id } = useParams(); //id org from url
  const [cart, setCart] = useState();
  const [date, setDate] = useState([]);
  const [cartProducts, setCartProducts] = useState([]);
  const [rand, setRand] = useState(0);
  const [currency, setCurrency] = useState();
  const [tables, setTables] = useState(0);
  const [filterTable, setFilterTable] = useState(0);
  const [displayedCart, setDisplayedCart] = useState({
    produits: [],
    table: 0,
    prix: 0,
    status: "Nouveau",
  });
  const [currentCartArray, setCurrentCartArray] = useState([]);
  const user = useUser();
  const [infoUser] = useState(user.result);
  // const handleClose = () => setOpen(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const displayOrderModal = (cart) => {
    setDisplayedCart(cart);
    setModalOpen(true);
  };
  const [org, setOrg] = useState();

  const handlePrint = () => {
    // Hide the button before printing
    const printButton = document.getElementById("printButton");
    if (printButton) {
      printButton.style.display = "none";
    }

    // Print the modal
    window.print();

    // Restore the button's display after printing
    if (printButton) {
      printButton.style.display = "block";
    }
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  const getOrderColor = (status) => {
    switch (status) {
      case "Annulée":
        return "primary";
        break;
      case "Nouveau":
        return "warning";
        break;
      case "Livrée":
        return "success";
        break;
      default:
        return "danger";
    }
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();

  const deepArrayCompare = (array1, array2) => {
    if (array1.length !== array2.length) return false;

    for (let i = 0; i < array1.length; i++) {
      if (JSON.stringify(array1[i]) !== JSON.stringify(array2[i])) {
        return false;
      }
    }

    return true;
  };

  function newArray(produits) {
    console.log("produits", produits);
    const result = produits.reduce((acc, curr) => {
      const key = `${curr.products.titre.fr}`;
      const prix = `${curr.products.prix}`;
      const ingredientsArray = curr.ingredients.map((ingredient) => ({
        ingredient,
      }));
      const prixIngredients = ingredientsArray.reduce(
        (total, product) => total + parseInt(product.ingredient.prix),
        0
      );
      const existingItem = acc.find(
        (item) =>
          item.key === key &&
          deepArrayCompare(item.ingredients, ingredientsArray)
      );
      if (existingItem) {
        existingItem.data.push(curr.products);
      } else {
        acc.push({
          key,
          data: [curr],
          prix,
          ingredients: ingredientsArray,
          prixTotal: parseInt(prix) + parseInt(prixIngredients),
        });
      }
      return acc;
    }, []);

    return result;
  }
  const updateStartDate = (date) => {
    setStartDate(date);
    let tempCart = carts.filter((value) => {
      if (date && endDate) {
        const orderDate = new Date(value.createdAt);
        return (
          orderDate >= startDate &&
          orderDate <= endDate &&
          (filterTable == 0 || value.table == filterTable)
        );
      } else {
        return true;
      }
    });
    setCurrentCartArray(tempCart);
    setPageCount(Math.ceil(tempCart.length / rowsPerPage));
    setOffset(currentPage * rowsPerPage);
  };
  const updateEndDate = (date) => {
    setEndDate(date);
    let tempCart = carts.filter((value) => {
      if (startDate && date) {
        const orderDate = new Date(value.createdAt);
        return (
          orderDate >= startDate &&
          orderDate <= endDate &&
          (filterTable == 0 || value.table == filterTable)
        );
      } else {
        return true;
      }
    });
    setCurrentCartArray(tempCart);
    setPageCount(Math.ceil(tempCart.length / rowsPerPage));
    setOffset(currentPage * rowsPerPage);
  };

  const applyTableFilter = (event) => {
    setFilterTable(event.target.value);
    let tempCart = carts.filter((value) => {
      if (startDate && endDate) {
        const orderDate = new Date(value.createdAt);
        return (
          orderDate >= startDate &&
          orderDate <= endDate &&
          (event.target.value == 0 || value.table == event.target.value)
        );
      } else {
        return event.target.value == 0 || value.table == event.target.value;
      }
    });
    setCurrentCartArray(tempCart);
    setPageCount(Math.ceil(tempCart.length / rowsPerPage));
    setOffset(currentPage * rowsPerPage);
    forceUpdate();
  };
  useEffect(() => {
    //check user type
    if (org && org.type !== "Restaurant") {
      window.location.href = "/";
    }
    getCarts(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setCarts(res.data.data);
        setCurrentCartArray(res.data.data);
        setPageCount(Math.ceil(carts.length / rowsPerPage));
        setOffset(currentPage * rowsPerPage);
      }
    });
    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
        setCurrency(res.data.data.currency);
        setTables(res.data.data.tables);
        setLoading(false);
      }
    });
  }, [usedOrganisations]);

  return (
    <section className="content" style={{ padding: "5px 7px 0px 6px" }}>
      <GifLoader loading={loading} />
      <div className="container-full">
        <div className="content-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h3 className="page-title">{t("Liste des commandes")}</h3>
              <div className="d-inline-block align-items-center">
                {/* <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">
                        <i className="mdi mdi-home-outline"></i>
                      </a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Order
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Order List
                    </li>
                  </ol>
                </nav> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-12">
          <Link
            to={"/receptionist"}
            className="btn btn-success mt-10 d-block text-center"
            style={{ right: "0", float: "right", marginBottom: "20px" }}
          >
            
            Commandes
          </Link>
        </div> */}
        </div>
        <section className="content">
          <div className="input-group">
            <div className="row">
              <div className="col-md-4">
                <DatePicker
                  className="form-control"
                  selected={startDate}
                  onChange={(date) => updateStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start Date"
                />
              </div>
              <div className="col-md-4">
                <DatePicker
                  className="form-control"
                  selected={endDate}
                  onChange={(date) => updateEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="End Date"
                />
              </div>
              <div className="col-md-4">
                <select
                  className="form-control"
                  value={filterTable}
                  onChange={applyTableFilter}
                >
                  <option value="0">{t("Choisissez une table")}</option>
                  {Array.from({ length: tables }, (_, i) => i).map(
                    (item, index) => (
                      <option value={item + 1}>table numero {item + 1}</option>
                    )
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="box">
                <div className="box-body p-0">
                  <div className="table-responsive rounded card-table">
                    <table className="table border-no" id="example1">
                      <thead>
                        <tr>
                          <th>{t("Numero de table")}</th>
                          <th>{t("Date")}</th>
                          <th>{t("Prix")}</th>
                          <th>{t("Take away")}</th>
                          <th>{t("Status du commande")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentCartArray &&
                          currentCartArray
                            .slice(
                              rowsPerPage * currentPage,
                              rowsPerPage * (currentPage + 1)
                            )
                            .map((value, index) => (
                              <tr
                                className="hover-primary"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  displayOrderModal(value);
                                }}
                              >
                                <td>{value.table}</td>
                                <td>
                                  {new Date(value.createdAt).getDate()}/
                                  {new Date(value.createdAt).getUTCMonth() + 1}/
                                  {new Date(value.createdAt).getFullYear()}{" "}
                                  <span className="font-size-12">
                                    {new Date(
                                      value.createdAt
                                    ).toLocaleTimeString()}
                                  </span>
                                </td>
                                <td>
                                  {" "}
                                  {value.prix.toFixed(2)} {currency}{" "}
                                </td>
                                <td> {value.takeAway ? t("Oui") : "Non"} </td>

                                <td>
                                  <span
                                    className={
                                      "badge badge-pill badge-" +
                                      getOrderColor(value.status) +
                                      "-light"
                                    }
                                  >
                                    {value.status}
                                  </span>
                                </td>
                                {/* <td>
                                <div className="btn-group">
                                  <a
                                    className="hover-primary dropdown-toggle no-caret"
                                    data-toggle="dropdown"
                                  >
                                    <i className="fa fa-ellipsis-h"></i>
                                  </a>
                                  <div className="dropdown-menu">
                                    <a className="dropdown-item" href="#">
                                      Accept Order
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      Reject Order
                                    </a>
                                  </div>
                                </div>
                              </td> */}
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {currentCartArray && (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              activeLinkClassName="btn btn-danger"
              onPageChange={(data) => {
                setCurrentPage(data.selected);
              }}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          )}
        </section>
      </div>
      <Modal
        open={modalOpen}
        onClose={(e) => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
        style={{ color: "white" }}
      >
        <Box sx={{ maxHeight: "80vh", overflowY: "auto", ...style }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 20,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <i className="fa-solid fa-xmark"></i>
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ color: "black" }}
          >
            {t("Details de la commande")}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="row">
              <div className="col-12">
                <div className="page-header">
                  <h2 className="d-inline">
                    <span className="font-size-30" style={{ color: "black" }}>
                      {t("Table numéro")} {displayedCart.table}
                    </span>
                  </h2>
                </div>
              </div>
              {/* /.col */}
            </div>
            <div className="row invoice-info">
              {/* /.col */}

              {/* /.col */}
              {/* <div className="col-sm-12 invoice-col mb-15">
                <div className="invoice-details row no-margin">
                  <div className="col-md-6 col-lg-3">
                    <b>Commande </b> {displayedCart.id}
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <b>Payment Due:</b> {displayedCart.createdAt}
                  </div>
                </div>
              </div> */}
              {/* /.col */}
            </div>
            <div className="row">
              <div className="col-12 table-responsive">
                <table
                  className="table table-bordered"
                  style={{ color: "#b5b5c3" }}
                >
                  <tbody>
                    <tr>
                      <th>Article</th>
                      <th className="text-right">Prix</th>
                    </tr>
                    {displayedCart.produits &&
                      newArray(displayedCart.produits).map((val, index) => {
                        const product = val.data[0].products;
                        return (
                          <tr>
                            <td>
                              X {val.data.length} {product.titre.fr}{" "}
                            </td>
                            <td className="text-right">
                              {" "}
                              {val.prixTotal * val.data.length} {currency}{" "}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-right">
                <p className="lead">
                  <b style={{ color: "#b5b5c3" }}>{t("Date & Heure:")}</b>
                  <span className="text-danger">
                    {" "}
                    {new Date(
                      displayedCart.createdAt
                    ).toLocaleDateString()}{" "}
                    {new Date(displayedCart.createdAt).toLocaleTimeString(
                      "en-US",
                      {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      }
                    )}{" "}
                  </span>
                </p>

                <div className="total-payment">
                  <h3 style={{ color: "black" }}>
                    <b>{t("Total")} :</b> {displayedCart.prix.toFixed(2)}{" "}
                    {currency}
                  </h3>
                </div>
              </div>
            </div>
            <div className="row" style={{ justifyContent: "right" }}>
              <button
                className="waves-effect btn btn-success-light mb-5"
                id="printButton"
                onClick={handlePrint}
                style={{ display: "block" }}
              >
                <i className="fa-solid fa-print"></i> {t("Imprimer")}
              </button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </section>
  );
}
export default Orders;
