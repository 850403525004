import React, { useEffect, useState } from "react";
import { addCategorie, getCategories } from "../Services/CategrieApi";

// import { useToken } from "../Auth/useToken";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GifLoader from "../Components/loader";
import { getCartes } from "../Services/CartesApi";
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";

function CategoryForm() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [logo, setLogo] = useState(null);
  const [libelle, setLibelle] = useState({
    fr: "",
    en: "",
    ar: "",
  });
  const [ordre, setOrdre] = useState(1);
  const [loading, setLoading] = useState(true);
  const [cartes, setCartes] = useState([]);
  const [selectedCarte, setSelectedCarte] = useState(null);

  const promptForDelete = () => {
    swal({
      title: "Pouf ! Votre catégorie a été ajoutée avec succès !",
      icon: "success",
    }).then(function () {
      window.location = "/categories";
    });
  };
  useEffect(() => {
    getCartes().then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        console.log(res.data.data);
        setCartes(res.data.data);
      }
    });
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();

    // Validate libelle
    if (!libelle) {
      toast.error("Veuillez entrer un nom de catégorie valide.");
      return false;
    }
    addCategorie(logo, libelle, ordre, selectedCarte).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        // alert("ajouté avec succees");
        promptForDelete();
      }
    });
    return false;
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <section className="content">
      <GifLoader loading={loading} />
      <div className="row">
        <div className="col-12">
          <div className="box">
            <div className="box-body">
              <form onSubmit={onSubmit}>
                <div className="form-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t("Libelle")}
                        </label>
                        <input
                          value={libelle.fr}
                          onChange={(e) =>
                            setLibelle({ ...libelle, fr: e.target.value })
                          }
                          type="text"
                          className="form-control"
                          placeholder="Libelle (français)"
                        />
                        <input
                          value={libelle.en}
                          onChange={(e) =>
                            setLibelle({ ...libelle, en: e.target.value })
                          }
                          type="text"
                          className="form-control"
                          placeholder="Libelle (English)"
                        />
                        <input
                          value={libelle.ar}
                          onChange={(e) =>
                            setLibelle({ ...libelle, ar: e.target.value })
                          }
                          type="text"
                          className="form-control"
                          placeholder="العنوان (العربية)"
                        />
                      </div>
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t('Catégorie')}
                        </label>
                        <select
                          value={selectedCarte}
                          onChange={(e) => setSelectedCarte(e.target.value)}
                          className="form-control"
                          data-placeholder="Choose a Category"
                          tabindex="1"
                        >
                          <option value={null}></option>
                          {cartes &&
                            cartes.map((value, index) => (
                              <option value={value.id} key={index}>
                                {value.libelle.fr}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t('Ordre dans menu')}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={ordre}
                          onChange={(e) => setOrdre(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h4 className="box-title mt-20">{t('Uploaded Image')}</h4>
                      <div className="product-img text-left">
                        <img
                          src={
                            logo
                              ? URL.createObjectURL(logo)
                              : "../images/product/no-image.png"
                          }
                          alt=""
                          className="mb-15"
                        />
                        <p>{t('Upload Anonther Image')}</p>
                        <div className="btn btn-info mb-20 choose-file">
                          <input
                            onChange={(e) => setLogo(e.currentTarget.files[0])}
                            type="file"
                            name="logo"
                            className="upload"
                          />
                        </div>
                        {logo ? (
                          <div className="pic-actions">
                            <button className="btn btn-success left-button">
                              Edit
                            </button>
                            <button className="btn btn-danger">{t('Delete')}</button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-actions mt-10">
                  <Link to={"/categories"}>
                    <button type="button" className="btn btn-primary">
                      {t('Annuler')}
                    </button>
                  </Link>
                  <button type="submit" className="btn btn-danger left-button">
                    {" "}
                    <i className="fa fa-check"></i> {t('Ajouter')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default CategoryForm;
