import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  editCategorie,
  getCategorieById,
  getCategories,
} from "../Services/CategrieApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GifLoader from "../Components/loader";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
} from "@mui/material";
import { getCartes } from "../Services/CartesApi";
import { useTranslation } from "react-i18next";

function EditCategorie() {

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  let id = useParams();
  const [currentCategory, setCurrentCategory] = useState(null);
  const [logo, setLogo] = useState(null);
  const defaultLibelle = {
    fr:
      currentCategory && typeof currentCategory.libelle === "object"
        ? currentCategory.libelle.fr
        : "",
    en:
      currentCategory && typeof currentCategory.libelle === "object"
        ? currentCategory.libelle.en
        : "",
    ar:
      currentCategory && typeof currentCategory.libelle === "object"
        ? currentCategory.libelle.ar
        : "",
  };
  const [libelle, setLibelle] = useState(defaultLibelle);
  const [ordre, setOrdre] = useState(1);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cartes, setCartes] = useState([]);
  const [dispo, setDispo] = useState(true);

  const [selectedCarte, setSelectedCarte] = useState(null);

  const promptForDelete = () => {
    swal({
      title: "Pouf ! Votre catégorie a été mise à jour",
      icon: "success",
    }).then(function () {
      window.location = "/categories";
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // Validate libelle
    if (!libelle) {
      toast.error("Veuillez entrer un nom de catégorie valide.");
      return false;
    }
    //@ToDo: Test all input errors here.
    editCategorie(logo, libelle, ordre, dispo, selectedCarte, id.id).then(
      (res) => {
        const success = res.data.success;
        if (success === "0") {
          alert("error");
        } else {
          promptForDelete();
        }
      }
    );
    return false;
  };

  useEffect(() => {
    getCategorieById(id.id).then((res) => {
      const success = res.data.success;
      if (success === false) {
        //error
        alert("error");
      } else {
        setCurrentCategory(res.data.data);
        setLibelle(res.data.data.libelle);
        setLoading(false);
        setOrdre(res.data.data.ordre ? res.data.data.ordre : ordre);
        setSelectedCarte(res.data.data.carte_id);
        setDispo(res.data.data.dispo);
      }
    });
    getCartes().then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        console.log(res.data.data);
        setCartes(res.data.data);
      }
    });
    getCategories().then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setCategories(res.data.data);
      }
    });
  }, [id]);

  return (
    <section className="content">
      <GifLoader loading={loading} />
      <div className="row">
        <div className="col-12">
          <div className="box">
            <div className="box-body">
              <form onSubmit={onSubmit}>
                <div className="form-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t('Titre')}
                        </label>
                        <input
                          value={typeof libelle === 'object' ? libelle.fr : libelle}
                          onChange={(e) =>
                            setLibelle({ ...libelle, fr: e.target.value })
                          }
                          type="text"
                          className="form-control"
                          placeholder="Libelle (fr)"
                        />
                        <input
                          value={libelle.en}
                          onChange={(e) =>
                            setLibelle({ ...libelle, en: e.target.value })
                          }
                          type="text"
                          className="form-control"
                          placeholder="Libelle (en)"
                        />
                        <input
                          value={libelle.ar}
                          onChange={(e) =>
                            setLibelle({ ...libelle, ar: e.target.value })
                          }
                          type="text"
                          className="form-control"
                          placeholder="Titre (ar)"
                        />
                      </div>
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t('Catégorie')}
                        </label>
                        <select
                          defaultValue={selectedCarte}
                          value={selectedCarte}
                          onChange={(e) => setSelectedCarte(e.target.value)}
                          className="form-control"
                          tabindex="1"
                        >
                          <option value={null}></option>
                          {cartes &&
                            cartes.map((value, index) => (
                              <option value={value.id} key={index}>
                                {value.libelle.fr}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t('Ordre dans menu')}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={ordre}
                          onChange={(e) => setOrdre(e.currentTarget.value)}
                        />
                      </div>
                      <div className="col-xl-3 col-6 text-center mb-20">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={dispo}
                                onChange={(e) => setDispo(e.target.checked)}
                              />
                            }
                            label="Disponibilité"
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h4 className="box-title mt-20">{t('Image')}</h4>
                      <div className="product-img text-left">
                        <img
                          src={
                            logo
                              ? URL.createObjectURL(logo)
                              : currentCategory && currentCategory.logo
                              ? process.env.REACT_APP_API_BASE_URL +
                                currentCategory.logo
                              : "/../images/product/product-9.png"
                          }
                          alt=""
                          className="mb-15"
                        />
                        <p>{t("Changer l'image")}</p>
                        <div className="btn btn-info mb-20 choose-file">
                          <input
                            type="file"
                            name="logo"
                            className="upload"
                            onChange={(e) => setLogo(e.currentTarget.files[0])}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-actions mt-10">
                  <Link to="/categories">
                    <button type="button" className="btn btn-primary">
                      {t("Annuler")}
                    </button>
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-danger left-button"
                    onClick={(e) => promptForDelete()}
                  >
                    {" "}
                    <i className="fa fa-check"></i> {t("Enregistrer")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default EditCategorie;
