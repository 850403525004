import React, { useEffect, useState } from "react";
import {
  addIngredient,
  deleteIngredient,
  editIngredient,
  getIngredientsByMenu,
} from "../Services/IngredientsApi";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { getOrganisationById } from "../Services/OrganizationApi";
import { useOrganisations } from "../Auth/useOrganisations";

function Ingredients() {
  const [ingredient, setingredient] = useState(null);
  const [image, setImage] = useState(null);
  const [titre, setTitre] = useState();
  const [description, setDescription] = useState();
  const [quantite, setQuantite] = useState();
  const [unite, setUnite] = useState();
  const [calories, setCalories] = useState();
  const [currency, setCurrency] = useState();
  const [prix, setPrix] = useState();

  const { id } = useParams();
  const [rand, setRand] = useState(0);
  const [ingredientToEdit, setIngredientToEdit] = useState("");

  const promptForDelete = (ingredient_id) => {
    swal({
      title: "Etes-vous sûr ?",
      text: "Une fois supprimé, vous le perdrez !",
      icon: "warning",
      buttons: ["Non", "Oui"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteIngredient(ingredient_id).then((res) => {
          if (res && res.data.success) {
            swal("Pouf ! Votre ingredient a été supprimée !", {
              icon: "success",
            });
            setRand(rand + 1);
          } else {
            swal("Erreur lors de la suppression du menu !", {
              icon: "error",
            });
          }
        });
      } else {
        swal("Votre menu est en sécurité !");
      }
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    //@ToDo: Test all input errors here.
    if (ingredientToEdit) {
      editIngredient(titre, prix, id, ingredientToEdit).then((res) => {
        const success = res.data.success;
        if (success === "0") {
          alert("error");
        } else {
          document.getElementById("dismissModal").click();
          setTitre("");
          setPrix("");
          setRand(rand + 1);
          setIngredientToEdit("");
        }
      });
      return;
    } else {
      addIngredient(titre, prix, id).then((res) => {
        const success = res.data.success;
        if (success === "0") {
          alert("error");
        } else {
          document.getElementById("dismissModal").click();
          setTitre("");
          setPrix("");
          setRand(rand + 1);
        }
      });
      return;
    }

    return false;
  };

  const openEditModal = (value) => {
    setIngredientToEdit(value.id);
    setTitre(value.titre);
    setPrix(value.prix);
    document.getElementById("openModal").click();
  };
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  useEffect(() => {
    getIngredientsByMenu(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        console.log(res.data.data);
        setingredient(res.data.data);
      }
    });
    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setCurrency(res.data.data.currency);
      }
    });
  }, [rand]);

  return (
    <section className="content">
      <div className="row">
        <h2>Ingredients de menu</h2>
        <div className="col-lg-12 col-md-8">
          <div className="box">
            <div className="media-list media-list-divided media-list-hover">
              {ingredient &&
                ingredient.map((value, index) => (
                  <div className="media align-items-center">
                    <div className="media-body">
                      <p>
                        <strong>
                          {value.titre} +{value.prix}
                          {currency}
                        </strong>
                      </p>
                    </div>

                    <div className="media-right gap-items">
                      <Link
                        className="media-action lead"
                        data-toggle="tooltip"
                        title="Orders"
                        onClick={(e) => promptForDelete(value.id)}
                      >
                        <i className="ti-trash"></i>
                      </Link>
                      <Link
                        className="media-action lead"
                        data-toggle="tooltip"
                        title="Receipts"
                        onClick={(e) => openEditModal(value)}
                      >
                        <i className="ti-pencil"></i>
                      </Link>
                    </div>
                  </div>
                ))}
              <div className="box-body">
                <a
                  href="javascript:void(0)"
                  data-toggle="modal"
                  data-target="#myModal"
                  className="btn btn-success mt-10 d-block text-center"
                  id="openModal"
                  onMouseEnter={(e) => {
                    setTitre("");
                    setPrix("");
                    setIngredientToEdit("");
                  }}
                >
                  +
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-3 col-md-4">
          <div className="box no-shadow">
            <div className="box-body">
              <a
                href="javascript:void(0)"
                data-toggle="modal"
                data-target="#myModal"
                className="btn btn-success mt-10 d-block text-center"
                id="openModal"
                onMouseEnter={(e) => {
                  setTitre("");
                  setDescription("");
                  setImage(null);
                  setQuantite("");
                  setCalories("");
                  setUnite("");
                  setIngredientToEdit("");
                }}
              >
                +
              </a>
            </div>
          </div>
        </div> */}
      </div>
      {/* modal */}
      <div
        id="myModal"
        className="modal fade in"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Ajouter un ingredient
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={onSubmit}>
                <div className="form-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          Titre
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Titre"
                          value={titre}
                          onChange={(e) => setTitre(e.currentTarget.value)}
                        />
                      </div>

                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          prix
                        </label>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="ti-money"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            value={prix}
                            onChange={(e) => setPrix(e.currentTarget.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-success">
                    Add
                  </button>
                  <button
                    type="button"
                    className="btn btn-default float-right"
                    data-dismiss="modal"
                    id="dismissModal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Ingredients;
