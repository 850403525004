import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getSocials } from "../../Services/SocialsApi";
import { useOrganisations } from "../../Auth/useOrganisations";
import { useTranslation } from "react-i18next";

function RightBar() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [facebook, setFacebook] = useState();
  const [youtube, setYoutube] = useState();
  const [instagram, setInstagram] = useState();
  const [website, setWebsite] = useState();
  const [socials, setSocials] = useState({
    facebook: "",
    youtube: "",
    instagram: "",
    website: "",
  });

  useEffect(() => {
    getSocials(null).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setSocials(res.data.data);
        setFacebook(res.data.data.facebook);
        setInstagram(res.data.data.instagram);
        setYoutube(res.data.data.youtube);
        setWebsite(res.data.data.website);
      }
    });
  }, []);
  return (
    <div className="right-bar">
      <div id="sidebarRight">
        <div className="right-bar-inner">
          <div className="box">
            <div className="box-header with-border">
              <h4 className="box-title">{t("Social Medias")}</h4>
            </div>
            <div className="box-body p-0">
              <div className="media-list media-list-hover media-list-divided">
                <Link
                  onClick={(e) => (window.location.href = socials.facebook)}
                  className="media media-single"
                  href="#"
                >
                  <i className="fa fa-brands fa-facebook"></i>
                  <span className="title">Facebook </span>
                </Link>
                <Link
                  onClick={(e) => (window.location.href = socials.youtube)}
                  className="media media-single"
                  href="#"
                >
                  <i className="fa fa-brands fa-youtube"></i>
                  <span className="title">Youtube</span>
                </Link>
                <Link
                  onClick={(e) => (window.location.href = socials.instagram)}
                  className="media media-single"
                  href="#"
                >
                  <i className="fa fa-brands fa-instagram"></i>
                  <span className="title">Instagram</span>
                </Link>
                <Link
                  onClick={(e) => (window.location.href = socials.website)}
                  className="media media-single"
                  href="#"
                >
                  {" "}
                  <i className="fa fa-solid fa-globe"></i>
                  <span className="title">Site Web</span>
                </Link>{" "}
              </div>
            </div>
            <h4 className="box-title mt-20">
              <Link
                to={"/socials"}
                className="btn btn-success mt-10 d-block text-center"
              >
                {t("Ajouter")}/ {t("modifier")}
              </Link>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightBar;
