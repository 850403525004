import * as React from "react";

import { useEffect } from "react";
import { useState } from "react";

import { getMenus, getMenusByOrg } from "../Services/MenuApi";

export default function Events({ eventsOrg, ...props }) {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    if (
      eventsOrg &&
      eventsOrg.relatedOrganizations &&
      eventsOrg.relatedOrganizations.length > 0
    ) {
      getMenusByOrg(eventsOrg.relatedOrganizations[0].id).then((res) => {
        const success = res.data.success;
        if (success === "0") {
          alert("error");
        } else {
          setEvents(res.data.data);
          console.log(res.data.data);
        }
      });
    }
  }, [eventsOrg]);

  return (
    <>
      {eventsOrg && eventsOrg.relatedOrganizations && events.length ? (
        <div>
          <button
            className="waves-effect waves-light btn btn-rounded btn-warning mb-5"
            style={{ backgroundColor: "black", color: "burlywood" }}
            onClick={(e) =>
              (window.location.href =
                "/menu3" +
                "/" +
                eventsOrg.relatedOrganizations[0].id +
                "/" +
                events[0].id)
            }
          >
            {" "}
            Check our event planning! <i class="fa-solid fa-angles-right"></i>
          </button>
        </div>
      ) : null}
    </>
  );
}
