import React from "react";
import Footer from "../Components/Shared/Footer";
import HeaderWaiter from './../Components/Shared/HeaderWaiter';
import SideBarWaiter from "../Components/Shared/SideBarWaiter";

function WaiterLayout(props) {
  return (
    <div>
      <div className="wrapper">
        {/*  <div id="loader" /> */}
        <HeaderWaiter></HeaderWaiter>
        <aside className="main-sidebar">
          {/* sidebar*/}
          <SideBarWaiter></SideBarWaiter>
        </aside>
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper" style={{marginRight:'0'}}>
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <React.Fragment>{props.children}</React.Fragment>
            </section>
            {/* /.content */}
          </div>
        </div>
        {/* /.content-wrapper */}
        {/* Content Right Sidebar */}
        {/* /.Content Right Sidebar */}
        <Footer></Footer>
        {/* Control Sidebar */}
        {/* /.control-sidebar */}
        {/* Add the sidebar's background. This div must be placed immediately after the control sidebar */}
        <div className="control-sidebar-bg" />
      </div>
      {/* ./wrapper */}
    </div>
  );
}

export default WaiterLayout;
