import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { deleteMenu, getMenuByCategoryId, getMenus } from "../Services/MenuApi";
import swal from "sweetalert";
import { useOrganisations } from "../Auth/useOrganisations";
import { getIngredientsByMenu } from "../Services/IngredientsApi";
import { getCategorieById } from "../Services/CategrieApi";
import { getOrganisationById } from "../Services/OrganizationApi";
import GifLoader from "../Components/loader";
import { useTranslation } from "react-i18next";

function CategoryMenus() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  let id = useParams();
  const [menus, setMenus] = useState([]);
  const [displayedCategory, setDisplayedCategory] = useState();
  const [displayedMenu, setDisplayedMenu] = useState();
  const [rand, setRand] = useState(0);
  const [currency, setCurrency] = useState();
  const [loading, setLoading] = useState(true);
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();

  const promptForDelete = (menu_id) => {
    swal({
      title: "Etes-vous sûr ?",
      text: "Une fois supprimé, vous le perdrez !",
      icon: "warning",
      buttons: ["Non", "Oui"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteMenu(menu_id).then((res) => {
          if (res && res.data.success) {
            swal("Pouf ! Votre article a été supprimée !", {
              icon: "success",
            });
            setRand(rand + 1);
          } else {
            swal("Erreur lors de la suppression du menu !", {
              icon: "error",
            });
          }
        });
      } else {
        swal("Votre menu est en sécurité !");
      }
    });
  };
  useEffect(() => {
    getCategorieById(id.id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        console.log(res.data.data);
        setDisplayedCategory(res.data.data);
      }
    });
    getMenuByCategoryId(id.id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        console.log(res.data.data);
        setMenus(res.data.data);
      }
    });
    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setCurrency(res.data.data.currency);
        setLoading(false);
      }
    });
  }, [rand, usedOrganisations]);

  return (
    <section className="content">
      <GifLoader loading={loading} />
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="mr-auto">
            {/* <h3 className="page-title"> Vos {displayedCategory.libelle}</h3> */}
            <div className="d-inline-block align-items-center"></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Link
            to={"/menus"}
            className="btn btn-success mt-10 d-block text-center"
            style={{ right: "0", float: "right", marginBottom: "20px" }}
          >
            <i className="fa fa-eye mr-5"></i>
            Voir tous les articles
          </Link>
        </div>
      </div>
      <div className="row">
        {menus &&
          menus.map((value, index) => (
            <div className="col-xxxl-3 col-xl-4 col-lg-6 col-12">
              <div className="box food-box">
                <div className="box-body text-center">
                  <div className="menu-item">
                    <img
                      className="rounded img-fluid"
                      src={
                        value.image.length
                          ? process.env.REACT_APP_API_BASE_URL + value.image
                          : "../images/product/no-image.png"
                      }
                      alt=""
                    />
                  </div>
                  <div className="menu-details text-center">
                    <h4 className="mt-20 mb-10">{value.titre.fr}</h4>
                  </div>
                  <div className="act-btn d-flex justify-content-between">
                    <div className="text-center mx-5">
                      <button
                        className="waves-effect waves-circle btn btn-circle btn-success-light btn-xs mb-5"
                        data-toggle="modal"
                        data-target="#modal-center"
                        onClick={(e) => setDisplayedMenu(value)}
                      >
                        <i className="fa fa-eye"></i>
                      </button>
                      <small className="d-block">{t("View")}</small>
                    </div>
                    <div className="text-center mx-5">
                      <Link
                        to={"/menu/edit/" + value.id}
                        className="waves-effect waves-circle btn btn-circle btn-danger-light btn-xs mb-5"
                      >
                        <i className="fa fa-edit"></i>
                      </Link>
                      <small className="d-block">{t("Edit")}</small>
                    </div>
                    <div className="text-center mx-5">
                      <button
                        className="waves-effect waves-circle btn btn-circle btn-primary-light btn-xs mb-5"
                        onClick={(e) => promptForDelete(value.id)}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                      <small className="d-block">{t("Delete")}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      {/* Modal */}
      <div className="modal center-modal fade" id="modal-center" tabindex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t("Details de l'article")}</h5>
              <button type="button" className="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="box box-body b-1 text-center no-shadow">
                    <img
                      src={
                        displayedMenu && displayedMenu.image
                          ? process.env.REACT_APP_API_BASE_URL +
                            displayedMenu.image
                          : "../images/products/no-image.png"
                      }
                      id="product-image"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="col-md-8 col-sm-6">
                  <h2 className="box-title mt-0">
                    {displayedMenu && displayedMenu.titre.fr}
                  </h2>

                  <h1 className="pro-price mb-0 mt-20">
                    {displayedMenu && displayedMenu.prix} {currency}
                    {/* <span className="old-price">&#36;540</span>
                    <span className="text-danger">50% off</span> */}
                  </h1>
                  <p>{displayedMenu && displayedMenu.description}</p>
                  {/* <div className="row">
                    <div className="col-sm-12">
                      <h6 className="mt-20">Ingredients</h6>
                      <p className="mb-0">
                        {ingredients &&
                          ingredients.map((value, index) => (
                            <span className="badge badge-pill badge-lg badge-default">
                              hi
                            </span>
                          ))}
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="modal-footer modal-footer-uniform">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {t("Close")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* /.modal */}
    </section>
  );
}
export default CategoryMenus;
