import React, { useEffect } from "react";
import { useState } from "react";
import { getPopup } from "../Services/PopupApi";
import { useOrganisations } from "../Auth/useOrganisations";

function HeinekenAdPopup() {
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  let popup = null;
  useEffect(() => {
    getPopup(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        popup = res.data.data;
        // Create pop-up ad element
        const heinekenAd = document.createElement("div");
        heinekenAd.innerHTML = `
          <div style="position: relative; display: inline-block;">
            <a href="https://www.heineken.com/tn/fr/home"><img src="${
              process.env.REACT_APP_API_BASE_URL + popup.adimage
            }"></a>
            <button class="waves-effect waves-circle btn btn-circle btn-primary-light btn-xs mb-5" style="position: absolute; top: 5px; right: 5px; font-size: 20px; cursor: pointer;">X</button>
          </div>
        `;

        // Set styles for pop-up ad
        heinekenAd.style.position = "fixed";
        heinekenAd.style.top = "50%";
        heinekenAd.style.left = "50%";
        heinekenAd.style.transform = "translate(-50%, -50%)";
        heinekenAd.style.zIndex = "9999";
        heinekenAd.style.backgroundColor = "white";
        heinekenAd.style.width = "300px";

        // Add pop-up ad to the page
        document.body.appendChild(heinekenAd);

        // Add click event listener to close button and body for close button click
        const closeButton = heinekenAd.querySelector(".close-button");
        if (closeButton) {
          closeButton.addEventListener("click", () => {
            document.body.removeChild(heinekenAd);
          });
        }

        document.body.addEventListener("click", (event) => {
          if (event.target.classList.contains("waves-effect")) {
            document.body.removeChild(heinekenAd);
          }
        });
      }
    });
  }, []);

  return <div>{/* Your ReactJS component JSX code goes here */}</div>;
}

export default HeinekenAdPopup;
