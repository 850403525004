import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useUser } from "../Auth/useUser";
import {
  editOrganisation,
  getOrganisationById,
} from "../Services/OrganizationApi";
import { useOrganisations } from "./../Auth/useOrganisations";
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GifLoader from "../Components/loader";
import { useTranslation } from "react-i18next";

function EditOrganisation() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [org, setOrg] = useState();
  const [nom, setNom] = useState();
  const [numero, setNumero] = useState();
  const [tables, setTables] = useState();
  const [description, setDescription] = useState();
  const [logo, setLogo] = useState();
  const [menu, setMenu] = useState();
  const [wifi, setWifi] = useState();
  const [currency, setCurrency] = useState();
  const [color, setColor] = useState();
  const [message, setMessage] = useState();
  const user = useUser();
  const [infoUser] = useState(user.result);
  const paymentMethods = ["Cash", "Credit Card", "Tickets Restau", "Chéque"];
  const [checked, setChecked] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  const promptForDelete = () => {
    swal({
      title: "Pouf ! Votre organisation a été mise à jour",
      icon: "success",
    }).then(function () {
      window.location = "/";
    });
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    // Validate nom
    if (!nom) {
      toast.error("Veuillez entrer un nom pour votre entreprise!!");
      return false;
    }

    // Validate numero
    const numeroRegex = /^\d{8}$/;
    if (!numeroRegex.test(numero)) {
      toast.error(
        "Veuillez entrer un numéro de téléphone valide avec exactement 8 chiffres!!"
      );
      return false;
    }
    // Validate currency
    if (!currency) {
      toast.error("Veuillez sélectionner une devise pour votre entreprise!!");
      return false;
    }
    editOrganisation(
      nom,
      numero,
      description,
      tables,
      wifi,
      logo,
      color,
      menu,
      currency,
      message,
      checked,
      usedOrganisations
    ).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        promptForDelete();
      }
    });
    return false;
  };
  useEffect(() => {
    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
        setNom(res.data.data.nom);
        setNumero(res.data.data.numero);
        setTables(res.data.data.tables);
        setDescription(res.data.data.description);
        setWifi(res.data.data.wifi);
        setMenu(res.data.data.menu);
        setColor(res.data.data.color);
        setCurrency(res.data.data.currency);
        setMessage(res.data.data.message);
        setChecked(
          res.data.data.paiementmode ? res.data.data.paiementmode : []
        );
        setLoading(false);
      }
    });
  }, []);
  return (
    <section className="content">
      <GifLoader loading={loading} />
      <div className="row">
        <div className="col-12">
          <div className="box" style={{ padding: "15px" }}>
            <div className="box-body">
              <form onSubmit={onSubmit}>
                <div className="form-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="col-md-6">
                        <h4 className="box-title mt-20">Logo</h4>
                        <div className="product-img text-left">
                          <img
                            src={
                              logo
                                ? URL.createObjectURL(logo)
                                : org && org.logo
                                ? process.env.REACT_APP_API_BASE_URL + org.logo
                                : "/../images/product/product-9.png"
                            }
                            alt=""
                            className="mb-15"
                          />
                          <p>{t("Changer l'image")}</p>
                          <div className="btn btn-info mb-20 choose-file">
                            <input
                              type="file"
                              name="logo"
                              className="upload"
                              onChange={(e) =>
                                setLogo(e.currentTarget.files[0])
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t("Description")}
                        </label>
                        <textarea
                          className="form-control p-20"
                          rows="4"
                          defaultValue={org ? org.description : ""}
                          onChange={(e) =>
                            setDescription(e.currentTarget.value)
                          }
                        ></textarea>
                      </div>
                      {org && org.type === "Restaurant" ? (
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-solid fa-comment"></i>
                            </div>
                            <input
                              type="text"
                              placeholder="Un message pour vos clients"
                              className="form-control"
                              defaultValue={org ? org.message : ""}
                              onChange={(e) =>
                                setMessage(e.currentTarget.value)
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend ">
                            <div className="input-group-addon">
                              <i className="fa fa-solid fa-user"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            className="form-control pl-15 bg-transparent"
                            placeholder="Nom"
                            defaultValue={org ? org.nom : ""}
                            onChange={(e) => setNom(e.currentTarget.value)}
                          />
                        </div>
                      </div>
                      {/* <div className="form-group">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend ">
                            <div className="input-group-addon">
                              <i className="fa fa-solid fa-user"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            className="form-control pl-15 bg-transparent"
                            placeholder="Devise"
                            defaultValue={org ? org.currency : ""}
                            onChange={(e) => setCurrency(e.currentTarget.value)}
                          />
                        </div>
                      </div> */}
                      {/* <div className="form-group">
                        <label htmlFor="currency">Currency</label>
                        <select
                          id="currency"
                          className="form-control"
                          defaultValue={org ? org.currency : ""}
                          onChange={(e) => setCurrency(e.currentTarget.value)}
                        >
                          <option value="">Select a currency</option>
                          <option value="TND">TND</option>
                          <option value="Dinar libyen">Dinar libyen</option>
                          <option value="CFA">CFA</option>
                          <option value="£">£</option>
                          <option value="$">$</option>
                        </select>
                      </div> */}
                      {org && org.type !== "Rating" ? (
                        <div className="form-group">
                          <label className="font-weight-700 font-size-16">
                            {t("Devise")}
                          </label>
                          <select
                            defaultValue={org ? org.currency : ""}
                            onChange={(e) => setCurrency(e.currentTarget.value)}
                            className="form-control"
                            tabindex="1"
                          >
                            <option
                              value="TND"
                              selected={org && org.currency === "TND"}
                            >
                              TND
                            </option>
                            <option
                              value="Dinar libyen"
                              selected={org && org.currency === "Dinar libyen"}
                            >
                              Dinar libyen
                            </option>
                            <option
                              value="CFA"
                              selected={org && org.currency === "CFA"}
                            >
                              CFA
                            </option>
                            <option
                              value="€"
                              selected={org && org.currency === "€"}
                            >
                              € Euro
                            </option>
                            <option
                              value="$"
                              selected={org && org.currency === "$"}
                            >
                              $ Dollar
                            </option>
                          </select>
                        </div>
                      ) : null}

                      {org && org.type === "Showroom" ? (
                        <div className="form-group">
                          <label className="col-form-label col-md-2">
                            {t("Thème")}
                          </label>
                          <div className="col-md-10">
                            <input
                              type="color"
                              name="color-choice"
                              id="color-choice"
                              list="color-options"
                              onChange={(e) => setColor(e.currentTarget.value)}
                              defaultValue={color}
                            />

                            <datalist id="color-options">
                              <option>#660000</option>
                              <option>#ffffff</option>
                              <option>#ffd265</option>
                              <option>#1a2734</option>
                              <option>#0a7b83</option>
                              <option>#383c3d</option>
                            </datalist>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {org && org.type === "Restaurant" ? (
                        <>
                          <div className="form-group">
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-spoon"></i>
                              </div>
                              <input
                                type="number"
                                className="form-control"
                                min={1}
                                defaultValue={org ? org.tables : ""}
                                onChange={(e) =>
                                  setTables(e.currentTarget.value)
                                }
                              />
                            </div>
                          </div>
                          <List sx={{ width: "100%", maxWidth: 360 }}>
                            {paymentMethods.map((value) => {
                              const labelId = `checkbox-list-label-${value}`;
                              return (
                                <ListItem key={value} disablePadding>
                                  <ListItemButton
                                    role={undefined}
                                    onClick={handleToggle(value)}
                                    dense
                                  >
                                    <ListItemIcon>
                                      <Checkbox
                                        edge="start"
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      id={labelId}
                                      primary={value}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              );
                            })}
                          </List>
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="form-group">
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="fa fa-phone"></i>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="99 99 99 99"
                            defaultValue={org ? org.numero : ""}
                            onChange={(e) => setNumero(e.currentTarget.value)}
                          />
                        </div>
                      </div>
                      {org && org.type === "Restaurant" ? (
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-solid fa-wifi"></i>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={org ? org.wifi : ""}
                              onChange={(e) => setWifi(e.currentTarget.value)}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-md-12"></div>
                    </div>
                    {/* choix menu */}

                    {org && org.type === "Restaurant" ? (
                      <div className="row">
                        <div
                          className="row"
                          style={{
                            margin: "0px 0px 16px 19px",
                            fontSize: "24px",
                            color: "white",
                          }}
                        >
                          {" "}
                          <h4 className="box-title mt-20">{t("Choisir un menu")}</h4>
                        </div>
                        <div className="row" style={{ marginLeft: "16px" }}>
                          <div
                            className="col-md-12 col-lg-4"
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="box"
                              style={{
                                background: menu === 1 ? "gray" : "",
                              }}
                              onClick={(e) => setMenu(1)}
                            >
                              <div className="box-body p-5">
                                <img
                                  src="../images/auth-bg/menu1.png"
                                  alt="welcome email page"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-md-12 col-lg-4"
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="box"
                              style={{
                                background: menu === 2 ? "gray" : "",
                              }}
                              onClick={(e) => setMenu(2)}
                            >
                              <div className="box-body p-5">
                                <img
                                  src="../images/auth-bg/menu2.png"
                                  alt="Verify Emial page"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-md-12 col-lg-4"
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="box"
                              style={{
                                background: menu === 5 ? "gray" : "",
                              }}
                              onClick={(e) => setMenu(5)}
                            >
                              <div className="box-body p-5">
                                <img
                                  src="../images/auth-bg/menu5.png"
                                  alt="Verify Emial page"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                {org && org.type === "Restaurant" ? (
                  <div className="row">
                    <div className="col-md-12">
                      <Link
                        to={"/personalisationmenu"}
                        style={{ width: "100%" }}
                        className="waves-effect waves-light btn btn-rounded btn-warning mb-20"
                      >
                        {t("Personaliser votre menu")}
                      </Link>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="form-actions mt-10">
                  <Link to="/categories">
                    <button type="button" className="btn btn-primary">
                      {t("Annuler")}
                    </button>
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-danger left-button"
                    // onClick={(e) => promptForDelete()}
                  >
                    {" "}
                    <i className="fa fa-check"></i> {t("Enregistrer")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default EditOrganisation;
