import axios from "axios";
import { getSession } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API;


export async function getPaymentData(organisation_id, plan_id, amount) {
    const frmData = new URLSearchParams();;
    frmData.append("organisation_id", organisation_id);
    frmData.append("plan_id", plan_id);
    frmData.append("amount", amount);
    const httpHeaders = {
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        },
      };
    
    try {
      const response = await axios.post(api_url + "flouci/payment/",frmData,httpHeaders);
      return response;
    } catch (error) {
      console.error(error);
    }
}

export async function verifyPayment(transactionId) {
    try {
      const response = await axios.get(api_url + "flouci/payment/"+ transactionId);
      return response;
    } catch (error) {
      console.error(error);
    }
}