import axios from "axios";
import { getSession } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API;

/* Setting the header for the axios request. */
const config = {
  headers: { Authorization: `Bearer ${getSession("token")}` },
};

//to add in every api
const currentOrg = getSession("usedorganisations");

export async function addHappyHour(
  organisation_id,
  menu_id,
  start_date,
  end_date,
  start_time,
  end_time,
  reduction
) {
  const body = {
    organisation_id: organisation_id,
    menu_id: menu_id,
    start_date: start_date,
    end_date: end_date,
    start_time: start_time,
    end_time: end_time,
    reduction: reduction,
  };
  const httpHeaders = {
    headers: {
      Authorization: `Bearer ${getSession("token")}`,
    },
  };

  try {
    const response = await axios.post(api_url + "happyhour", body, httpHeaders);
    return response;
  } catch (error) {
    console.error(error);
  }
}

// export async function editDemande(id) {
//   const body = {};
//   const httpHeaders = {
//     Authorization: `Bearer ${getSession("token")}`,
//     "Content-Type": "application/json",
//   };

//   try {
//     const response = await axios.patch(
//       api_url + "demande/" + id,
//       body,
//       httpHeaders
//     );
//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// }

export async function getHappyHours(id) {
  if (id == null) {
    id = currentOrg;
  }
  try {
    const response = await axios.get(api_url + "happyhour/organisation/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getActiveHappyHours(id) {
  if (id == null) {
    id = currentOrg;
  }
  try {
    const response = await axios.get(api_url + "happyhour/active/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

// export async function getTablesDemandes(id , table) {
//   if (id == null) {
//     id = currentOrg;
//   }
//   try {
//     const response = await axios.get(api_url + "demande/" + id +'/' + table, config);
//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// }
