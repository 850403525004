import React, { useEffect, useState } from "react";
import TemplateCleaner from "./TemplateCleaner";
import { Link } from "react-router-dom";

function NewMenuHeader({ withLocation, title, backLink, ...props }) {
  return (
    <>
      {withLocation ? (
        <div className="osahan-header-nav px-3 pt-3 d-flex align-items-center bg-danger">
          <h5 className="font-weight-normal mb-0 text-white">
            <button
              type="button"
              className="btn bg-danger text-white border-0 p-0"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              <span className="text-white h4 m-0">
                <i className="fa-solid fa-location-dot"></i>&nbsp;
                <span>Set Location</span>&nbsp;
                <i className="fa-solid fa-chevron-down"></i>
              </span>
            </button>
          </h5>
          <div className="ms-auto gap-3 d-flex align-items-center">
            <Link to="notifications.html" className="text-white">
              <i className="bi bi-bell d-flex m-0 h2"></i>
            </Link>
            <a className="toggle osahan-toggle text-white m-0" href="#">
              <i className="bi bi-list d-flex m-0 h1"></i>
            </a>
          </div>
          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
              <div className="modal-content border-0">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Add Your Location
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="input-group border rounded-3 mb-3 shadow-sm">
                      <span className="input-group-text bg-white border-0">
                        <i className="bi bi-search"></i>
                      </span>
                      <input
                        type="text"
                        className="shadow-none form-control bg-white border-0 ps-0"
                        placeholder="bang"
                      />
                    </div>
                  </form>
                  <div
                    data-bs-dismiss="modal"
                    className="d-flex align-items-center gap-3 mb-3 px-0"
                  >
                    <div>
                      <i className="fa-solid fa-location-crosshairs text-danger"></i>
                    </div>
                    <div>Use Current Location</div>
                  </div>
                  <div className="text-muted text-uppercase small mb-1">
                    Search Results
                  </div>
                  <div
                    data-bs-dismiss="modal"
                    className="d-flex align-items-center gap-3 border-bottom py-3"
                  >
                    <span>
                      <i className="bi bi-search h6"></i>
                    </span>
                    <div className="lh-sm">
                      <h6 className="mb-1">Bangalore</h6>
                      <p className="text-muted m-0">Karnataka, India</p>
                    </div>
                  </div>
                  <div
                    data-bs-dismiss="modal"
                    className="d-flex align-items-center gap-3 border-bottom py-3"
                  >
                    <span>
                      <i className="bi bi-search h6"></i>
                    </span>
                    <div className="lh-sm">
                      <h6 className="mb-1">Bangalore internaltional airport</h6>
                      <p className="text-muted m-0">
                        Karmpegowda.in't Airport, Hunachur, karnataka, India
                      </p>
                    </div>
                  </div>
                  <div
                    data-bs-dismiss="modal"
                    className="d-flex align-items-center gap-3 border-bottom py-3"
                  >
                    <span>
                      <i className="bi bi-search h6"></i>
                    </span>
                    <div className="lh-sm">
                      <h6 className="mb-1">Railway Station back gate</h6>
                      <p className="text-muted m-0">
                        M.G. Railway Colony, Majestic, Bangaluru
                      </p>
                    </div>
                  </div>
                  <div
                    data-bs-dismiss="modal"
                    className="d-flex align-items-center gap-3 pt-3"
                  >
                    <span>
                      <i className="bi bi-search h6"></i>
                    </span>
                    <div className="lh-sm">
                      <h6 className="mb-1">Bangalore Cant</h6>
                      <p className="text-muted m-0">
                        Cantonent Railway Station Road, Contonment Railway
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white shadow-sm p-3">
          <div className="d-flex align-items-center">
            <div className="gap-3 d-flex align-items-center">
              <Link to={backLink}>
                <i className="bi bi-arrow-left d-flex text-danger h2 m-0 back-page"></i>
              </Link>
              <h3 className="fw-bold m-0">{title}</h3>
            </div>
            <div className="ms-auto gap-3 d-flex align-items-center">
              <button
                type="button"
                className="btn btn-white border btn-sm rounded-md text-muted"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
              >
                <span>
                  <i className="fa-solid fa-tag"></i>
                </span>
                &nbsp;Coupon
              </button>
              <a className="toggle osahan-toggle text-dark m-0" href="#">
                <i className="bi bi-list d-flex m-0 h1"></i>
              </a>
            </div>
          </div>
          <div
            className="modal fade"
            id="exampleModal2"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content border-0">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Coupons
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body p-0">
                  <div className="p-3">
                    <div>
                      <div className="mb-2">
                        <div className="mb-3">
                          <img
                            src="/newmenu/img/p1.png"
                            alt=""
                            className="img-fluid ch-40 border rounded-2"
                          />
                        </div>
                        <div className="h4 m-0">Get 50% OFF up to $100</div>
                      </div>
                      <p className="text-muted">
                        Valid on total value of items worth $159 or more.&nbsp;
                        <span className="text-dark">View Details</span>
                      </p>
                      <div className="d-flex justify-content-between pb-3 align-items-center">
                        <div className="text-uppercase fw-bold border border-danger text-danger rounded px-2 py-1 h5 m-0">
                          maxsafety
                        </div>
                        <div>
                          <a
                            href="#"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="text-decoration-none text-danger"
                          >
                            Apply
                          </a>
                        </div>
                      </div>
                      <div className="text-primary">
                        You will save $100 with this code
                      </div>
                    </div>
                    <hr />
                    <div>
                      <div className="mb-2">
                        <div className="mb-3">
                          <img
                            src="/newmenu/img/p1.png"
                            alt=""
                            className="img-fluid ch-40 border rounded-2"
                          />
                        </div>
                        <div className="h4 m-0">
                          50% OFF up to $100 and $30 Paytm cashback using Paytm
                        </div>
                      </div>
                      <p className="text-muted">
                        Valid on total value of items worth $159 or more.&nbsp;
                        <span className="text-dark">View Details</span>
                      </p>
                      <div className="d-flex justify-content-between pb-3 align-items-center">
                        <div className="text-uppercase fw-bold border border-danger text-danger rounded px-2 py-1 h5 m-0">
                          zompaytm
                        </div>
                        <div>
                          <a
                            href="#"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="text-decoration-none text-danger"
                          >
                            Apply
                          </a>
                        </div>
                      </div>
                      <div className="text-primary">
                        You will save $100 &amp; get extra cashback with this
                        code
                      </div>
                    </div>
                    <hr />
                    <div>
                      <div className="mb-2">
                        <div className="mb-3">
                          <img
                            src="/newmenu/img/p2.png"
                            alt=""
                            className="img-fluid ch-40 border rounded-2"
                          />
                        </div>
                        <div className="h4 m-0">Get 60% OFF up to $140</div>
                      </div>
                      <p className="text-muted">
                        Valid on total value of items worth $159 or more.&nbsp;
                        <span className="text-dark">View Details</span>
                      </p>
                      <div className="d-flex justify-content-between pb-3 align-items-center">
                        <div className="text-uppercase fw-bold border border-danger text-danger rounded px-2 py-1 h5 m-0">
                          simplparty
                        </div>
                        <div>
                          <a
                            href="#"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="text-decoration-none text-danger"
                          >
                            Apply
                          </a>
                        </div>
                      </div>
                      <div className="text-primary">
                        You will save $140 with this code
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <nav id="main-nav">
        <ul className="second-nav">
          <li>
            <Link
              to="edit-profile.html"
              className="bg-danger sidebar-user d-flex align-items-center p-3 border-0 mb-0 rounded-4"
            >
              <img
                src="/newmenu/img/user.jpeg"
                className="img-fluid rounded-pill me-3 ch-50"
                alt="#"
              />
              <div className="text-white">
                <h6 className="mb-0">Hi Your Name</h6>
                <small>+91 12345-67890</small>
                <br />
                <span className="f-10 text-white-50">Version 1.32</span>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/newmenu/list">
              <i className="bi bi-house me-2"></i> Homepage
            </Link>
          </li>
          <li>
            <Link to="enable-location.html">
              <i className="bi bi-geo-alt me-2"></i> Enable Location
            </Link>
          </li>
          <li>
            <Link to="location.html">
              <i className="bi bi-geo me-2"></i> Add Location
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="bi bi-key me-2"></i> Authentication
            </Link>
            <ul>
              <li>
                <Link to="signup.html">Sign In/Up</Link>
              </li>
              <li>
                <Link to="otp-verify.html">Verification</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="coupons.html">
              <i className="bi bi-ticket-detailed me-2"></i> Coupons
            </Link>
          </li>
          <li>
            <Link to="offers.html">
              <i className="bi bi-percent me-2"></i> Offers
            </Link>
          </li>
          <li>
            <Link to="wallet.html">
              <i className="bi bi-wallet me-2"></i> Wallet
            </Link>
          </li>
          <li>
            <Link to="notification-setting.html">
              <i className="bi bi-bell-slash me-2"></i> Notification Setting
            </Link>
          </li>
          <li>
            <Link to="notifications.html">
              <i className="bi bi-bell me-2"></i> Notifications
            </Link>
          </li>
          <li>
            <Link to="setting.html">
              <i className="bi bi-gear me-2"></i> Setting
            </Link>
          </li>
          <li>
            <Link to="/newmenu/list">
              <i className="bi bi-search me-2"></i> Search List
            </Link>
          </li>
          <li>
            <Link to="store.html">
              <i className="bi bi-shop me-2"></i> Store
            </Link>
          </li>
          <li>
            <Link to="cart.html">
              <i className="bi bi-bag me-2"></i> Cart
            </Link>
          </li>
          <li>
            <Link to="checkout.html">
              <i className="bi bi-cart me-2"></i> Checkout
            </Link>
          </li>
          <li>
            <Link to="payment.html">
              <i className="bi bi-credit-card me-2"></i> Payment
            </Link>
          </li>
          <li>
            <Link to="add-card.html">
              <i className="bi bi-credit-card-2-front me-2"></i> Add Card
            </Link>
          </li>
          <li>
            <Link to="personal-info.html">
              <i className="bi bi-person me-2"></i> Personal Info
            </Link>
          </li>
          <li>
            <Link to="edit-profile.html">
              <i className="bi bi-pencil me-2"></i> Edit Profile
            </Link>
          </li>
          <li>
            <Link to="my-address.html">
              <i className="bi bi-map me-2"></i> My Address
            </Link>
          </li>
          <li>
            <Link to="your-order.html">
              <i className="bi bi-view-list me-2"></i> Your Order
            </Link>
          </li>
          <li>
            <Link to="order-confirm.html">
              <i className="bi bi-bag-check me-2"></i> Order Confirm
            </Link>
          </li>
          <li>
            <Link to="order-details.html">
              <i className="bi bi-list-nested me-2"></i> Order Details
            </Link>
          </li>
          <li>
            <Link to="support.html">
              <i className="bi bi-question-circle me-2"></i> Support
            </Link>
          </li>
        </ul>
        <ul className="bottom-nav">
          <li className="email">
            <Link
              className="text-danger nav-item text-center"
              to="/newmenu/list"
              tabindex="0"
            >
              <p className="h5 m-0">
                <i className="icofont-ui-home text-danger"></i>
              </p>
              Home
            </Link>
          </li>
          <li className="github">
            <Link
              to="offers.html"
              className="nav-item text-center"
              tabindex="0"
            >
              <p className="h5 m-0">
                <i className="icofont-sale-discount"></i>
              </p>
              Offer
            </Link>
          </li>
          <li className="ko-fi">
            <Link
              to="support.html"
              className="nav-item text-center"
              tabindex="0"
            >
              <p className="h5 m-0">
                <i className="icofont-support-faq"></i>
              </p>
              Help
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default NewMenuHeader;
