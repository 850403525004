import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect } from "react";
import { useState } from "react";
import { getPopup } from "../Services/PopupApi";
import { useTranslation } from "react-i18next";

export default function BuyProductPopUp({
  product,
  category,
  suggestionMessage,
  cartProducts,
  confirm,
  decline,
  toggleDrawer,
  orgId,
  ...props
}) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [suggestedProduct, setSuggestedProduct] = useState();
  const [suggestedCategory, setSuggestedCategory] = useState();
  const [suggestion, setSuggestion] = useState();
  const [added, setAdded] = useState(false);

  useEffect(() => {
    getPopup(orgId).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setSuggestedProduct(res.data.data.menu_id);
        setSuggestedCategory(res.data.data.category_id);
        setSuggestion(res.data.data.suggestion);
        console.log(res.data.data.menu_id);
        console.log(res.data.data.category_id);
      }
    });
  }, []);

  function isProductExist() {
    let suggestedProductExists = false;

    for (let i = 0; i < cartProducts.length; i++) {
      if (cartProducts[i].data[0].id === suggestedProduct._id) {
        suggestedProductExists = true;
        break;
      }
    }

    return suggestedProductExists;
  }
  function isCategoryExist() {
    let suggestedCategoryExists = false;

    for (let i = 0; i < cartProducts.length; i++) {
      if (cartProducts[i].data[0].category_id._id === suggestedCategory._id) {
        suggestedCategoryExists = true;
        break;
      }
    }

    return suggestedCategoryExists;
  }

  const handleClickOpenProduct = () => {
    if (added || isProductExist()) {
      decline();
    } else {
      setOpen(true);
    }
  };
  const handleClickOpenCatgory = () => {
    console.log(isCategoryExist());
    if (isCategoryExist() === true) {
      decline();
    } else {
      setOpen(true);
    }
  };
  const handleClickOpenMessage = () => {
    setOpen(true);
  };

  const handleClose = () => {
    decline();
    setOpen(false);
  };
  const handleBuyProduct = () => {
    setAdded(true);
    confirm();
    setOpen(false);
    toggleDrawer(false);
  };

  const handleBuyCategory = () => {
    setAdded(true);
    setOpen(false);
    toggleDrawer(false);
  };

  const handleSubmitSuggestion = () => {
    setOpen(false);
    toggleDrawer(false);
  };

  return (
    <>
      {suggestedProduct && suggestedProduct ? (
        <div>
          <button
            className="waves-effect waves-light btn btn-rounded btn-warning mb-5"
            style={{ width: "100%" }}
            onClick={handleClickOpenProduct}
          >
            {t("confirmer")}
          </button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogTitle
            id="alert-dialog-title"
            style={{ color: "black", width: "80vw", textAlign: "center" }}
          >
            {"Buy "}
            {product && product.titre.fr}
            {"?"}
          </DialogTitle> */}
            <DialogContent sx={{ display: "flex" }}>
              <div
                className="avatar avatar-lg"
                style={{ width: "100px", height: "100px" }}
              >
                <img
                  className="rounded img-fluid"
                  src={
                    product && product.image
                      ? process.env.REACT_APP_API_BASE_URL + product.image
                      : "../images/product/no-image.png"
                  }
                />
              </div>

              <DialogContentText
                id="alert-dialog-description"
                sx={{ width: "100%" }}
              >
                <p className="text-fade w-p85 mx-auto">
                  Voulez-vous commandez {product && product.titre.fr} ?
                </p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Non, merci</Button>
              <Button onClick={handleBuyProduct} autoFocus>
                Oui
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <></>
      )}
      {suggestedCategory && suggestedCategory ? (
        <div>
          <button
            className="waves-effect waves-light btn btn-rounded btn-warning mb-5"
            style={{ width: "100%" }}
            onClick={handleClickOpenCatgory}
          >
            {t("confirmer")}
          </button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent sx={{ display: "flex" }}>
              <div
                className="avatar avatar-lg"
                style={{ width: "100px", height: "100px" }}
              >
                <img
                  className="rounded img-fluid"
                  src={
                    category && category.image
                      ? process.env.REACT_APP_API_BASE_URL + category.image
                      : "../images/product/no-image.png"
                  }
                />
              </div>
              <DialogContentText
                id="alert-dialog-description"
                sx={{ width: "100%" }}
              >
                <p className="text-fade w-p85 mx-auto">
                  Voulez-vous commandez du {category && category.libelle} ?
                </p>
                {/* <button
                  onClick={(e) => console.log(isCategoryExist())}
                ></button> */}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Non, merci</Button>
              <Button onClick={handleBuyCategory} autoFocus>
                Oui
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}

      {suggestion && suggestion ? (
        <div>
          <button
            className="waves-effect waves-light btn btn-rounded btn-warning mb-5"
            style={{ width: "100%" }}
            onClick={handleClickOpenMessage}
          >
            {t("confirmer")}
          </button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent sx={{ display: "flex" }}>
              <DialogContentText
                id="alert-dialog-description"
                sx={{ width: "100%" }}
              >
                <p className="text-fade w-p85 mx-auto"> {suggestionMessage} </p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Non, merci</Button>
              <Button onClick={handleSubmitSuggestion} autoFocus>
                Oui
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
      {!suggestion && !suggestedCategory && !suggestedProduct ? (
        <div>
          <button
            className="waves-effect waves-light btn btn-rounded btn-warning mb-5"
            style={{ width: "100%" }}
            onClick={(e) => decline()}
          >
            {t("confirmer")}
          </button>
        </div>
      ) : null}
    </>
  );
}
