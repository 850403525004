import React, { useEffect, useRef, useState } from "react";
import TemplateCleaner from "./TemplateCleaner";
import { Link, useParams } from "react-router-dom";
import NewMenuHeader from "./NewMenuHeader";
import { getOrganisationById } from "../../Services/OrganizationApi";
import { getPersonalisationMenu } from "../../Services/TemplateMenuApi";
import { getSocials } from "../../Services/SocialsApi";
import { getComments } from "../../Services/CommentsApi";
import { getCategories } from "../../Services/CategrieApi";
import NewMenuFooter from "./NewMenuFooter";
import GifLoader from "../../Components/loader";
import { getActiveHappyHours } from "../../Services/HappyHourApi";
import fr from "../MenusStyle/france.png";
import eng from "../MenusStyle/england.png";
import ar from "../MenusStyle/tun.png";
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import Facebook from "../MenusStyle/facebook.png";
import Instagram from "../MenusStyle/instagram.png";
import Youtube from "../MenusStyle/youtube.png";
import Website from "../MenusStyle/internet.png";
import { getAllIngredients } from "../../Services/IngredientsApi";
import Ingredients from "./../../Containers/Ingredient";
import { ToastContainer } from "react-toastify";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // A function that increment ðŸ‘†ðŸ» the previous state like here
  // is better than directly setting setValue(value + 1)
}

function Store() {
  const { id, table, idcategory } = useParams();
  const [org, setOrg] = useState();
  const [filter, setFilter] = useState("");
  const [categories, setcategories] = useState([]);
  const [categoriesMenus, setcategoriesMenus] = useState([]);
  const [currentProduct, setCurrentProduct] = useState();
  const [currency, setCurrency] = useState();
  const [color, setColor] = useState();
  const [rating, setRating] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [reviewsCount, setReviewsCount] = useState([]);
  const [background, setBackground] = useState();
  const [socials, setSocials] = useState();
  const [template, setTemplate] = useState();
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [supplementsPrice, setSupplementsPrice] = useState(0);
  const [ingredients, setIngredients] = useState([]);
  const [currentProductIngredients, setCurrentProductIngredients] = useState(
    []
  );
  const [chiffreApresVirg, setChiffreApresVirg] = useState();
  const [checkedValues, setCheckedValues] = useState([]);
  const [layout, setLayout] = useState("unset");
  const handleCheckboxChange = (event, value) => {
    const { checked } = event.target;

    if (checked) {
      // If the checkbox is checked, add the ingredient object to the checkedValues array
      setCheckedValues((prevValues) => [...prevValues, value]);
    } else {
      // If the checkbox is unchecked, remove the ingredient object from the checkedValues array
      setCheckedValues((prevValues) =>
        prevValues.filter((item) => item !== value)
      );
    }
  };

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(
    localStorage.getItem("selectedLanguage") || "fr"
  );

  // Define a function to change the language
  const handleLanguageChange = (selectedLanguage) => {
    setLang(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);

    // Use the changeLanguage function from i18next
    changeLanguage(selectedLanguage);

    // You can also trigger any language-specific layout changes here if needed.
  };

  const translation = (titre) => {
    if (lang === "fr") {
      return titre.fr;
    } else if (lang === "en") {
      return titre.en;
    } else return titre.ar;
  };

  const currencyTranslation = (currency) => {
    if (lang === "fr" && currency === "TND") {
      return "TND";
    } else if (lang === "en" && currency === "TND") {
      return "TND";
    } else return " دت";
  };

  // Initialize your cart state with the saved data or an empty cart
  const [cart, setCart] = useState({
    produits: [],
    table: atob(table),
    prix: 0,
    status: "Nouveau",
  });

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      setCart(JSON.parse(localStorage.getItem("cart")));
    } else {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, []);

  const [cartProducts, setCartProducts] = useState();
  const [happyHours, setHappyHours] = useState([]);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    setTimeout(() => {
      var onPageSetupDoneEvent = new Event("onPageSetupDone");
      document.dispatchEvent(onPageSetupDoneEvent);
    }, 2000);
    changeLanguage(localStorage.getItem("selectedLanguage"));
    if (localStorage.getItem("selectedLanguage") === "ar") {
      setLayout("rtl");
    }

    getOrganisationById(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
        setCurrency(res.data.data.currency);
        if (res.data.data.currency === "TND") {
          setChiffreApresVirg(3);
        } else if (
          res.data.data.currency === "Euro" ||
          res.data.data.currency === "Dollar"
        ) {
          setChiffreApresVirg(2);
        }
      }
    });
    getActiveHappyHours(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setHappyHours(res.data.data);
      }
    });
    getPersonalisationMenu(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setTemplate(res.data.data);
        setBackground(res.data.data[0].background);
      }
    });
    getSocials(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setSocials(res.data.data);
      }
    });
    getAllIngredients(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setIngredients(res.data.data);
      }
    });
    getComments(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setReviews(res.data.data);
        setReviewsCount(res.data.data.length);
        let fiveArray = res.data.data.filter((arr) => arr.star === 5);
        let fourArray = res.data.data.filter((arr) => arr.star === 4);
        let threeArray = res.data.data.filter((arr) => arr.star === 3);
        let twoArray = res.data.data.filter((arr) => arr.star === 2);
        let oneArray = res.data.data.filter((arr) => arr.star === 1);
        let max =
          (fiveArray.length * 5 +
            fourArray.length * 4 +
            threeArray.length * 3 +
            twoArray.length * 2 +
            oneArray.length) /
          res.data.data.length;
        setRating(max.toFixed(1));
      }
    });
    getCategories(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setcategories(res.data.data);
        setLoading(false);
      }
    });
  }, [id]);

  function getPrice(product, happyHours) {
    const happyHourItem = happyHours.find(
      (item) => item.menu_id._id === product._id
    );

    if (happyHourItem) {
      return product.prix - happyHourItem.reduction;
    } else {
      return product.prix;
    }
  }
  function MenuIngredients(product, ingredients) {
    const filteredIngredients = ingredients.filter(
      (ingredient) => ingredient.menu_id._id === product._id
    );
    return filteredIngredients;
  }

  function happyHourTime(product, happyHours) {
    const happyHourItem = happyHours.find(
      (item) => item.menu_id._id === product._id
    );

    return happyHourItem;
  }

  function convertToAMPM(timeString) {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(":");

    // Convert hours to an integer
    const hour = parseInt(hours);

    // Determine whether it's AM or PM
    const period = hour < 12 ? "am" : "pm";

    // Format the time in "hampm" format
    let formattedHours = hour % 12 || 12; // Use 12 for 0 hours
    formattedHours =
      formattedHours < 10 ? `0${formattedHours}` : formattedHours;

    // Create the final formatted time string
    const formattedTime = `${formattedHours}${period}`;

    return formattedTime;
  }

  const addProductToCart = (product, checkedIngredients) => {
    // Create a copy of the cart object
    let tempCart = { ...cart };

    const tempQuantity = quantity; // Use the predefined 'quantity' value
    setQuantity(1);
    if (tempQuantity > 0) {
      for (let i = 0; i < tempQuantity; i++) {
        // Create a new cart product object
        const cartProduct = {
          products: product, // Reference to the product in the "menus" collection
          ingredients: checkedIngredients, // Array of ingredient references
        };

        tempCart.produits.push(cartProduct);

        // You can use reduce to group cart products as before
        const result = tempCart.produits.reduce((acc, curr) => {
          const key = `${curr.product_id}`; // Use the product_id as the key
          const existingItem = acc.find((item) => item.key === key);

          if (existingItem) {
            existingItem.data.push(curr);
          } else {
            acc.push({ key, data: [curr] });
          }

          return acc;
        }, []);

        setCartProducts(result);

        // Calculate the total price based on products and ingredients
        tempCart.prix +=
          getPrice(product, happyHours) +
          checkedIngredients.reduce(
            (total, ingredient) => total + ingredient.prix,
            0
          );

        // Update the cart state
        setCart(tempCart);
        setCheckedValues([]);
        // Get the parent div with id "checklist"
        var checklistDiv = document.getElementById("checklist");

        // Get all the checkboxes inside the "checklist" div
        var checkboxes = checklistDiv.querySelectorAll(
          'input[type="checkbox"]'
        );

        // Loop through the checkboxes and uncheck them
        for (var j = 0; j < checkboxes.length; j++) {
          checkboxes[j].checked = false;
        }

        // Trigger a force update if needed
        forceUpdate();
      }

      // Store the updated cart in local storage
      localStorage.setItem("cart", JSON.stringify(tempCart));
    }
  };

  const firstCategoryButtonRef = useRef();

  // Simulate a click on the first category button when the page loads
  useEffect(() => {
    if (categories.length > 0) {
      firstCategoryButtonRef.current.click();
    }
  }, [categories]);

  return (
    <>
      <GifLoader loading={loading} />
      <TemplateCleaner />
      <div className="bg-white shadow-sm p-3 sticky-top">
        <div className="d-flex align-items-center">
          <div className="gap-3 d-flex align-items-center">
            <h3 className="fw-bold m-0"> {org && org.nom} </h3>
          </div>
          <div className="ms-auto gap-3 d-flex align-items-center">
            <div className="btn-group">
              <button
                className="btn btn-rounded dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                style={{
                  backgroundColor: "white",
                  display: "flex",
                  padding: "0",
                }}
              >
                <div className="btn-text">
                  {lang === "fr" ? (
                    <>
                      <img
                        src={fr}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      Fr
                    </>
                  ) : lang === "en" ? (
                    <>
                      <img
                        src={eng}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      En
                    </>
                  ) : lang === "ar" ? (
                    <>
                      <img
                        src={ar}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      Ar
                    </>
                  ) : null}
                </div>
              </button>
              <div
                className="dropdown-menu dropdown-menu-right"
                style={{ backgroundColor: "white" }}
              >
                <a
                  className="dropdown-item"
                  onClick={() => {
                    handleLanguageChange("fr");
                    setLayout("unset");
                  }}
                >
                  <img
                    src={fr}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  Fr
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    handleLanguageChange("en");
                    setLayout("unset");
                  }}
                >
                  <img
                    src={eng}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  En
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    handleLanguageChange("ar");
                    setLayout("rtl");
                  }}
                >
                  <img
                    src={ar}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  Ar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="p-3"
        style={{
          backgroundImage:
            "url(../../../images/auth-bg/backgroundmenu/bg-" +
            background +
            ".jpg)",
          backgroundPosition: "bottom",
          backgroundSize: "cover",
        }}
      >
        <div
          className="row align-items-start justify-content-between"
          style={{ backgroundColor: "#00000045" }}
        >
          <div className="col-8">
            <h1 className="fw-bold mb-1" style={{ color: "aliceblue" }}>
              {" "}
              {org && org.nom}{" "}
            </h1>
            <div className="fs-6 mb-2" style={{ color: "aliceblue" }}>
              {org && org.description}{" "}
            </div>
            <p className="small text-muted" style={{ color: "aliceblue" }}>
              <nav>
                <Link
                  onClick={(e) => (window.location.href = socials.facebook)}
                  className="waves-effect waves-circle btn btn-circle btn-xs"
                >
                  <img src={Facebook} style={{ width: "20px" }} />
                </Link>
                <Link
                  onClick={(e) => (window.location.href = socials.instagram)}
                  className="waves-effect waves-circle btn btn-circle btn-xs"
                >
                  <img src={Instagram} style={{ width: "20px" }} />{" "}
                </Link>
                <Link
                  onClick={(e) => (window.location.href = socials.youtube)}
                  className="waves-effect waves-circle btn btn-circle btn-xs"
                >
                  <img src={Youtube} style={{ width: "20px" }} />{" "}
                </Link>
                <Link
                  onClick={(e) => (window.location.href = socials.website)}
                  className="waves-effect waves-circle btn btn-circle btn-xs"
                >
                  <img src={Website} style={{ width: "20px" }} />{" "}
                </Link>
              </nav>
            </p>
            {/* <div className="d-flex align-items-center gap-2">
              <div>
                <img
                  src="/newmenu/img/delivery.png"
                  alt=""
                  className="img-fluid ch-20"
                />
              </div>
              <a
                href="support.html"
                className="text-decoration-none text-muted small"
              >
                know more
              </a>
            </div> */}
          </div>
          {rating && rating >= 4 ? (
            <div className="col-3">
              <div className="shadow rounded-3">
                <div className="bg-success rounded-top text-white">
                  <div className="d-flex justify-content-center gap-1 p-1">
                    <div className="h2 m-0"> {rating} </div>
                    <div className="pt-1">
                      <i className="fa-solid fa-star"></i>
                    </div>
                  </div>
                </div>
                <div className="text-center rounded-bottom p-1 bg-white">
                  <div className="mb-0 text-warning fw-bold">
                    {" "}
                    {reviewsCount}{" "}
                  </div>
                  <div className="small text-muted">{t("Avis clients")}</div>
                </div>
              </div>
              {/* <div className="badge bg-light text-dark my-2 p-2 w-100 border text-center">
                <i className="fa-solid fa-stopwatch text-success me-1"></i>
                &nbsp;30 mins
              </div> */}
            </div>
          ) : null}
        </div>
      </div>
      {/* tabs with search bar */}
      <main className="sticky-top" style={{ direction: layout }}>
        <div
          className="bg-light"
          style={{ overflow: "auto", whiteSpace: "nowrap" }}
        >
          <div className="row m-0">
            <div className="col-12 p-0">
              <ul
                className="nav osahan-tab payment-methods-container"
                id="pills-tab"
                role="tablist"
              >
                {categories &&
                  categories.map((value, index) =>
                    value.menus_list.length ? (
                      <li
                        className="nav-item col p-0"
                        role="presentation"
                        key={index}
                      >
                        <button
                          ref={
                            idcategory && idcategory === value._id
                              ? firstCategoryButtonRef
                              : index === 0
                              ? firstCategoryButtonRef
                              : null
                          }
                          className="nav-link bg-light w-100"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                          onClick={(e) => {
                            setcategoriesMenus(value.menus_list);
                          }}
                        >
                          {translation(value.libelle)}
                        </button>
                      </li>
                    ) : null
                  )}
              </ul>
            </div>
          </div>
        </div>
        <div className="bg-white px-3 pt-3">
          <div className="input-group bg-white shadow rounded-md border p-1 mb-3">
            <span className="input-group-text border-0 bg-white">
              <i className="fa-solid fa-magnifying-glass text-danger"></i>
            </span>
            <input
              type="text"
              className="shadow-none form-control border-0"
              placeholder={t("Recherche")}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
          {/* <div className="d-flex align-items-center gap-3">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefaultdd"
              />
              <label
                className="form-check-label"
                for="flexSwitchCheckDefaultdd"
              >
                Veg
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
              />
              <label className="form-check-label" for="flexSwitchCheckDefault">
                Non-Veg
              </label>
            </div>
          </div> */}
        </div>
      </main>
      {/* Contant */}
      <div className="p-3" style={{ direction: layout }}>
        <div className="row">
          <div className="col-12">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div>
                  {categoriesMenus &&
                    categoriesMenus.map((value, index) => {
                      const titre =
                        typeof value.titre === "object"
                          ? value.titre.fr
                          : value.titre;

                      return (
                        <div key={index}>
                          {titre
                            .toLowerCase()
                            .includes(filter.toLowerCase()) ? (
                            <div
                              className="store-list- mb-10"
                              style={{ marginBottom: "10px" }}
                            >
                              <div className="row justify-content-between">
                                <div className="col-6">
                                  {getPrice(value, happyHours) !==
                                  value.prix ? (
                                    <div className="d-flex align-items-center gap-2 mb-3">
                                      <img
                                        src="/newmenu/img/veg.jpeg"
                                        alt=""
                                        className="img-fluid ch-20"
                                      />
                                      <div className="badge border border-warning text-warning">
                                        HAPPY HOUR{" "}
                                        {
                                          happyHourTime(value, happyHours)
                                            .start_time
                                        }{" "}
                                        -{" "}
                                        {
                                          happyHourTime(value, happyHours)
                                            .end_time
                                        }
                                      </div>
                                    </div>
                                  ) : null}

                                  <h3 className="fw-bold mb-1">
                                    {" "}
                                    {translation(value.titre)}{" "}
                                  </h3>
                                  <p
                                    className="text-muted mb-3"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {" "}
                                    {translation(value.description)}
                                  </p>
                                  {getPrice(value, happyHours) !==
                                  value.prix ? (
                                    <div
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <span className="text-danger">
                                        <del> {value.prix} </del>
                                      </span>{" "}
                                      {getPrice(value, happyHours)}{" "}
                                      {currencyTranslation(currency)}
                                    </div>
                                  ) : (
                                    <h6 className="fw-bold">
                                      {" "}
                                      {getPrice(value, happyHours)}{" "}
                                      {currencyTranslation(currency)}{" "}
                                    </h6>
                                  )}
                                </div>
                                <div className="col-4">
                                  <div className="card border-0">
                                    <img
                                      src={
                                        value.image.length
                                          ? process.env.REACT_APP_API_BASE_URL +
                                            value.image
                                          : ""
                                      }
                                      className="card-img-top rounded-3"
                                      alt="..."
                                    />
                                    <div className="card-body d-grid px-0 pt-2 pb-0">
                                      <button
                                        type="button"
                                        className="btn btn-outline-danger fw-bold text-uppercase btn-sm rounded"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        onClick={(e) => {
                                          setCurrentProduct(value);
                                          setCurrentProductIngredients(
                                            MenuIngredients(value, ingredients)
                                          );
                                        }}
                                      >
                                        {t("Ajouter")} +
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr></hr>
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Model */}
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <h5 className="modal-title">
                {" "}
                {currentProduct && currentProduct.titre.fr}{" "}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setQuantity(1);
                  var checklistDiv = document.getElementById("checklist");
                  var checkboxes = checklistDiv.querySelectorAll(
                    'input[type="checkbox"]'
                  );
                  for (var j = 0; j < checkboxes.length; j++) {
                    checkboxes[j].checked = false;
                  }
                }}
              ></button>
            </div>
            <div className="modal-body p-0">
              <div>
                <img
                  src={
                    currentProduct && currentProduct.image.length
                      ? process.env.REACT_APP_API_BASE_URL +
                        currentProduct.image
                      : ""
                  }
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="p-3">
                {currentProduct &&
                getPrice(currentProduct, happyHours) !== currentProduct.prix ? (
                  <div className="d-flex align-items-center gap-2 mb-3">
                    <img
                      src="/newmenu/img/veg.jpeg"
                      alt=""
                      className="img-fluid ch-20"
                    />
                    <div className="badge border border-warning text-warning">
                      HAPPY HOUR !
                    </div>
                  </div>
                ) : null}
                <h3 className="fw-bold mb-2">
                  {currentProduct && translation(currentProduct.titre)}
                </h3>
                <p className="text-muted mb-3" style={{ fontSize: "14px" }}>
                  {" "}
                  {currentProduct && translation(currentProduct.description)}
                </p>
                <div id="checklist">
                  {currentProductIngredients &&
                    currentProductIngredients.map((value, index) => (
                      <>
                        <input
                          type="checkbox"
                          name="r"
                          value={value}
                          id={index}
                          onChange={(event) =>
                            handleCheckboxChange(event, value)
                          }
                        />
                        <label for={index}>
                          {" "}
                          {value.titre}
                          {value.prix > 0
                            ? " + " +
                              value.prix.toFixed(chiffreApresVirg) +
                              currency
                            : null}{" "}
                        </label>
                      </>
                    ))}
                </div>
              </div>
              {/* <div>
                {checkedValues &&
                  checkedValues.map((value, index) => (
                    <p> {value.prix}</p>
                  ))}
              </div> */}
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <div className="btn btn-white border border-danger col-6 px-1">
                <div className="d-flex align-items-start justify-content-between px-1">
                  <div
                    className="minus"
                    style={{ padding: "0px 10px 0px 10px" }}
                    onClick={(e) =>
                      quantity !== 1 ? setQuantity(quantity - 1) : null
                    }
                  >
                    <i className="fa-solid fa-minus text-danger"></i>
                  </div>
                  <input
                    className="shadow-none form-control text-center border-0 p-0 box"
                    type="text"
                    placeholder=""
                    aria-label="default input example"
                    value={quantity}
                  />
                  <div
                    className="plus"
                    style={{ padding: "0px 10px 0px 10px" }}
                    onClick={(e) => setQuantity(quantity + 1)}
                  >
                    <i className="fa-solid fa-plus text-danger"></i>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-danger col-5"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  currentProduct &&
                    addProductToCart(currentProduct, checkedValues);
                }}
              >
                {t("Ajouter")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <NewMenuFooter id={id} table={table} />
    </>
  );
}

export default Store;
