import axios from "axios";
import { getSession } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API;

/* Setting the header for the axios request. */
const config = {
  headers: { Authorization: `Bearer ${getSession("token")}` },
};
const currentOrg = getSession("usedorganisations");

export async function addWaiter(
  nom,
  prenom,
  email,
  password,
  role,
  mobile,
  tables,
  gender,
  type,
  date_naissance,
) {
  const body = {
    nom: nom,
    prenom: prenom,
    email: email,
    password: password,
    role: role,
    mobile: mobile,
    tables: tables,
    gender: gender,
    type: type,
    date_naissance: date_naissance,
    isactiv: "1",
    organisation_id: currentOrg,
  };

  const httpHeaders = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(
      api_url + "register-email",
      body,
      httpHeaders
    );
    return response;
  } catch (error) {
    return error?.response;
  }
}

export async function getWaiters(id) {
  if (id == null) {
    id = currentOrg;
  }
  try {
    const response = await axios.get(
      api_url + "users/organisation/" + id,
      config
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getWaiterById(id) {
  
  try {
    const response = await axios.get(
      api_url + "users/" + id,
      config
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function editWaiter(id) {
  const body = {};
  const httpHeaders = {
    Authorization: `Bearer ${getSession("token")}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.patch(
      api_url + "users/" + id,
      body,
      httpHeaders
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}