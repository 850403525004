import React, { useEffect, useState } from "react";
import "./MenusStyle/menu2.css";
import { useOrganisations } from "../Auth/useOrganisations";
import { getMenuByCarteId, getMenus } from "../Services/MenuApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getOrganisationById } from "../Services/OrganizationApi";
import { registerScan } from "../Services/TrackingApi";
import { Rating } from "react-simple-star-rating";
import swal from "sweetalert";
import fr from "./MenusStyle/france.png";
import eng from "./MenusStyle/england.png";
import ar from "./MenusStyle/tun.png";
import { changeLanguage } from "../i18n";
import Facebook from "./MenusStyle/facebook.png";
import Instagram from "./MenusStyle/instagram.png";
import Youtube from "./MenusStyle/youtube.png";
import Website from "./MenusStyle/internet.png";

import { addComment } from "../Services/CommentsApi";
import { getSocials } from "../Services/SocialsApi";
import {
  Button,
  CssBaseline,
  Drawer,
  IconButton,
  Modal,
  Skeleton,
  styled,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { addCart } from "../Services/CartApi";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import props from "prop-types";
import { Global } from "@emotion/react";
import Swal from "sweetalert2";
import ReactDOM from "react-dom";
import { QuantityPicker } from "react-qty-picker";
import { addDemande } from "../Services/DemandeApi";
import TipsPopUp from "../Components/TipsPopUp";
import { toast } from "react-toastify";
import Cash from "./MenusStyle/money.png";
import Tpe from "./MenusStyle/card-machine.png";
import Coupon from "./MenusStyle/coupon.png";
import Cheque from "./MenusStyle/cheque.png";
import { getCartes } from "../Services/CartesApi";
import { useTranslation } from "react-i18next";
import BuyProductPopUp from "../Components/SuggestionPopUp";
import { getActiveHappyHours } from "../Services/HappyHourApi";
import { getPopup } from "../Services/PopupApi";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // A function that increment ðŸ‘†ðŸ» the previous state like here
  // is better than directly setting setValue(value + 1)
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function Menu2() {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { id, table, id_category } = useParams(); //id org from url
  const [currentCarte, seturrentCarte] = useState(id_category);
  const [menus, setMenus] = useState([]);
  const [categoryListing, setCategoryListing] = useState([]);
  const [categories, setCategories] = useState([]);
  const [org, setOrg] = useState(null);
  const [displayedMenu, setDisplayedMenu] = useState();
  const [comment, setComment] = useState();
  const [star, setStar] = useState(1);
  const [cartProducts, setCartProducts] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [tip, setTip] = useState(0);
  const [tips, setTips] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const paymentmodes = ["Cash", "Credit Card", "Tickets Restau", "Chéque"];
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [paymentMethods, setPaymenMethods] = useState([]);
  const [currency, setCurrency] = useState(0);
  const [chiffreApresVirg, setChiffreApresVirg] = useState();
  const [cartes, setCartes] = useState([]);
  const [lang, setLang] = useState("Fr");
  const [suggestedProduct, setSuggestedProduct] = useState();
  const [suggestedCategory, setSuggestedCategory] = useState();
  const [suggestion, setSuggestion] = useState();
  const [happyHours, setHappyHours] = useState([]);
  const [addition, setAddition] = useState({
    produits: [],
    prix: 0,
  });
  function handleQuantityChange(newQuantity) {
    if (!newQuantity) {
      newQuantity = 1;
    }
    setQuantity(newQuantity);
  }
  const translation = (titre) => {
    if (lang === "Fr") {
      return titre.fr;
    } else if (lang === "En") {
      return titre.en;
    } else return titre.ar;
  };

  const [cart, setCart] = useState({
    produits: [],
    table: atob(table),
    prix: 0,
    status: "Nouveau",
  });
  const forceUpdate = useForceUpdate();

  const [socials, setSocials] = useState({
    facebook: "",
    youtube: "",
    instagram: "",
    website: "",
  });
  const handleTipChange = (value) => {
    setTip(value);
  };
  
  const addProductToCart = (product) => {
    let tempQuantity = 1;
    const content = document.createElement("div");
    ReactDOM.render(
      <div>
        <QuantityPicker
          min={1}
          value={quantity}
          onChange={(value) => {
            handleQuantityChange();
            tempQuantity = value;
          }}
        />
      </div>,
      content
    );
    // const tempQuantity = quantity; // Store the value of quantity in a separate variable
    Swal.fire({
      title: "<span style='color: #353535'>Quantité :</span>",
      html: content,
      confirmButtonColor: "#3b3b3b",
      confirmButtonText: "Ajouter",
      onClose: () => {
        ReactDOM.unmountComponentAtNode(content);
      },
    }).then(function () {
      let tempCart = cart;
      if (tempQuantity > 0) {
        for (let i = 0; i < tempQuantity; i++) {
          // Create a new cart product object
          const cartProduct = {
            products: product, // Reference to the product in the "menus" collection
            ingredients: [], // Array of ingredient references
          };

          tempCart.produits.push(cartProduct);

          // You can use reduce to group cart products as before
          const result = tempCart.produits.reduce((acc, curr) => {
            const key = `${curr.product_id}`; // Use the product_id as the key
            const existingItem = acc.find((item) => item.key === key);

            if (existingItem) {
              existingItem.data.push(curr);
            } else {
              acc.push({ key, data: [curr] });
            }

            return acc;
          }, []);

          setCartProducts(result);

          // Calculate the total price based on products and ingredients
          tempCart.prix += getPrice(product, happyHours);
          // Update the cart state
          setCart(tempCart);

          forceUpdate();
        }
      }
    });
  };
  function newArray(produits) {
    console.log(produits);
    const result = produits.reduce((acc, curr) => {
      const key = `${curr.products.titre.fr}`;
      const prix = `${curr.products.prix}`;
      const existingItem = acc.find((item) => item.key === key);
      if (existingItem) {
        existingItem.data.push(curr.products);
      } else {
        acc.push({
          key,
          data: [curr],
          prix,
        });
      }
      return acc;
    }, []);

    return result;
  }

  function removeProductFromCart(productId) {
    console.log(productId);
    let tempCart = cart;
    const indexToRemove = tempCart.produits.findIndex(
      (item) => item.products.id === productId
    );
    console.log(indexToRemove);

    if (indexToRemove !== -1) {
      // Remove the item from the produits array

      tempCart.prix -= tempCart.produits[indexToRemove].products.prix;
      tempCart.produits.splice(indexToRemove, 1);
      forceUpdate();
      setCart(tempCart);
    }
    console.log(tempCart);
  }

  const promptForDelete = () => {
    swal({
      title: "Nous avons bien reçu votre avis !",
      icon: "success",
    }).then(function () {
      // window.location = "/categories";
    });
  };
  const promptForAddition = () => {
    swal({
      title: "Attendez, notre serveur va venir à votre table!",
      icon: "success",
    }).then(function () {
      // window.location = "/categories";
    });
  };
  const promptForCart = () => {
    swal({
      title: "Commande confirmé !",
      icon: "success",
    }).then(function () {
      // window.location = "/categories";
    });
  };
  const promptForAdd = () => {
    Swal.fire({
      text: "Produit ajouté au panier !",
    }).then(function (result) {
      if (result.value) {
        setQuantity(result.value);
      }
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    //@ToDo: Test all input errors here.
    addComment(comment, star, atob(table), id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        // alert("ajouté avec succees");
        promptForDelete();
      }
    });
    return false;
  };
  const callServer = async (e) => {
    addDemande(atob(table), id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        promptForAddition();
      }
    });
  };

  function getPrice(val2, happyHours) {
    const happyHourItem = happyHours.find(
      (item) => item.menu_id._id === val2.id
    );

    if (happyHourItem) {
      return val2.prix - happyHourItem.reduction;
    } else {
      return val2.prix;
    }
  }
  const addSuggestionAndConfirm = () => {
    const product = suggestedProduct;
    let tempCart = cart;
    tempCart.produits.push(product);
    const result = tempCart.produits.reduce((acc, curr) => {
      const key = `${curr.titre.fr}`;
      const prix = `${curr.prix}`;
      const existingItem = acc.find((item) => item.key === key);

      if (existingItem) {
        existingItem.data.push(curr);
      } else {
        acc.push({ key, data: [curr], prix });
      }
      return acc;
    }, []);
    setCartProducts(result);
    tempCart.prix += product.prix;
    setCart(tempCart);
    forceUpdate();
  };

  const CartAdd = async () => {
    console.log(cart);
    let tempCart = {
      produits: cart.produits.map((item) => ({
        products: item.products.id,
        ingredients: [],
      })),
      // Copy other properties from the original cart
      table: cart.table,
      prix: cart.prix,
      status: cart.status,
    };
    console.log(tempCart);
    addCart(tempCart, id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        const newAddition = {
          produits: addition.produits.concat(cart.produits),
          prix: addition.prix + cart.prix,
          tips: 0,
        };
        setAddition(newAddition);
        setCart({
          produits: [],
          table: atob(table),
          prix: 0,
          status: "Nouveau",
        });
        setCartProducts([]);
        promptForCart();
        forceUpdate();
      }
    });
    return false;
  };

  const handleRating = (rate) => {
    setStar(rate);
  };
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  let initialized = false;

  useEffect(() => {
    let initialized = false;

    // Function to display the alert message
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue =
        "You are going to lose your data. Are you sure you want to leave?";
    };

    // Add the event listener to display the alert message
    // window.addEventListener("beforeunload", handleBeforeUnload);
    setUsedOrganisations(id);
    if (!initialized) {
      registerScan(id);
      initialized = true;
    }
    getMenuByCarteId(usedOrganisations, currentCarte).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setMenus(res.data.data);
        let catList = [];
        let filtredData = res.data.data.filter(
          (arr) => arr.category_id && arr.dispo === true
        ); //we remove every product without category
        const result = filtredData.reduce((acc, curr) => {
          acc[curr.category_id._id] = acc[curr.category_id._id] || [];
          acc[curr.category_id._id].push(curr);
          return acc;
        }, {});
        let catMenu = Object.values(result);
        catMenu.sort((a, b) => a[0].category_id.ordre - b[0].category_id.ordre);
        setCategoryListing(catMenu);
        catMenu.forEach((element) => {
          catList.push(element[0].category_id);
        });
        const filteredCategories = catList.filter((cat) => cat.dispo === true);

        // Pass the filteredCategories to setCategories function
        setCategories(filteredCategories);
      }
    });
    getActiveHappyHours(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setHappyHours(res.data.data);
      }
    });
    getCartes(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        // const finalCatList = [];
        setCartes(res.data.data);
      }
    });
    getPopup(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        console.log(res.data.data.menu_id);
        setSuggestedProduct(res.data.data.menu_id);
        setSuggestedCategory(res.data.data.category_id);
        setSuggestion(res.data.data.suggestion);
      }
    });
    getOrganisationById(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
        setCurrency(res.data.data.currency);
        if (res.data.data.currency === "TND") {
          setChiffreApresVirg(3);
        } else if (
          res.data.data.currency === "Euro" ||
          res.data.data.currency === "Dollar"
        ) {
          setChiffreApresVirg(2);
        }
        setPaymenMethods(res.data.data.paiementmode);
      }
    });
    getSocials(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setSocials(res.data.data);
      }
    });
    // return () => {
    //   window.removeEventListener("beforeunload", handleBeforeUnload);
    // };
  }, []);

  const { window } = props;
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const toggleDrawer2 = (newOpen) => () => {
    setOpen2(newOpen);
  };
  function slowScroll(target, event) {
    event.preventDefault();
    const element = document.querySelector(target);
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition - 20;
    window.scrollBy({
      top: offsetPosition,
      behavior: "smooth",
    });
  }

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const [direction, setDirection] = useState("unset");
  const [align, setAlign] = useState("unset");
  const arabicLayout = () => {
    setDirection("rtl");
    setAlign("right");
  };
  const francoLayout = () => {
    setDirection("unset");
    setAlign("unset");
  };

  return (
    <section className="menu2">
      <nav className="top-nav">
        <div className="row" style={{ margin: "0" }}>
          <div className="col-3" style={{ textAlign: "left" }}>
            <button
              onClick={goBack}
              className="btn btn-rounded"
              style={{ backgroundColor: "white", display: "flex" }}
            >
              <i
                className="fa-solid fa-chevron-left"
                style={{ fontSize: "30px" }}
              ></i>
            </button>
          </div>
          <div className="col-6">
            <a href="#food-menu">{t("Découvrez notre menu !")}</a>
          </div>
          <div className="col-3" style={{ textAlign: "right" }}>
            <div className="btn-group">
              <button
                className="btn btn-rounded dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                style={{ backgroundColor: "white", display: "flex" }}
              >
                <div className="btn-text">
                  {lang === "Fr" ? (
                    <>
                      <img
                        src={fr}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      Fr
                    </>
                  ) : lang === "En" ? (
                    <>
                      <img
                        src={eng}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      En
                    </>
                  ) : lang === "Ar" ? (
                    <>
                      <img
                        src={ar}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      Ar
                    </>
                  ) : null}
                </div>
              </button>
              <div
                className="dropdown-menu dropdown-menu-right"
                style={{ backgroundColor: "white", color: "black" }}
              >
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => {
                    setLang("Fr");
                    changeLanguage("fr");
                    francoLayout();
                  }}
                  style={{ color: "black" }}
                >
                  <img
                    src={fr}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  Fr
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => {
                    setLang("En");
                    changeLanguage("en");
                    francoLayout();
                  }}
                  style={{ color: "black" }}
                >
                  <img
                    src={eng}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  En
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => {
                    setLang("Ar");
                    changeLanguage("ar");
                    arabicLayout();
                  }}
                  style={{ color: "black" }}
                >
                  <img
                    src={ar}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  Ar
                </a>
              </div>
            </div>
          </div>
        </div>

        <a href="#food-menu">
          <img
            src="https://i.postimg.cc/fTzFMXKj/arrow-down.png"
            alt="Cardápio"
          />
        </a>
        <div className="banner">
          {org && org.logo ? (
            <img src={process.env.REACT_APP_API_BASE_URL + org.logo} />
          ) : (
            ""
          )}
        </div>
      </nav>
      <section id="home" className="home">
        <img src="banner-standard.png" alt="" className="img-fluid" />
      </section>
      <div
        className="container"
        id="food-menu"
        style={{ scrollBehavior: "smooth", direction: direction }}
      >
        <h1 className="h1">Menu</h1>
        <section className="content-menu">
          <aside className="aside-float" id="aside-float">
            <nav className="nav-side">
              {categories.map((val, index) => {
                return (
                  <a
                    href={"#" + translation(val.libelle)}
                    onClick={(e) => slowScroll("#" + translation(val.libelle))}
                  >
                    {" "}
                    {translation(val.libelle)}{" "}
                  </a>
                );
              })}
            </nav>
          </aside>
          <section className="content-side">
            {categories.map((val, index) => {
              return (
                <section className="items" id="items">
                  <article className="plates">
                    <div
                      id={translation(val.libelle)}
                      style={{ height: "50px" }}
                    ></div>
                    <div
                      className="header1"
                      style={{
                        backgroundImage: `url(${
                          val.logo
                            ? process.env.REACT_APP_API_BASE_URL +
                              val.logo.replace("\\", "/")
                            : "../images/product/no-image.png"
                        })`,
                      }}
                    >
                      <div
                        className="text"
                        style={{ backgroundColor: "#00000045" }}
                      >
                        {" "}
                        {translation(val.libelle)}{" "}
                      </div>
                    </div>
                    <ul className="menu-items">
                      {categoryListing[index].map((val2, index2) => {
                        return (
                          <li className="row item">
                            <span
                              className="col-9 food"
                              onClick={(e) => {
                                setDisplayedMenu(val2);
                                handleOpen();
                              }}
                              style={{ textAlign: align }}
                            >
                              {translation(val2.titre).length > 15
                                ? translation(val2.titre).substring(0, 15) +
                                  " ..."
                                : translation(val2.titre)}
                              <p
                                className="ingredients"
                                onClick={(e) => {
                                  setDisplayedMenu(val2);
                                  handleOpen();
                                }}
                              >
                                {" "}
                                {translation(val2.description).length > 30
                                  ? translation(val2.description).substring(
                                      0,
                                      30
                                    ) + " ..."
                                  : translation(val2.description)}
                              </p>
                            </span>
                            <span className="col-3 price">
                              {" "}
                              <div
                                className="row"
                                style={{ justifyContent: "center" }}
                                onClick={(e) => {
                                  setDisplayedMenu(val2);
                                  handleOpen();
                                }}
                              >
                                {val2.prix}{" "}
                              </div>
                              <div
                                className="row"
                                style={{ justifyContent: "center" }}
                              >
                                <button
                                  type="button"
                                  className="waves-effect waves-circle btn btn-circle btn-success-light btn-xs mb-5"
                                  onClick={(e) => addProductToCart(val2)}
                                  style={{ float: "right" }}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </article>
                </section>
              );
            })}
          </section>
        </section>
        <footer className="footer">
          <div className="bon-appetit">
            <nav className="nav-side">
              <a href="#food-menu">
                <img
                  src="https://i.postimg.cc/nrDLzgZg/arrow-up.png"
                  alt="Voltar ao Topo"
                />
              </a>
            </nav>
            <h2>{t("bon appetit")}</h2>
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            <button
              type="button"
              className="waves-effect waves-light btn btn-rounded btn-warning mb-5"
              onClick={(e) => {
                addition.prix > 0
                  ? setOpen2(true)
                  : toast.error("Passer une commande d'abord !");
              }}
            >
              <span className="btn-addition-text">{t("Addition")}</span>
            </button>
          </div>

          <form onSubmit={onSubmit}>
            <div className="mx-auto mt-40">
              <h3 className="text-uppercase text-white">{t("Votre avis")}</h3>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control p-10"
                  placeholder="Pensez-vous......"
                  value={comment}
                  onChange={(e) => setComment(e.currentTarget.value)}
                  style={{ backgroundColor: "white" }}
                />
                <div className="input-group-append">
                  <button type="submit" className="btn btn-success">
                    Envoyer
                  </button>
                </div>
              </div>
            </div>
            <div>
              <Rating
                onClick={handleRating}
                initialValue={1}
                ratingValue={star}
                size={20}
                label
                transition
                fillColor="orange"
                emptyColor="gray"
                className="foo" // Will remove the inline style if applied
                value={star}
                onChange={(e) => setStar(e.currentTarget.value)}
              />
              {/* Use rating value */}
            </div>
          </form>
          <div className="social-media">
            <nav>
              <Link
                onClick={(e) => (window.location.href = socials.facebook)}
                className="waves-effect waves-circle btn btn-circle btn-xs m-5"
              >
                <img src={Facebook} />
              </Link>
              <Link
                onClick={(e) => (window.location.href = socials.instagram)}
                className="waves-effect waves-circle btn btn-circle btn-xs m-5"
              >
                <img src={Instagram} />{" "}
              </Link>
              <Link
                onClick={(e) => (window.location.href = socials.youtube)}
                className="waves-effect waves-circle btn btn-circle btn-xs m-5"
              >
                <img src={Youtube} />{" "}
              </Link>
              <Link
                onClick={(e) => (window.location.href = socials.website)}
                className="waves-effect waves-circle btn btn-circle btn-xs m-5"
              >
                <img src={Website} />{" "}
              </Link>
            </nav>
          </div>
          <div
            className="paiement-mode"
            style={{
              borderRadius: "10px",
              padding: "5px 5px 5px 10px",
              justifyContent: "center",
            }}
          >
           
            <div className="row">
              {paymentMethods &&
                paymentMethods.map((value) => (
                  <div className="col-3" style={{ justifyContent: "center" }}>
                    <p>
                      <div
                        className="waves-effect waves-circle btn btn-circle btn-xs m-5"
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          width: "50px",
                          height: "50px",
                          fontSize: "24px",
                          display: "grid",
                          alignItems: "center",
                        }}
                      >
                        {value === "Cash" ? (
                          <i className="fa-regular fa-money-bill-1"></i>
                        ) : value === "Tickets Restau" ? (
                          <i className="fa-solid fa-ticket"></i>
                        ) : value === "Credit Card" ? (
                          <i className="fa-brands fa-cc-mastercard"></i>
                        ) : (
                          <i className="fa-solid fa-money-check-dollar"></i>
                        )}
                      </div>
                    </p>
                  </div>
                ))}
            </div>
          </div>
          <div style={{ overflow: "hidden" }}>
            <h4
              style={{
                fontSize: "15px",
                whiteSpace: "nowrap",
                animation: "scroll 10s linear infinite",
              }}
            >
              {currency === "TND"
                ? t("Tous les prix sont en Dinar Tunisien.")
                : currency === "CFA"
                ? t("Tous les prix sont en CFA.")
                : currency === "Dinar libyen"
                ? t("Tous les prix sont en Dinar libyen")
                : currency === "$"
                ? t("Tous les prix sont en Dollar $")
                : t("Tous les prix sont en Euro €")}
            </h4>
          </div>
          <div className="copyright">
            <p>© 2023 SCANINY. {t("Tous droits réservés.")}</p>
          </div>
        </footer>
      </div>

      <SwipeableDrawer
        container={container}
        anchor="left"
        open={open2}
        onClose={toggleDrawer2(false)}
        onOpen={toggleDrawer2(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "8px",
          }}
        >
          <IconButton onClick={toggleDrawer2(false)} sx={{ float: "right" }}>
            <i className="fa-solid fa-xmark"></i>
          </IconButton>
        </div>
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        ></StyledBox>
        <StyledBox
          sx={{
            bottom: 0,
            height: "100%",
            overflow: "auto",
          }}
        >
          <div className="box-body" style={{ padding: "0px 40px 0px 40px" }}>
            <div
              className="table-responsive-xl"
              style={{ overflowX: "hidden" }}
            >
              <table className="table product-overview">
                <thead>
                  <tr>
                    <th>{t("Designation")}</th>

                    <th>{t("prix")}</th>
                  </tr>
                </thead>
                <tbody>
                  {addition.prix > 0 ? (
                    addition.produits &&
                    newArray(addition.produits).map((value, index) => (
                      <tr>
                        <td>
                          {value.data.length}{" "}
                          {value.key.length > 10
                            ? value.key.substring(0, 10) + " ..."
                            : value.key}
                        </td>

                        <td>
                          {" "}
                          {value.prix * value.data.length}
                          {currency}{" "}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4">Vous n'avez aucune commande.</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div className="row">
                <div className="col-12 text-right">
                  <div>
                    <p>
                      {t("Prix Total")} :{" "}
                      {addition.prix && addition.prix.toFixed(chiffreApresVirg)}{" "}
                      {currency}{" "}
                    </p>
                    <p>
                      {t("Tips")} :{" "}
                      {addition.tips && addition.tips.toFixed(chiffreApresVirg)}{" "}
                      {currency}{" "}
                    </p>
                  </div>
                  <div className="total-payment">
                    <h3 style={{ color: "black" }}>
                      <b>Total :</b>{" "}
                      {(addition.tips + addition.prix).toFixed(
                        chiffreApresVirg
                      )}{" "}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            {/* <button
                  onClick={(e) => {
                    callServer();
                  }}
                  className="waves-effect waves-light btn btn-rounded btn-warning mb-5"
                >
                  <i className="fa fa-solid fa-bell"></i>
                </button> */}
            <TipsPopUp confirm={callServer} onChange={handleTipChange} />
          </div>
        </StyledBox>
      </SwipeableDrawer>

      <Root>
        {/* <CssBaseline /> */}
        <Global
          styles={{
            ".MuiDrawer-root > .MuiPaper-root": {
              // height: `calc(50% - ${drawerBleeding}px)`,
              overflow: "visible",
            },
          }}
        />
        <div className="arrow bounce"></div>

        <SwipeableDrawer
          container={container}
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              height: "calc(50%)",
              bottom: 0,
            },
          }}
        >
          <StyledBox
            sx={{
              position: "absolute",
              top: -drawerBleeding,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: "visible",
              right: 0,
              left: 0,
            }}
          ></StyledBox>
          <StyledBox
            sx={{
              bottom: 0,
              height: "100%",
              overflow: "auto",
            }}
          >
            <div
              className="box"
              style={{
                float: "none",
                clear: "both",
                marginBottom: "0",
                borderRadius: "0",
                backgroundColor: "white",
                height: "100%",
                boxShadow: "none",
              }}
            >
              <div className="box-body p-10" style={{ direction: direction }}>
                <div className="table-responsive-xl" style={{ height: "100%" }}>
                  <table className="table product-overview">
                    <thead>
                      <table
                        className="table product-overview"
                        style={{
                          background: "white",
                          borderBottom: "1px solid gray",
                          left: "0",
                        }}
                      >
                        <thead>
                          <tr sty>
                            <th>{t("Designation")}</th>

                            <th>{t("prix")}</th>
                          </tr>
                        </thead>
                      </table>
                    </thead>
                    <tbody>
                      {/* <tr style={{ opacity: 0 }}>
                            <th>Designation</th>

                            <th>{t("prix")}</th>
                            <th style={{ textAlign: "center" }}></th>
                          </tr> */}
                      {cart.prix > 0 ? (
                        cart.produits &&
                        newArray(cart.produits).map((value, index) => (
                          <tr>
                            <td>
                              {value.data.length} {"  "}
                              {value.key.length > 40
                                ? value.key.substring(0, 40) + " ..."
                                : value.key}
                            </td>

                            <td>
                              {" "}
                              {getPrice(value.data[0].products, happyHours) *
                                value.data.length}{" "}
                            </td>

                            <td align="center">
                              <button
                                className="btn btn-circle btn-primary btn-xs"
                                title=""
                                onClick={(e) =>
                                  removeProductFromCart(value.data[0].products.id)
                                }
                              >
                                <i className="fa-solid fa-xmark"></i>
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4">
                            {t("Vous n'avez aucun article dans votre panier.")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div
                    className="table-footer"
                    style={{
                      bottom: "0px",
                      position: "absolute",
                      width: "95%",
                      position: "unset",
                    }}
                  >
                    {cart.prix > 0 ? (
                      <>
                        <div className="row m-0">
                          <div className="col-12 text-right">
                            <div className="total-payment">
                              <h3 style={{ color: "black" }}>
                                <b>{t("Total")} :</b>{" "}
                                {cart.prix.toFixed(chiffreApresVirg)}
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="row" style={{ display: "contents" }}>
                          <BuyProductPopUp
                            confirm={(e) => addSuggestionAndConfirm()}
                            decline={(e) => CartAdd(0)}
                            product={suggestedProduct}
                            category={suggestedCategory}
                            suggestionMessage={suggestion}
                            cartProducts={cartProducts}
                            addProductToCart={addProductToCart}
                            toggleDrawer={toggleDrawer}
                            orgId={id}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <button onClick={(e)=> console.log(cartProducts)}>click</button> */}
                  </div>
                </div>
              </div>
            </div>
          </StyledBox>
        </SwipeableDrawer>
      </Root>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ color: "black" }}
          >
            Details du produit
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="box box-body b-1 text-center no-shadow">
                  <img
                    src={
                      displayedMenu && displayedMenu.image
                        ? process.env.REACT_APP_API_BASE_URL +
                          displayedMenu.image
                        : "../images/products/no-image.png"
                    }
                    id="product-image"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="clear"></div>
              </div>
              <div className="col-md-8 col-sm-6">
                <h2 className="box-title mt-0" style={{ color: "black" }}>
                  {displayedMenu && translation(displayedMenu.titre)}
                </h2>

                {/* <h1 className="pro-price mb-0 mt-20">
                  {displayedMenu && displayedMenu.prix} {currency}
                </h1> */}
                <p>{displayedMenu && displayedMenu.description}</p>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </section>
  );
}
export default Menu2;
