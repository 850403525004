import React, { useEffect, useState } from "react";

import { getWaiters } from "../Services/WaiterApi";
import Tables from "./ImpressionCodes";
import { Link } from "react-router-dom";
import { useUser } from "../Auth/useUser";
import { useOrganisations } from "../Auth/useOrganisations";
import GifLoader from "../Components/loader";
import swal from "sweetalert";
import { deleteUserById } from "../Services/UserApi";
import { getOrganisationById } from "../Services/OrganizationApi";
import { useTranslation } from "react-i18next";

function Waiters() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [waiter, setWaiter] = useState([]);
  const user = useUser();
  const [rand, setRand] = useState(0);
  const [infoUser] = useState(user.result);
  const [loading, setLoading] = useState(true);
  const [org, setOrg] = useState();

  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();

  const promptForDelete = (userId) => {
    swal({
      title: "Etes-vous sûr ?",
      text: "Une fois supprimé, vous le perdrez !",
      icon: "warning",
      buttons: ["Non", "Oui"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteUserById(userId).then((res) => {
          if (res && res.data.success) {
            swal("Pouf ! Votre serveur a été supprimée !", {
              icon: "success",
            });
            setRand(rand + 1);
          } else {
            swal("Erreur lors de la suppression du menu !", {
              icon: "error",
            });
          }
        });
      } else {
        swal("Votre serveur est en sécurité !");
      }
    });
  };

  useEffect(() => {
    //check user type
    if (org && org.type !== "Restaurant") {
      window.location.href = "/";
    }
    getWaiters(usedOrganisations).then((res) => {
      // setLoading(false);
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setWaiter(res.data.data);
      }
    });
    getOrganisationById(usedOrganisations).then((res) => {
      // setLoading(false);
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
      }
    });
  }, [rand]);
  return (
    <section className="content">
      {/* <GifLoader loading={loading} /> */}
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="mr-auto">
            <h3 className="page-title">{t("Liste des serveurs")}</h3>
            <div className="d-inline-block align-items-center">
              {/* <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">
                        <i className="mdi mdi-home-outline"></i>
                      </a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Order
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Order List
                    </li>
                  </ol>
                </nav> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Link
            to={"/waiters/add"}
            className="btn btn-success mt-10 d-block text-center"
            style={{ right: "0", float: "right", marginBottom: "20px" }}
          >
            <i className="fa fa-plus mr-5"></i>
            {t("Ajouter")}
          </Link>
        </div>
      </div>

      <div className="row">
        {waiter &&
          waiter.map((value, index) => (
            <div className="col-12 col-lg-3">
              <div className="box ribbon-box">
                <div className="ribbon-two ribbon-two-primary">
                  <span>{t("Serveur")}</span>
                </div>
                <div className="box-header no-border p-0">
                  <a href="#">
                    <img
                      className="img-fluid"
                      src="../images/avatar/375x200/2.jpg"
                      alt=""
                    />
                  </a>
                </div>
                <div className="box-body">
                  <div className="user-contact list-inline text-center">
                    <button
                      className="btn btn-circle mb-5 btn-facebook"
                      onClick={(e) => {
                        window.location.href = "/waiters/edit/" + value.id;
                      }}
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                    <button
                      className="btn btn-circle mb-5 btn-instagram"
                      onClick={(e) => promptForDelete(value.id)}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                  <div className="text-center">
                    <h3 className="mt-40">
                      {value.nom} {value.prenom}{" "}
                    </h3>
                    <h6 className="user-info mt-0 mb-10 text-fade">
                      {t("Tables affectés")}
                    </h6>
                    <p className="text-fade w-p85 mx-auto">
                      <div className="input-group">
                        <ul className="icolors">
                          {value.tables &&
                            value.tables.map((item, index) => (
                              <li className="bg-danger rounded-circle">{item}</li>
                            ))}
                        </ul>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
}
export default Waiters;
