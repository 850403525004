import React, { useEffect, useRef, useState } from "react";
import TemplateCleaner from "./TemplateCleaner";
import { Link, useParams } from "react-router-dom";
import { getOrganisationById } from "../../Services/OrganizationApi";
import { getPersonalisationMenu } from "../../Services/TemplateMenuApi";
import { getSocials } from "../../Services/SocialsApi";
import { getComments } from "../../Services/CommentsApi";
import { getCategories } from "../../Services/CategrieApi";
import GifLoader from "../../Components/loader";
import { getActiveHappyHours } from "../../Services/HappyHourApi";
import fr from "../MenusStyle/france.png";
import eng from "../MenusStyle/england.png";
import ar from "../MenusStyle/tun.png";
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import Facebook from "../MenusStyle/facebook.png";
import Instagram from "../MenusStyle/instagram.png";
import Youtube from "../MenusStyle/youtube.png";
import Website from "../MenusStyle/internet.png";
import styled from "styled-components";
import TemplateCleanerCatalog from "./TemplateCleanerCatalog";

const RestyledLayout = styled.div`
  .nav-link {
    border: none;
    color: #ea7001;
    padding: 13px 13px;
  }
  .nav-link:hover {
    color: #a16833;
  }
  .nav-link.active {
    color: #d9be8e;
    border-bottom: 2px solid var(--red);
  }
`;

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // A function that increment ðŸ‘†ðŸ» the previous state like here
  // is better than directly setting setValue(value + 1)
}

function Catalogue() {
  const { id } = useParams();
  //   const style = document.head.appendChild(style);
  //   style.innerHTML = `
  //   :root {
  //     --themecolor: #D9BE8E;
  //     --fontcolor: #A16833;
  //     --font: #EA7001;
  //   }
  // `;
  const [org, setOrg] = useState();
  const [filter, setFilter] = useState("");
  const [categories, setcategories] = useState([]);
  const [categoriesMenus, setcategoriesMenus] = useState([]);
  const [currentProduct, setCurrentProduct] = useState();
  const [currency, setCurrency] = useState();
  const [color, setColor] = useState();
  const [rating, setRating] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [reviewsCount, setReviewsCount] = useState([]);
  const [background, setBackground] = useState();
  const [socials, setSocials] = useState();
  const [template, setTemplate] = useState();
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [chiffreApresVirg, setChiffreApresVirg] = useState();
  const [checkedValues, setCheckedValues] = useState([]);
  const [layout, setLayout] = useState("unset");

  const { t } = useTranslation();
  const [lang, setLang] = useState(
    localStorage.getItem("selectedLanguage") || "fr"
  );

  // Define a function to change the language
  const handleLanguageChange = (selectedLanguage) => {
    setLang(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);

    // Use the changeLanguage function from i18next
    changeLanguage(selectedLanguage);

    // You can also trigger any language-specific layout changes here if needed.
  };

  const translation = (titre) => {
    if (lang === "fr") {
      return titre.fr;
    } else if (lang === "en") {
      return titre.en;
    } else return titre.ar;
  };

  const currencyTranslation = (currency) => {
    if (lang === "fr" && currency === "TND") {
      return "TND";
    } else if (lang === "en" && currency === "TND") {
      return "TND";
    } else return " دت";
  };

  // Initialize your cart state with the saved data or an empty cart

  const [happyHours, setHappyHours] = useState([]);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    setTimeout(() => {
      var onPageSetupDoneEvent = new Event("onPageSetupDone");
      document.dispatchEvent(onPageSetupDoneEvent);
    }, 2000);
    changeLanguage(localStorage.getItem("selectedLanguage"));
    if (localStorage.getItem("selectedLanguage") === "ar") {
      setLayout("rtl");
    }

    getOrganisationById(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
        setCurrency(res.data.data.currency);
        if (res.data.data.currency === "TND") {
          setChiffreApresVirg(3);
        } else if (
          res.data.data.currency === "Euro" ||
          res.data.data.currency === "Dollar"
        ) {
          setChiffreApresVirg(2);
        }
      }
    });
    getActiveHappyHours(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setHappyHours(res.data.data);
      }
    });
    getPersonalisationMenu(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setTemplate(res.data.data);
        setBackground(res.data.data[0].background);
      }
    });
    getSocials(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setSocials(res.data.data);
      }
    });

    getComments(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setReviews(res.data.data);
        setReviewsCount(res.data.data.length);
        let fiveArray = res.data.data.filter((arr) => arr.star === 5);
        let fourArray = res.data.data.filter((arr) => arr.star === 4);
        let threeArray = res.data.data.filter((arr) => arr.star === 3);
        let twoArray = res.data.data.filter((arr) => arr.star === 2);
        let oneArray = res.data.data.filter((arr) => arr.star === 1);
        let max =
          (fiveArray.length * 5 +
            fourArray.length * 4 +
            threeArray.length * 3 +
            twoArray.length * 2 +
            oneArray.length) /
          res.data.data.length;
        setRating(max.toFixed(1));
      }
    });
    getCategories(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setcategories(res.data.data);
        setLoading(false);
      }
    });
  }, [id]);

  function getPrice(product, happyHours) {
    const happyHourItem = happyHours.find(
      (item) => item.menu_id._id === product._id
    );

    if (happyHourItem) {
      return product.prix - happyHourItem.reduction;
    } else {
      return product.prix;
    }
  }

  function happyHourTime(product, happyHours) {
    const happyHourItem = happyHours.find(
      (item) => item.menu_id._id === product._id
    );

    return happyHourItem;
  }

  function convertToAMPM(timeString) {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(":");

    // Convert hours to an integer
    const hour = parseInt(hours);

    // Determine whether it's AM or PM
    const period = hour < 12 ? "am" : "pm";

    // Format the time in "hampm" format
    let formattedHours = hour % 12 || 12; // Use 12 for 0 hours
    formattedHours =
      formattedHours < 10 ? `0${formattedHours}` : formattedHours;

    // Create the final formatted time string
    const formattedTime = `${formattedHours}${period}`;

    return formattedTime;
  }

  const firstCategoryButtonRef = useRef();

  // Simulate a click on the first category button when the page loads
  useEffect(() => {
    if (categories.length > 0) {
      firstCategoryButtonRef.current.click();
    }
  }, [categories]);

  return (
    <>
      <GifLoader loading={loading} />
      <TemplateCleanerCatalog />
      <RestyledLayout>
        <div className="bg-white shadow-sm p-3 sticky-top">
          <div className="d-flex align-items-center">
            <div className="gap-3 d-flex align-items-center">
              <img
                src={
                  org && org.logo
                    ? process.env.REACT_APP_API_socket_URL + "/" +org.logo
                    : "/../images/product/product-9.png"
                }
                style={{ width: "30px", marginRight: "10px" }}
                alt="whatever"
              />
              <h3 className="fw-bold m-0"> {org && org.nom} </h3>
            </div>
            <div className="ms-auto gap-3 d-flex align-items-center">
              <div className="btn-group">
                <button
                  className="btn btn-rounded dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  style={{
                    backgroundColor: "white",
                    display: "flex",
                    padding: "0",
                  }}
                >
                  <div className="btn-text">
                    {lang === "fr" ? (
                      <>
                        <img
                          src={fr}
                          style={{ width: "30px", marginRight: "10px" }}
                        />
                        Fr
                      </>
                    ) : lang === "en" ? (
                      <>
                        <img
                          src={eng}
                          style={{ width: "30px", marginRight: "10px" }}
                        />
                        En
                      </>
                    ) : lang === "ar" ? (
                      <>
                        <img
                          src={ar}
                          style={{ width: "30px", marginRight: "10px" }}
                        />
                        Ar
                      </>
                    ) : null}
                  </div>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  style={{ backgroundColor: "white" }}
                >
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      handleLanguageChange("fr");
                      setLayout("unset");
                    }}
                  >
                    <img
                      src={fr}
                      style={{ width: "30px", marginRight: "10px" }}
                    />
                    Fr
                  </a>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      handleLanguageChange("en");
                      setLayout("unset");
                    }}
                  >
                    <img
                      src={eng}
                      style={{ width: "30px", marginRight: "10px" }}
                    />
                    En
                  </a>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      handleLanguageChange("ar");
                      setLayout("rtl");
                    }}
                  >
                    <img
                      src={ar}
                      style={{ width: "30px", marginRight: "10px" }}
                    />
                    Ar
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="p-3"
          style={{
            backgroundImage:
              "url(../../../images/auth-bg/backgroundmenu/bg-" +
              background +
              ".jpg)",
            backgroundPosition: "bottom",
            backgroundSize: "cover",
          }}
        >
          <div
            className="row align-items-start justify-content-between"
            style={{ backgroundColor: "#00000045" }}
          >
            <div className="col-8">
              <h1 className="fw-bold mb-1" style={{ color: "aliceblue" }}>
                {" "}
                {org && org.nom}{" "}
              </h1>
              <div className="fs-6 mb-2" style={{ color: "aliceblue" }}>
                {org && org.description}{" "}
              </div>
              <p className="small text-muted" style={{ color: "aliceblue" }}>
                <nav>
                  <Link
                    onClick={(e) => (window.location.href = socials.facebook)}
                    className="waves-effect waves-circle btn btn-circle btn-xs"
                  >
                    <img src={Facebook} style={{ width: "20px" }} />
                  </Link>
                  <Link
                    onClick={(e) => (window.location.href = socials.instagram)}
                    className="waves-effect waves-circle btn btn-circle btn-xs"
                  >
                    <img src={Instagram} style={{ width: "20px" }} />{" "}
                  </Link>
                  <Link
                    onClick={(e) => (window.location.href = socials.youtube)}
                    className="waves-effect waves-circle btn btn-circle btn-xs"
                  >
                    <img src={Youtube} style={{ width: "20px" }} />{" "}
                  </Link>
                  <Link
                    onClick={(e) => (window.location.href = socials.website)}
                    className="waves-effect waves-circle btn btn-circle btn-xs"
                  >
                    <img src={Website} style={{ width: "20px" }} />{" "}
                  </Link>
                </nav>
              </p>
              {/* <div className="d-flex align-items-center gap-2">
              <div>
                <img
                  src="/newmenu/img/delivery.png"
                  alt=""
                  className="img-fluid ch-20"
                />
              </div>
              <a
                href="support.html"
                className="text-decoration-none text-muted small"
              >
                know more
              </a>
            </div> */}
            </div>
            {rating && rating >= 4 ? (
              <div className="col-3">
                <div className="shadow rounded-3">
                  <div className="bg-success rounded-top text-white">
                    <div className="d-flex justify-content-center gap-1 p-1">
                      <div className="h2 m-0"> {rating} </div>
                      <div className="pt-1">
                        <i className="fa-solid fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <div className="text-center rounded-bottom p-1 bg-white">
                    <div className="mb-0 text-warning fw-bold">
                      {" "}
                      {reviewsCount}{" "}
                    </div>
                    <div className="small text-muted">{t("Avis clients")}</div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {/* tabs with search bar */}
        <main className="sticky-top" style={{ direction: layout }}>
          <div
            className="bg-light"
            style={{ overflow: "auto", whiteSpace: "nowrap" }}
          >
            <div className="row m-0">
              <div className="col-12 p-0">
                <ul
                  className="nav osahan-tab payment-methods-container"
                  id="pills-tab"
                  role="tablist"
                >
                  {categories &&
                    categories.map((value, index) =>
                      value.menus_list.length ? (
                        <li
                          className="nav-item col p-0"
                          role="presentation"
                          key={index}
                        >
                          <button
                            ref={index === 0 ? firstCategoryButtonRef : null}
                            className="nav-link bg-light w-100"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                            onClick={(e) => {
                              setcategoriesMenus(value.menus_list);
                            }}
                          >
                            {translation(value.libelle)}
                          </button>
                        </li>
                      ) : null
                    )}
                </ul>
              </div>
            </div>
          </div>
          <div className="bg-white px-3 pt-3">
            <div className="input-group bg-white shadow rounded-md border p-1 mb-3">
              <span className="input-group-text border-0 bg-white">
                <i className="fa-solid fa-magnifying-glass text-danger"></i>
              </span>
              <input
                type="text"
                className="shadow-none form-control border-0"
                placeholder={t("Recherche")}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
          </div>
        </main>
        {/* Content */}
        <div className="p-3" style={{ direction: layout }}>
          <div className="row">
            <div className="col-12">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div>
                    {categoriesMenus &&
                      categoriesMenus.map((value, index) => {
                        const titre =
                          typeof value.titre === "object"
                            ? value.titre.fr
                            : value.titre;

                        return titre
                          .toLowerCase()
                          .includes(filter.toLowerCase()) ? (
                          <div className="col-12 col-md-4 mb-3" key={index}>
                            <Link className="text-decoration-none link-dark">
                              <div className="card rounded-4 shadow border-0 overflow-hidden">
                                <div className="position-relative">
                                  <img
                                    src={
                                      value && value.image.length
                                        ? process.env.REACT_APP_API_socket_URL +
                                          "/" +
                                          value.image[0]
                                        : ""
                                    }
                                    alt=""
                                    className="img-fluid rounded-top"
                                  />
                                </div>
                                <div className="card-body">
                                  <div className="d-flex justify-content-between">
                                    <div className="h4 fw-bold">
                                      {" "}
                                      {value.titre.fr}{" "}
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between text-muted h6 m-0 fw-normal">
                                    <div className="description">
                                      {" "}
                                      {value.description.fr}{" "}
                                    </div>
                                    <div className="price-tag">
                                      {" "}
                                      {value.prix > 0
                                        ? value.prix +
                                          " " +
                                          currencyTranslation(currency)
                                        : t("free")}{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        ) : null;
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RestyledLayout>
    </>
  );
}
export default Catalogue;
