import axios from "axios";
import { getSession } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API;

/* Setting the header for the axios request. */
const config = {
  headers: { Authorization: `Bearer ${getSession("token")}` },
};

//to add in every api
const currentOrg = getSession("usedorganisations");

export async function addComment(comment, star, table, id) {
  const body = {
    comment: comment,
    star: star,
    table: table,

  };
  const httpHeaders = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(api_url + "comment/" + id, body, httpHeaders);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function addProductComment(comment, star, product_id, id) {
  const body = {
    comment: comment,
    star: star,
    product_id: product_id,
  };
  const httpHeaders = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(api_url + "comment/" + id, body, httpHeaders);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getComments(id) {
  if (id === null) {
    id = currentOrg;
  }
  try {
    const response = await axios.get(api_url + "comment/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}