import React, { useEffect, useState } from "react";
import "./MenusStyle/menu4.css";
import { getMenuByCarteId, getMenus } from "../Services/MenuApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slide,
  SwipeableDrawer,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import { getPersonalisationMenu } from "../Services/TemplateMenuApi";
import { useTranslation } from "react-i18next";
import fr from "./MenusStyle/france.png";
import eng from "./MenusStyle/england.png";
import ar from "./MenusStyle/tun.png";
import { changeLanguage } from "i18next";
import { QuantityPicker } from "react-qty-picker";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import { getActiveHappyHours } from "../Services/HappyHourApi";
import { grey } from "@mui/material/colors";
import { getOrganisationById } from "../Services/OrganizationApi";
import BuyProductPopUp from "../Components/SuggestionPopUp";
import { getPopup } from "../Services/PopupApi";
import { addCart } from "../Services/CartApi";
import swal from "sweetalert";
import Cart from "./MenusStyle/cart.png";
import { toast } from "react-toastify";
import { addComment } from "../Services/CommentsApi";
import { getSocials } from "../Services/SocialsApi";
import { Rating } from "react-simple-star-rating";
import Facebook from "./MenusStyle/facebook.png";
import Instagram from "./MenusStyle/instagram.png";
import Youtube from "./MenusStyle/youtube.png";
import Website from "./MenusStyle/internet.png";
import TipsPopUp from "../Components/TipsPopUp";
import { addDemande } from "../Services/DemandeApi";

const drawerBleeding = 56;
const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // A function that increment ðŸ‘†ðŸ» the previous state like here
  // is better than directly setting setValue(value + 1)
}

function Menu4() {
  const { id, table, id_category } = useParams();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [lang, setLang] = useState("Fr");
  const [personalisationMenu, setPesonalisationMenu] = useState();
  const [chiffreApresVirg, setChiffreApresVirg] = useState();
  const [open2, setOpen2] = React.useState(false);
  const translation = (titre) => {
    if (lang === "Fr") {
      return titre.fr;
    } else if (lang === "En") {
      return titre.en;
    } else return titre.ar;
  };
  const [open, setOpen] = React.useState(false);
  const [openCart, setOpenCart] = React.useState(false);
  const [index, setIndex] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [menus, setMenus] = useState([]);
  const [categoryListing, setCategoryListing] = useState([]);
  const [categories, setCategories] = useState([]);
  const colorsArray = ["green", "purple", "orange", "pink", "blue"];
  const [color, setColor] = useState();
  const [quantity, setQuantity] = useState(1);
  const [cartProducts, setCartProducts] = useState([]);
  const [happyHours, setHappyHours] = useState([]);
  const [org, setOrg] = useState();
  const [currency, setCurrency] = useState([]);
  const [paymentMethods, setPaymenMethods] = useState([]);
  const [popup, setPopup] = useState();
  const [suggestedProduct, setSuggestedProduct] = useState();
  const [suggestedCategory, setSuggestedCategory] = useState();
  const [suggestion, setSuggestion] = useState();
  const [comment, setComment] = useState();
  const [star, setStar] = useState();
  const [socials, setSocials] = useState();
  const [checked, setChecked] = useState("Cash");
  const handleRating = (rate) => {
    setStar(rate);
  };

  const handleToggle = (value) => () => {
    const newChecked = value === checked ? "" : value;
    setChecked(newChecked);
  };

  const [addition, setAddition] = useState({
    produits: [],
    prix: 0,
    tips: 0,
  });
  const forceUpdate = useForceUpdate();
  const [tips, setTips] = useState(0);
  const handleTipChange = (value) => {
    setTips(value);
  };
  const [cart, setCart] = useState({
    produits: [],
    table: atob(table),
    prix: 0,
    status: "Nouveau",
  });
  function handleQuantityChange(newQuantity) {
    if (!newQuantity) {
      newQuantity = 1;
    }
    setQuantity(newQuantity);
  }
  function getPrice(val2, happyHours) {
    const happyHourItem = happyHours.find(
      (item) => item.menu_id._id === val2.id
    );

    if (happyHourItem) {
      return val2.prix - happyHourItem.reduction;
    } else {
      return val2.prix;
    }
  }
  const promptForCart = () => {
    swal({
      title: `${t("Commande confirmée !")}`,
      icon: "success",
    }).then(function () {
      // window.location = "/categories";
    });
  };
  const CartAdd = async (tipValue) => {
    setTips(tips + tipValue);
    const arr = [];
    let tempCart = cart;
    cart.produits.forEach((value) => {
      if ("id" in value) {
        arr.push(value.id);
      } else {
        arr.push(value._id);
      }
    });
    //@ToDo: Test all input errors here.
    tempCart.produits = arr;
    tempCart.prix = tempCart.prix + tipValue;
    addCart(tempCart, id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        let tempAddition = addition.produits;
        tempAddition = tempAddition.concat(cartProducts);
        const groupedArray = tempAddition.reduce((acc, item) => {
          const index = acc.findIndex((element) => element.key === item.key);
          if (index === -1) {
            acc.push({
              key: item.key,
              data: item.data.slice(),
              prix: item.prix,
            });
          } else {
            acc[index].data.push(...item.data);
          }
          return acc;
        }, []);

        console.log(groupedArray);
        setAddition({
          produits: groupedArray,
          prix: addition.prix + tempCart.prix,
          tips: 0,
        });

        setCart({
          produits: [],
          table: atob(table),
          prix: 0,
          status: "Nouveau",
        });
        setCartProducts([]);
        promptForCart();
        forceUpdate();
      }
    });
    return false;
  };
  const addSuggestionAndConfirm = () => {
    const product = suggestedProduct;
    let tempCart = cart;
    tempCart.produits.push(product);
    const result = tempCart.produits.reduce((acc, curr) => {
      const key = `${curr.titre.fr}`;
      const prix = `${curr.prix}`;
      const existingItem = acc.find((item) => item.key === key);

      if (existingItem) {
        existingItem.data.push(curr);
      } else {
        acc.push({ key, data: [curr], prix });
      }
      return acc;
    }, []);
    setCartProducts(result);
    tempCart.prix += product.prix;
    setCart(tempCart);
    forceUpdate();
  };
  const removeProductFromCart = (product, index) => {
    let tempCart = {
      produits: [],
      table: atob(table),
      prix: 0,
      status: "Nouveau",
    };
    let tempCartProduits = cartProducts;
    tempCartProduits[index].data.splice(0, 1);
    if (tempCartProduits[index].data.length === 0) {
      tempCartProduits.splice(index, 1);
    }
    setCartProducts(tempCartProduits);

    for (var i = 0; i < tempCartProduits.length; i++) {
      for (var j = 0; j < tempCartProduits[i].data.length; j++) {
        tempCart.produits.push(tempCartProduits[i].data[j]);
        tempCart.prix += getPrice(tempCartProduits[i].data[j], happyHours);
      }
    }
    forceUpdate();
    setCart(tempCart);
    return;
  };
  const addProductToCart = (product) => {
    let tempCart = { ...cart }; // Create a copy of the cart object

    const tempQuantity = quantity; // Use the predefined 'quantity' value

    if (tempQuantity > 0) {
      for (let i = 0; i < tempQuantity; i++) {
        tempCart.produits.push(product);

        const result = tempCart.produits.reduce((acc, curr) => {
          const key = `${translation(curr.titre)}`;
          const prix = `${curr.prix}`;
          const existingItem = acc.find((item) => item.key === key);

          if (existingItem) {
            existingItem.data.push(curr);
          } else {
            acc.push({ key, data: [curr], prix });
          }

          return acc;
        }, []);

        setCartProducts(result);

        tempCart.prix += getPrice(product, happyHours);
        setCart(tempCart);
        forceUpdate();
      }
    }
  };

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const toggleDrawer = (newOpen) => () => {
    setOpenCart(newOpen);
  };
  const toggleDrawer2 = (newOpen) => () => {
    setOpen2(newOpen);
  };

  useEffect(() => {
    getSocials(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setSocials(res.data.data);
      }
    });
    getPopup(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setPopup(res.data.data);
        console.log(res.data.data.menu_id);
        setSuggestedProduct(res.data.data.menu_id);
        setSuggestedCategory(res.data.data.category_id);
        setSuggestion(res.data.data.suggestion);
      }
    });
    getOrganisationById(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
        setCurrency(res.data.data.currency);
        changeLanguage("fr");
        if (res.data.data.currency === "TND") {
          setChiffreApresVirg(3);
        } else if (
          res.data.data.currency === "Euro" ||
          res.data.data.currency === "Dollar"
        ) {
          setChiffreApresVirg(2);
        }
        setPaymenMethods(res.data.data.paiementmode);
      }
    });
    getActiveHappyHours(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setHappyHours(res.data.data);
      }
    });
    getPersonalisationMenu(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setPesonalisationMenu(res.data.data[0]);
        console.log(res.data.data);
      }
    });
    getMenuByCarteId(id, id_category).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setMenus(res.data.data);
        let catList = [];
        let filtredData = res.data.data.filter(
          (arr) => arr.category_id && arr.dispo === true
        ); //we remove every product without category
        const result = filtredData.reduce((acc, curr) => {
          acc[curr.category_id._id] = acc[curr.category_id._id] || [];
          acc[curr.category_id._id].push(curr);
          return acc;
        }, {});
        let catMenu = Object.values(result);
        catMenu.sort((a, b) => a[0].category_id.ordre - b[0].category_id.ordre);
        setCategoryListing(catMenu);
        catMenu.forEach((element) => {
          catList.push(element[0].category_id);
        });
        const filteredCategories = catList.filter((cat) => cat.dispo === true);

        // Pass the filteredCategories to setCategories function
        setCategories(filteredCategories);
      }
    });
  }, []);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const container = window !== undefined ? () => document.body : undefined;
  const promptForDelete = () => {
    swal({
      title: t("Nous avons bien reçu votre avis !"),
      icon: "success",
    }).then(function () {
      // window.location = "/categories";
    });
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    //@ToDo: Test all input errors here.
    addComment(comment, star, atob(table), id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        promptForDelete();
      }
    });
    return false;
  };
  const promptForAddition = () => {
    swal({
      title: "Attendez, notre serveur va venir à votre table!",
      icon: "success",
    }).then(function () {
      // window.location = "/categories";
    });
  };

  const callWaiter = async (tips) => {
    let tempAddition = addition;
    tempAddition.tips = tempAddition.tips + tips;
    setAddition(tempAddition);
    addDemande(atob(table), tips, checked, id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        promptForAddition();
        console.log(res.data.data);
      }
    });
    forceUpdate();
  };

  return (
    <section className="menu4" style={{ overflowX: "hidden" }}>
      <div className="row lang-switcher">
        <div className="col-6" style={{ textAlign: "left" }}>
          <button
            onClick={toggleDrawer(true)}
            className="btn btn-rounded"
            style={{
              backgroundImage: `url(${Cart})`,
            }}
          >
            <i class="fa-solid fa-cart-shopping"></i>
          </button>
        </div>
        <div className="col-6" style={{ textAlign: "right" }}>
          <div className="btn-group">
            <button
              className="btn btn-rounded dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              style={{ backgroundColor: "white", display: "flex" }}
            >
              <div className="btn-text">
                {lang === "Fr" ? (
                  <>
                    <img
                      src={fr}
                      style={{ width: "30px", marginRight: "10px" }}
                    />
                    Fr
                  </>
                ) : lang === "En" ? (
                  <>
                    <img
                      src={eng}
                      style={{ width: "30px", marginRight: "10px" }}
                    />
                    En
                  </>
                ) : lang === "Ar" ? (
                  <>
                    <img
                      src={ar}
                      style={{ width: "30px", marginRight: "10px" }}
                    />
                    Ar
                  </>
                ) : null}
              </div>
            </button>
            <div
              className="dropdown-menu dropdown-menu-right"
              style={{ backgroundColor: "white" }}
            >
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => {
                  setLang("Fr");
                  changeLanguage("fr");
                }}
              >
                <img src={fr} style={{ width: "30px", marginRight: "10px" }} />
                Fr
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => {
                  setLang("En");
                  changeLanguage("en");
                }}
              >
                <img src={eng} style={{ width: "30px", marginRight: "10px" }} />
                En
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => {
                  setLang("Ar");
                  changeLanguage("ar");
                }}
              >
                <img src={ar} style={{ width: "30px", marginRight: "10px" }} />
                Ar
              </a>
            </div>
          </div>
        </div>
      </div>
      <ul className="ui-list mt-5">
        {categories.map((val, index) => {
          const colorIndex = index % colorsArray.length;
          return (
            <li
              className={"ui-item " + colorsArray[colorIndex]}
              onClick={(e) => {
                setIndex(index);
                setColor(colorsArray[colorIndex]);
                handleClickOpen();
              }}
            >
              <i className="fa-solid fa-bowl-food food-icon"></i>
              <p>{translation(val.libelle)}</p>
              <i className="fa fa-angle-right"></i>
            </li>
          );
        })}
      </ul>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className="custom-app-bar" sx={{ position: "relative" }}>
          <Toolbar className={color}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <span onClick={handleClose}>
                <i className="fa-solid fa-chevron-left"></i>
              </span>
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Menus
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          <section className="content">
            <div className="row fx-element-overlay">
              {categoryListing &&
                categoryListing[index] &&
                categoryListing[index].map((val2, index2) => {
                  return (
                    <div className="col-12 col-lg-6 col-xl-4">
                      <div className="box box-default">
                        <div className="fx-card-item">
                          <div className="fx-card-avatar fx-overlay-1">
                            {" "}
                            {personalisationMenu &&
                            personalisationMenu.productimage &&
                            val2.image.length ? (
                              <img
                                src={
                                  val2.image.length
                                    ? process.env.REACT_APP_API_BASE_URL +
                                      val2.image
                                    : ""
                                }
                                alt="img"
                              />
                            ) : null}
                            <div className="fx-overlay scrl-up">
                              <ul className="fx-info">
                                <li>
                                  <button
                                    className="waves-effect waves-circle btn btn-circle btn-warning btn-xs m-5"
                                    onClick={handleDecrement}
                                  >
                                    <i class="fa-solid fa-minus"></i>
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="btn btn-outline"
                                    onClick={(e) => addProductToCart(val2)}
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {/* <i class="fa-solid fa-cart-shopping"></i> */}
                                    <span>{quantity}</span>
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="waves-effect waves-circle btn btn-circle btn-warning btn-xs m-5"
                                    onClick={handleIncrement}
                                  >
                                    <i class="fa-solid fa-plus"></i>
                                  </button>
                                </li>{" "}
                                <br></br>
                                <li>
                                  <button
                                    className="waves-effect waves-light btn btn-rounded btn-warning mt-5"
                                    onClick={(e) => {
                                      addProductToCart(val2);
                                      setQuantity(1);
                                    }}
                                  >
                                    Ajouter
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="fx-card-content text-left mb-0">
                            <div className="product-text">
                              <h2 className="pro-price text-blue">
                                {" "}
                                {getPrice(val2, happyHours)}
                              </h2>
                              <h4
                                className="box-title mb-0"
                                style={{ fontSize: "24px" }}
                              >
                                {" "}
                                {translation(val2.titre).length > 15
                                  ? translation(val2.titre).substring(0, 15) +
                                    " ..."
                                  : translation(val2.titre)}{" "}
                              </h4>
                              <small
                                className="text-muted db"
                                style={{ fontSize: "20px" }}
                              >
                                {val2 &&
                                translation(val2.description) !== "null" ? (
                                  <>
                                    {" "}
                                    {translation(val2.description).length > 30
                                      ? translation(val2.description).substring(
                                          0,
                                          30
                                        ) + " ..."
                                      : translation(val2.description)}{" "}
                                  </>
                                ) : null}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </section>
        </List>
      </Dialog>

      <SwipeableDrawer
        container={container}
        anchor="left"
        open={open2}
        onClose={toggleDrawer2(false)}
        onOpen={toggleDrawer2(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={true}
        // onScroll={(event) => {
        //   console.log(event.target.scrollTop);
        // }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "8px",
            overflowY: "auto",
          }}
        >
          <IconButton onClick={toggleDrawer2(false)} sx={{ float: "right" }}>
            <i className="fa-solid fa-xmark"></i>
          </IconButton>
        </div>
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        ></StyledBox>
        <StyledBox
          sx={{
            bottom: 0,
            height: "100%",
            margin: "0px 40px",
            overflowY: "auto",
          }}
        >
          <div className="box-body" style={{ padding: "0px" }}>
            <div
              className="entete-addition"
              style={{ justifyContent: "center", display: "flex" }}
            >
              {/* <div
                    className="avatar avatar-lg "
                    style={{ marginRight: "5px" }}
                  >
                    <img
                      className="rounded img-fluid"
                      src={
                        org.logo
                          ? process.env.REACT_APP_API_BASE_URL + org.logo
                          : NoImage
                      }
                    />
                  </div> */}
              <h4 style={{ color: "#5e5e5e" }}>{org && org.nom}</h4>
            </div>
            <div
              className="table-responsive-xl"
              style={{ overflowX: "hidden" }}
            >
              <table className="table product-overview">
                <thead>
                  <tr>
                    <th>{t("Designation")}</th>

                    <th>{t("prix")}</th>
                  </tr>
                </thead>
                <tbody>
                  {addition.prix > 0 ? (
                    addition.produits &&
                    addition.produits.map((value, index) => (
                      <tr>
                        <td>
                          {value.data.length}{" "}
                          {value.key.length > 10
                            ? value.key.substring(0, 10) + " ..."
                            : value.key}
                        </td>

                        <td>
                          {" "}
                          {getPrice(value.data[0], happyHours) *
                            value.data.length}
                          {currency}{" "}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4">Vous n'avez aucune commande.</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="row">
                <div className="col-12 text-right">
                  <p className="lead">
                    <b style={{ color: "#b5b5c3" }}>{t("Date")}</b>
                    <span className="text-danger">
                      {" "}
                      {new Date().toLocaleString("en-US", {
                        timeZone: "Africa/Tunis",
                      })}
                    </span>
                  </p>

                  {/* <div>
                        <p>Sub - Total amount : $3,592.00</p>
                        <p>Tax (18%) : $646.56</p>
                        <p>Shipping : $110.44</p>
                      </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-right">
                  <div>
                    <p>
                      {t("Prix Total")} :{" "}
                      {addition.prix.toFixed(chiffreApresVirg)} {currency}{" "}
                    </p>
                    <p>
                      {t("Tips")} : {addition.tips.toFixed(chiffreApresVirg)}{" "}
                      {currency}{" "}
                    </p>
                  </div>
                  <div className="total-payment">
                    <h3 style={{ color: "black" }}>
                      <b>Total :</b>{" "}
                      {(addition.tips + addition.prix).toFixed(
                        chiffreApresVirg
                      )}{" "}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ margin: "0px 10px" }}>
            <List sx={{ width: "100%", maxWidth: 360 }}>
              <h6 style={{ color: "black" }}>
                {t("Choisir une methode de paiement")}
              </h6>
              {paymentMethods.map((value) => {
                const labelId = `checkbox-list-label-${value}`;
                return (
                  <ListItem key={value} disablePadding>
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(value)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={value === checked}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={value} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            <TipsPopUp confirm={callWaiter} onChange={handleTipChange} />
          </div>
          <div
            className="row"
            style={{ justifyContent: "center", marginTop: "40px" }}
          >
            {t("Bon appétit et à bientôt !")}
          </div>
        </StyledBox>
      </SwipeableDrawer>

      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={openCart}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            height: "calc(50%)",
            bottom: 0,
          },
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        ></StyledBox>
        <StyledBox
          sx={{
            bottom: 0,
            height: "100%",
            overflow: "auto",
          }}
        >
          <div
            className="box"
            style={{
              float: "none",
              clear: "both",
              marginBottom: "0",
              borderRadius: "0",
              backgroundColor: "white",
              height: "100%",
              boxShadow: "none",
            }}
          >
            <div className="box-body p-10">
              <div className="table-responsive-xl" style={{ height: "100%" }}>
                <table className="table product-overview">
                  <thead>
                    <table
                      className="table product-overview"
                      style={{
                        background: "white",
                        borderBottom: "1px solid gray",
                        left: "0",
                      }}
                    >
                      <thead>
                        <tr>
                          <th colSpan="2">{t("Designation")}</th>
                          <th>{t("prix")}</th>
                        </tr>
                      </thead>
                    </table>
                  </thead>
                  <tbody>
                    {/* <tr style={{ opacity: 0 }}>
                            <th>Designation</th>

                            <th>{t("prix")}</th>
                            <th style={{ textAlign: "center" }}></th>
                          </tr> */}
                    {cart && cart.prix > 0 ? (
                      cartProducts &&
                      cartProducts.map((value, index) => (
                        <tr>
                          <td>
                            {value.data.length} {"  "}
                            {value.key.length > 40
                              ? value.key.substring(0, 40) + " ..."
                              : value.key}
                          </td>

                          <td>
                            {" "}
                            {getPrice(value.data[0], happyHours) *
                              value.data.length}{" "}
                          </td>

                          <td align="center">
                            <button
                              className="btn btn-circle btn-primary btn-xs"
                              title=""
                              onClick={(e) =>
                                removeProductFromCart(value, index)
                              }
                            >
                              <i className="fa-solid fa-xmark"></i>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4">
                          {t("Vous n'avez aucun article dans votre panier.")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div
                  className="table-footer"
                  style={{
                    bottom: "0px",
                    position: "absolute",
                    width: "95%",
                    position: "unset",
                  }}
                >
                  {cart && cart.prix > 0 ? (
                    <>
                      <div className="row m-0">
                        <div className="col-12 text-right">
                          <div className="total-payment">
                            <h3 style={{ color: "black" }}>
                              <b>{t("Total")} :</b>{" "}
                              {cart.prix.toFixed(chiffreApresVirg)}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ display: "contents" }}>
                        <BuyProductPopUp
                          confirm={(e) => addSuggestionAndConfirm()}
                          decline={(e) => CartAdd(0)}
                          product={suggestedProduct}
                          category={suggestedCategory}
                          suggestionMessage={suggestion}
                          cartProducts={cartProducts}
                          addProductToCart={addProductToCart}
                          toggleDrawer={toggleDrawer}
                          orgId={id}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* <button onClick={(e)=> console.log(cartProducts)}>click</button> */}
                </div>
              </div>
            </div>
          </div>
        </StyledBox>
      </SwipeableDrawer>
      <footer className="footer mt-0" style={{ textAlign: "center" }}>
        <div className="bon-appetit">
          <h2>{t("bon appetit")}</h2>
        </div>
        {personalisationMenu && personalisationMenu.options ? (
          <div className="row" style={{ justifyContent: "center" }}>
            <button
              type="button"
              className="waves-effect waves-light btn btn-rounded btn-warning mb-5"
              onClick={(e) => {
                setOpen2(true);
              }}
            >
              <span className="btn-addition-text">{t("Addition")}</span>
            </button>
          </div>
        ) : (
          <></>
        )}
        <form onSubmit={onSubmit}>
          <div className="mx-auto mt-20">
            <h3 className="text-uppercase text-white">{t("Votre avis")}</h3>
            <div>
              <Rating
                onClick={handleRating}
                initialValue={1}
                ratingValue={star}
                size={20}
                label
                transition
                fillColor="orange"
                emptyColor="gray"
                className="foo"
                value={star}
                onChange={(e) => setStar(e.currentTarget.value)}
              />
            </div>
            <div className="input-group">
              <input
                type="text"
                className="form-control p-10"
                placeholder={t("Pensez-vous......")}
                value={comment}
                onChange={(e) => setComment(e.currentTarget.value)}
                style={{ backgroundColor: "white" }}
              />
              <div className="input-group-append">
                <button type="submit" className="btn btn-success">
                  {t("envoyer")}
                </button>
              </div>
            </div>
          </div>
        </form>

        <div className="social-media mt-10" style={{ justifySelf: "center" }}>
          {" "}
          <nav>
            <Link
              onClick={(e) => (window.location.href = socials.facebook)}
              className="waves-effect waves-circle btn btn-circle btn-xs m-5"
            >
              <img src={Facebook} />
            </Link>
            <Link
              onClick={(e) => (window.location.href = socials.instagram)}
              className="waves-effect waves-circle btn btn-circle btn-xs m-5"
            >
              <img src={Instagram} />{" "}
            </Link>
            <Link
              onClick={(e) => (window.location.href = socials.youtube)}
              className="waves-effect waves-circle btn btn-circle btn-xs m-5"
            >
              <img src={Youtube} />{" "}
            </Link>
            <Link
              onClick={(e) => (window.location.href = socials.website)}
              className="waves-effect waves-circle btn btn-circle btn-xs m-5"
            >
              <img src={Website} />{" "}
            </Link>
          </nav>
        </div>
        <div
          className="paiement-mode"
          style={{
            borderRadius: "10px",
            padding: "5px 5px 5px 10px",
            justifyContent: "center",
          }}
        >
          <div className="row" style={{ justifyContent: "center" }}>
            {paymentMethods &&
              paymentMethods.map((value) => (
                <div className="col-3" style={{ justifyContent: "center" }}>
                  <p>
                    <div
                      className="waves-effect waves-circle btn btn-circle btn-xs m-5"
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        width: "50px",
                        height: "50px",
                        fontSize: "24px",
                        display: "grid",
                        alignItems: "center",
                      }}
                    >
                      {value === "Cash" ? (
                        <i className="fa-regular fa-money-bill-1"></i>
                      ) : value === "Tickets Restau" ? (
                        <i className="fa-solid fa-ticket"></i>
                      ) : value === "Credit Card" ? (
                        <i className="fa-brands fa-cc-mastercard"></i>
                      ) : (
                        <i className="fa-solid fa-money-check-dollar"></i>
                      )}
                    </div>
                  </p>
                </div>
              ))}
          </div>
        </div>
        <div className="copyright">
          <p>© 2023 SCANINY. {t("Tous droits réservés.")}</p>
        </div>
      </footer>
    </section>
  );
}
export default Menu4;
