import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import TemplateCleaner from "./TemplateCleaner";
import { getOrganisationById } from "../../Services/OrganizationApi";
import { addCart, getCartById } from "../../Services/CartApi";
import GifLoader from "../../Components/loader";
import { getPopup } from "../../Services/PopupApi";
import emptyCart from "../MenusStyle/empty-cart.png";
import greenTick from "../MenusStyle/green-tick.png";
import { getActiveHappyHours } from "../../Services/HappyHourApi";
import fr from "../MenusStyle/france.png";
import eng from "../MenusStyle/england.png";
import ar from "../MenusStyle/tun.png";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // A function that increment ðŸ‘†ðŸ» the previous state like here
  // is better than directly setting setValue(value + 1)
}

function Cart() {
  const { id, table } = useParams();
  const savedCart = JSON.parse(localStorage.getItem("cart"));
  const [cart, setCart] = useState();
  const [org, setOrg] = useState();
  const [currency, setCurrency] = useState();
  const [products, setProducts] = useState([]);
  const [productArray, setProductArray] = useState();
  const [loading, setLoading] = useState(true);
  const [cartProducts, setCartProducts] = useState([]);
  const [suggestedProduct, setSuggestedProduct] = useState();
  const [suggestedCategory, setSuggestedCategory] = useState();
  const [suggestion, setSuggestion] = useState();
  const [status, setStatus] = useState(emptyCart);
  const [happyHours, setHappyHours] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(
    localStorage.getItem("selectedLanguage") || "fr"
  );
  const [layout, setLayout] = useState("unset");

  const handleLanguageChange = (selectedLanguage) => {
    setLang(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);

    // Use the changeLanguage function from i18next
    changeLanguage(selectedLanguage);

    // You can also trigger any language-specific layout changes here if needed.
  };

  const translation = (titre) => {
    if (lang === "fr") {
      return titre.fr;
    } else if (lang === "en") {
      return titre.en;
    } else return titre.ar;
  };
  const currencyTranslation = (currency) => {
    if (lang === "fr" && currency === "TND") {
      return "TND";
    } else if (lang === "en" && currency === "TND") {
      return "TND";
    } else return " دت";
  };

  const [addition, setAddition] = useState({
    produits: [],
    prix: 0,
    tips: 0,
  });

  useEffect(() => {
    if (localStorage.getItem("addition")) {
      setAddition(JSON.parse(localStorage.getItem("addition")));
    } else {
      localStorage.setItem("addition", JSON.stringify(addition));
    }
  }, []);

  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();

  const goBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    setTimeout(() => {
      var onPageSetupDoneEvent = new Event("onPageSetupDone");
      document.dispatchEvent(onPageSetupDoneEvent);
    }, 2000);
    changeLanguage(localStorage.getItem("selectedLanguage"));
    if (localStorage.getItem("selectedLanguage") === "ar") {
      setLayout("rtl");
    }
    console.log(localStorage.getItem(addition));
    console.log(savedCart);
    getOrganisationById(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
        setCurrency(res.data.data.currency);
        console.log(res.data.data);
        setLoading(false);
      }
    });
    getPopup(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setSuggestedProduct(res.data.data.menu_id);
        setSuggestedCategory(res.data.data.category_id);
        setSuggestion(res.data.data.suggestion);
      }
    });
    getActiveHappyHours(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setHappyHours(res.data.data);
        console.log(res.data.data);
      }
    });
  }, []);

  function getPrice(product, happyHours) {
    const happyHourItem = happyHours.find(
      (item) => item.menu_id._id === product._id
    );

    if (happyHourItem) {
      return product.prix - happyHourItem.reduction;
    } else {
      return product.prix;
    }
  }
  function removeDuplicates(productArray) {
    console.log("array before transform", productArray);

    const uniqueProducts = [];
    const uniqueIds = new Set();

    for (const product of productArray) {
      if (!uniqueIds.has(product.ingredient.titre)) {
        uniqueIds.add(product.ingredient.titre);
        uniqueProducts.push(product);
      }
    }
    console.log("array after transform", uniqueProducts);

    return uniqueProducts;
  }

  function ingredientsPrice(array) {
    return array.reduce((total, product) => total + product.prix, 0);
  }
  const deepArrayCompare = (array1, array2) => {
    if (array1.length !== array2.length) return false;

    for (let i = 0; i < array1.length; i++) {
      if (JSON.stringify(array1[i]) !== JSON.stringify(array2[i])) {
        return false;
      }
    }

    return true;
  };
  function newArray(produits) {
    console.log(produits);
    const result = produits.reduce((acc, curr) => {
      const key = `${curr.products.titre.fr}`;
      const prix = `${curr.products.prix}`;
      const ingredientsArray = curr.ingredients.map((ingredient) => ({
        ingredient,
      }));
      const prixIngredients = ingredientsArray.reduce(
        (total, product) => total + product.ingredient.prix,
        0
      );
      const existingItem = acc.find(
        (item) =>
          item.key === key &&
          deepArrayCompare(item.ingredients, ingredientsArray)
      );
      if (existingItem) {
        existingItem.data.push(curr.products);
      } else {
        acc.push({
          key,
          data: [curr],
          prix,
          ingredients: ingredientsArray,
          prixTotal: parseInt(prix) + parseInt(prixIngredients),
        });
      }
      return acc;
    }, []);

    return result;
  }

  const addProductToCartPopup = (product) => {
    console.log("cart", savedCart);
    console.log("product to add", product);

    let tempCart = { ...savedCart }; // Create a copy of the cart object

    let suggestedProduct = {
      products: product,
      ingredients: [],
    };

    tempCart.produits.push(suggestedProduct);
    console.log(suggestedProduct);

    // const result = tempCart.produits.reduce((acc, curr) => {
    //   const key = `${curr.titre.fr}`;
    //   const prix = `${curr.prix}`;
    //   const existingItem = acc.find((item) => item.key === key);

    //   if (existingItem) {
    //     existingItem.data.push(curr);
    //   } else {
    //     acc.push({ key, data: [curr], prix });
    //   }

    //   return acc;
    // }, []);

    tempCart.prix += getPrice(product, happyHours);
    setCart(tempCart);

    forceUpdate();

    localStorage.setItem("cart", JSON.stringify(tempCart));
    // console.log("products : ", tempCart.produits);
    // console.log("cart : ", tempCart);
  };

  function addProductToCart(product) {
    // Create a new item for the cart
    let cart = { ...savedCart };
    const prixIngredients = product.ingredients.reduce(
      (total, ingredient) => total + ingredient.prix,
      0
    );

    // Add the new item to the cart's produits array
    cart.produits.push(product);

    // Update the total price in the cart
    cart.prix +=
      parseInt(getPrice(product.products, happyHours)) + prixIngredients;

    forceUpdate();
    localStorage.setItem("cart", JSON.stringify(cart));
    console.log("new cart", cart);
  }

  function RemoveProductFromCart(productId, ingredients) {
    console.log(productId);
    let cart = { ...savedCart };
    const indexToRemove = cart.produits.findIndex(
      (item) =>
        item.products._id === productId &&
        JSON.stringify(item.ingredients) === JSON.stringify(ingredients)
    );
    console.log("verify", indexToRemove);

    if (indexToRemove !== -1) {
      // Remove the item from the produits array
      const prixIngredients = ingredients.reduce(
        (total, ingredient) => total + ingredient.prix,
        0
      );
      cart.prix -= cart.produits[indexToRemove].products.prix + prixIngredients;
      cart.produits.splice(indexToRemove, 1);
      forceUpdate();
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }

  // les suggestions :

  function isProductExist() {
    let suggestedProductExists = false;
    for (let i = 0; i < savedCart.produits.length; i++) {
      if (savedCart.produits[i].products._id === suggestedProduct._id) {
        suggestedProductExists = true;
        break;
      }
    }

    return suggestedProductExists;
  }

  function isCategoryExist() {
    let suggestedCategoryExists = false;

    for (let i = 0; i < savedCart.produits.length; i++) {
      if (savedCart.produits[i].category_id === suggestedCategory._id) {
        suggestedCategoryExists = true;
        break;
      }
    }

    return suggestedCategoryExists;
  }

  const CartAdd = async () => {
    let tempCart = {
      produits: savedCart.produits.map((item) => ({
        products: item.products._id,
        ingredients: item.ingredients.map((ingredient) => ingredient.id),
      })),
      // Copy other properties from the original cart
      table: savedCart.table,
      prix: savedCart.prix,
      status: savedCart.status,
      takeAway: isChecked,
    };
    console.log(tempCart);
    addCart(tempCart, id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        // let AdditionProducts = addition.produits;
        // AdditionProducts = savedCart.produits;
        // console.log(AdditionProducts);

        const newAddition = {
          produits: addition.produits.concat(savedCart.produits),
          prix: addition.prix + savedCart.prix,
          tips: 0,
        };

        setAddition(newAddition);

        setStatus(greenTick);
        // console.log("Before saving to localStorage:", addition);

        localStorage.setItem("addition", JSON.stringify(newAddition));

        // console.log("After saving to localStorage:", addition);

        localStorage.removeItem("cart");

        forceUpdate();
      }
    });
    return false;
  };

  return (
    <>
      <GifLoader loading={loading} />
      <TemplateCleaner />

      <div className="bg-white shadow-sm p-3 sticky-top">
        <div className="d-flex align-items-center">
          <div className="gap-3 d-flex align-items-center">
            <a onClick={goBack}>
              <i className="bi bi-arrow-left d-flex text-danger h2 m-0 back-page"></i>
            </a>
            <h3 className="fw-bold m-0">{t("Cart")}</h3>
          </div>
          <div className="ms-auto gap-3 d-flex align-items-center">
            <div className="btn-group">
              <button
                className="btn btn-rounded dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                style={{
                  backgroundColor: "white",
                  display: "flex",
                  padding: "0",
                }}
              >
                <div className="btn-text">
                  {lang === "fr" ? (
                    <>
                      <img
                        src={fr}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      Fr
                    </>
                  ) : lang === "en" ? (
                    <>
                      <img
                        src={eng}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      En
                    </>
                  ) : lang === "ar" ? (
                    <>
                      <img
                        src={ar}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      Ar
                    </>
                  ) : null}
                </div>
              </button>
              <div
                className="dropdown-menu dropdown-menu-right"
                style={{ backgroundColor: "white" }}
              >
                <a
                  className="dropdown-item"
                  onClick={() => {
                    handleLanguageChange("fr");
                    setLayout("unset");
                  }}
                >
                  <img
                    src={fr}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  Fr
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    handleLanguageChange("en");
                    setLayout("unset");
                  }}
                >
                  <img
                    src={eng}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  En
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    handleLanguageChange("ar");
                    setLayout("rtl");
                  }}
                >
                  <img
                    src={ar}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  Ar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {savedCart && savedCart.prix > 0 ? (
        <>
          {" "}
          {/* body */}
          <div
            className="bg-white p-3 mb-3 shadow-sm"
            style={{ direction: layout }}
          >
            <div>
              {/* 1st */}
              {savedCart.produits &&
                newArray(savedCart.produits).map((value, index) => {
                  const produit = value.data[0].products;
                  const ingredients = value.data[0].ingredients;
                  return (
                    <div className="mb-3 d-flex gap-2">
                      {/* <button onClick={(e) => console.log(produit)}></button> */}
                      <div>
                        <h6 className="fw-bold mb-1">
                          {" "}
                          {translation(produit.titre)}{" "}
                        </h6>
                        {ingredients &&
                          ingredients.map((val, index) => <p>+{val.titre}</p>)}
                        <p className="mb-0">
                          {" "}
                          {getPrice(produit, happyHours) !== produit.prix ? (
                            <div
                              style={{
                                fontWeight: "500",
                                fontSize: "14px",
                              }}
                            >
                              <span className="text-danger">
                                <del> {value.prix} </del>
                              </span>{" "}
                              {getPrice(produit, happyHours)}{" "}
                              {currencyTranslation(currency)}
                            </div>
                          ) : (
                            <h6 className="fw-bold">
                              {" "}
                              {getPrice(produit, happyHours)}{" "}
                              {currencyTranslation(currency)}{" "}
                            </h6>
                          )}{" "}
                        </p>
                      </div>
                      <div
                        className={
                          layout === "unset"
                            ? "ms-auto text-end cw-80"
                            : "text-end cw-80"
                        }
                        style={{
                          marginRight: layout === "unset" ? "unset" : "auto",
                        }}
                      >
                        <div className="btn btn-white btn-sm border border-danger px-2 rounded">
                          <div className="d-flex align-items-center justify-content-between gap-2">
                            <div
                              className="minus"
                              onClick={(e) => {
                                RemoveProductFromCart(
                                  value.data[0].products._id,
                                  ingredients
                                );
                                console.log(savedCart);
                              }}
                            >
                              <i className="fa-solid fa-minus text-danger"></i>
                            </div>
                            <input
                              className="shadow-none form-control text-center border-0 p-0 box"
                              type="text"
                              placeholder=""
                              aria-label="default input example"
                              value={value.data.length}
                            />
                            <div
                              className="plus"
                              onClick={(e) => {
                                addProductToCart(value.data[0]);
                                // console.log(savedCart);
                              }}
                            >
                              <i className="fa-solid fa-plus text-danger"></i>
                            </div>
                          </div>
                        </div>
                        <h6
                          className="fw-bold"
                          style={{ textAlign: "center", direction: layout }}
                        >
                          {(getPrice(produit, happyHours) +
                            ingredientsPrice(ingredients)) *
                            value.data.length}{" "}
                          {currencyTranslation(currency)}
                        </h6>
                      </div>
                    </div>
                  );
                })}
              {/* Add more items */}
              <div>
                <Link
                  to={"/menu5/" + id + "/" + table}
                  className="text-decoration-none text-danger"
                >
                  <i className="fa-solid fa-plus"></i>&nbsp;
                  {t("Ajouter des articles")}
                </Link>
              </div>
            </div>
            <hr />
            <div className="row">
              <div
                className="col-12"
                style={{
                  alignItems: "center",
                  justifyContent: "right",
                  display: "flex",
                }}
              >
                {" "}
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <span className="slider round"></span>
                </label>
                <h3 className="mb-0">{t("Take away")} </h3>
              </div>
            </div>
          </div>
          {/* Total price */}
          <div className="bg-white p-3 mb-3 shadow-sm">
            <div className="d-flex justify-content-between h4 fw-bold">
              <div>{t("Total")}</div>
              <div>
                {savedCart.prix} {currencyTranslation(currency)}
              </div>
            </div>
          </div>
          <div className="pt-5 pb-4"></div>
          {/* Fixed bottom */}
          <div className="fixed-bottom p-3">
            {(!suggestion && !suggestedCategory && !suggestedProduct) ||
            (suggestedProduct && isProductExist()) ||
            (suggestedCategory && isCategoryExist()) ? (
              <>
                {" "}
                <button
                  className="btn btn-danger btn-lg d-grid"
                  style={{ width: "100%" }}
                  onClick={(e) => CartAdd()}
                >
                  <div className="d-flex justify-content-between">
                    <div></div>
                    <div>Confirmer</div>
                    <div>
                      <i className="fa-solid fa-caret-right"></i>
                    </div>
                  </div>
                </button>{" "}
              </>
            ) : suggestedProduct && !isProductExist() ? (
              <button
                className="btn btn-danger btn-lg d-grid"
                style={{ width: "100%" }}
                data-bs-toggle="modal"
                data-bs-target="#suggestionModal"
              >
                <div className="d-flex justify-content-between">
                  <div></div>
                  <div>Confirmer</div>
                  <div>
                    <i className="fa-solid fa-caret-right"></i>
                  </div>
                </div>
              </button>
            ) : suggestedCategory && !isCategoryExist() ? (
              <button
                className="btn btn-danger btn-lg d-grid"
                style={{ width: "100%" }}
                data-bs-toggle="modal"
                data-bs-target="#suggestionModal"
              >
                <div className="d-flex justify-content-between">
                  <div></div>
                  <div>Confirmer</div>
                  <div>
                    <i className="fa-solid fa-caret-right"></i>
                  </div>
                </div>
              </button>
            ) : suggestion ? (
              <button
                className="btn btn-danger btn-lg d-grid"
                style={{ width: "100%" }}
                data-bs-toggle="modal"
                data-bs-target="#suggestionModal"
              >
                <div className="d-flex justify-content-between">
                  <div></div>
                  <div>Confirmer</div>
                  <div>
                    <i className="fa-solid fa-caret-right"></i>
                  </div>
                </div>
              </button>
            ) : null}
          </div>
        </>
      ) : (
        <>
          <div className="my-5">
            <div className="text-center">
              <div className="mb-3 px-5">
                <img src={status} className="img-fluid px-5" alt="#" />
              </div>
              <div className="h1 text-success mb-1">
                {status === emptyCart
                  ? t("Vous n'avez aucun article dans votre panier.")
                  : t("Commande confirmée !")}
              </div>
            </div>
          </div>
          {/* buttons */}
          <div className="d-grid fixed-bottom gap-2 p-3">
            <Link
              to={"/menu5/" + id + "/" + table}
              className="btn btn-outline-danger btn-lg"
            >
              {status === emptyCart ? t("Passer une commande") : t("Ok")}
            </Link>
          </div>
        </>
      )}

      <div
        className="modal fade"
        id="suggestionModal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <h5 className="modal-title"> </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            {suggestedProduct && suggestedProduct ? (
              <>
                <div className="modal-body p-0">
                  <div className="row justify-content-between">
                    <div className="col-4">
                      <div className="card border-0">
                        <img
                          src={
                            suggestedProduct.image.length
                              ? process.env.REACT_APP_API_BASE_URL +
                                suggestedProduct.image
                              : ""
                          }
                          className="card-img-top rounded-3"
                          alt="..."
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <h3>
                        {t("Do you want to order")}{" "}
                        {translation(suggestedProduct.titre)} ?
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="modal-footer d-flex justify-content-between">
                  <button
                    className="btn btn-danger col-3"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => CartAdd()}
                  >
                    {t("Non")}
                  </button>
                  <button
                    className="btn btn-danger col-3"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => {
                      addProductToCartPopup(suggestedProduct);
                      // CartAdd();
                    }}
                  >
                    {t("Oui")}
                  </button>
                </div>
              </>
            ) : null}
            {suggestedCategory && suggestedCategory ? (
              <>
                <div className="modal-body p-0">
                  <div className="row justify-content-between">
                    <div className="col-4">
                      <div className="card border-0">
                        <img
                          src={
                            suggestedCategory && suggestedCategory.logo
                              ? process.env.REACT_APP_API_BASE_URL +
                                suggestedCategory.logo
                              : ""
                          }
                          className="card-img-top rounded-3"
                          alt="..."
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <h3>
                        {t("Do you want to order")}{" "}
                        {translation(suggestedCategory.libelle)} ?
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="modal-footer d-flex justify-content-between">
                  <button
                    className="btn btn-danger col-3"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => CartAdd()}
                  >
                    {t("Non")}
                  </button>
                  {/* <Link to={"/menu5/" + id + "/" + table}> */}
                  <button
                    className="btn btn-danger col-3"
                    onClick={(e) =>
                      (window.location.href =
                        "/menu5/" +
                        id +
                        "/" +
                        table +
                        "/" +
                        suggestedCategory._id)
                    }
                    // style={{ width: "100%" }}
                  >
                    {t("Oui")}
                  </button>
                  {/* </Link> */}
                </div>
              </>
            ) : null}

            {suggestion && suggestion ? (
              <>
                <div className="modal-body p-0">
                  <div className="row justify-content-between">
                    <div className="col-8">
                      <h3>{suggestion}</h3>
                    </div>
                  </div>
                </div>
                <div className="modal-footer d-flex justify-content-between">
                  <button
                    className="btn btn-danger col-3"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => {
                      CartAdd();
                      if (addition && addition.prix > 0) {
                        localStorage.setItem(
                          "addition",
                          JSON.stringify(addition)
                        );
                      }
                    }}
                  >
                    {t("Ok")}
                  </button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
export default Cart;
