import React from "react";
import Aside from "../Components/Shared/Aside";
import ChatBox from "../Components/Shared/ChatBox";
import Footer from "../Components/Shared/Footer";
import Header from "../Components/Shared/Header";
import RightBar from "../Components/Shared/RightBar";
import SideBar from "../Components/Shared/SideBar";
import { ToastContainer } from "react-toastify";

function DashboardLayout(props) {
  return (
    <div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <div className="wrapper">
        {/*  <div id="loader" /> */}
        <Header></Header>
        <aside className="main-sidebar">
          {/* sidebar*/}
          <SideBar></SideBar>
        </aside>
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <React.Fragment>{props.children}</React.Fragment>
            </section>
            {/* /.content */}
          </div>
        </div>
        {/* /.content-wrapper */}
        {/* Content Right Sidebar */}
        <RightBar></RightBar>
        {/* /.Content Right Sidebar */}
        <Footer></Footer>
        {/* Control Sidebar */}
        <Aside></Aside>
        {/* /.control-sidebar */}
        {/* Add the sidebar's background. This div must be placed immediately after the control sidebar */}
        <div className="control-sidebar-bg" />
      </div>
      {/* ./wrapper */}
    </div>
  );
}

export default DashboardLayout;
