import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useUser } from "../Auth/useUser";

import { useOrganisations } from "./../Auth/useOrganisations";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GifLoader from "../Components/loader";
import { editWaiter, getWaiterById } from "../Services/WaiterApi";
import { useTranslation } from "react-i18next";

function EditWaiter() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  let id = useParams();
  const [nom, setNom] = useState();
  const [mobile, setMobile] = useState();
  const [email, setEmail] = useState();
  const [prenom, setPrenom] = useState();
  const [logo, setLogo] = useState();
  const [background, setBackground] = useState(1);
  const [menu, setMenu] = useState();
  const [wifi, setWifi] = useState();
  const [waiter, setWaiter] = useState();
  const [currency, setCurrency] = useState();
  const [color, setColor] = useState();
  const [message, setMessage] = useState();
  const user = useUser();
  const [infoUser] = useState(user.result);
  const paymentMethods = ["Cash", "Credit Card", "Tickets Restau", "Chéque"];
  const [checked, setChecked] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  const promptForDelete = () => {
    swal({
      title: "Pouf ! Votre organisation a été mise à jour",
      icon: "success",
    }).then(function () {
      window.location = "/";
    });
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    // Validate nom
    if (!nom) {
      toast.error("Veuillez entrer un nom pour votre entreprise!!");
      return false;
    }

    // Validate numero
    const numeroRegex = /^\d{8}$/;
    if (!numeroRegex.test(mobile)) {
      toast.error(
        "Veuillez entrer un numéro de téléphone valide avec exactement 8 chiffres!!"
      );
      return false;
    }
    // Validate currency

    editWaiter(nom, prenom, mobile, email).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        promptForDelete();
      }
    });
    return false;
  };
  useEffect(() => {
    getWaiterById(id.id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setWaiter(res.data.data[0]);
        setLoading(false);
      }
    });
  }, []);
  return (
    <section className="content">
      <GifLoader loading={loading} />
      <div className="row">
        <div className="col-12">
          <div className="box" style={{ padding: "15px" }}>
            <div className="box-body">
              <form onSubmit={onSubmit}>
                <div className="form-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend ">
                            <div className="input-group-addon">
                              <i className="fa fa-solid fa-user"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            className="form-control pl-15 bg-transparent"
                            placeholder="Nom"
                            defaultValue={waiter ? waiter.nom : ""}
                            onChange={(e) => setNom(e.currentTarget.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend ">
                            <div className="input-group-addon">
                              <i className="fa fa-solid fa-user"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            className="form-control pl-15 bg-transparent"
                            placeholder="Nom"
                            defaultValue={waiter ? waiter.prenom : ""}
                            onChange={(e) => setPrenom(e.currentTarget.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend ">
                            <div className="input-group-addon">
                              <i className="fa-solid fa-envelope"></i>
                            </div>
                          </div>
                          <input
                            type="email"
                            className="form-control pl-15 bg-transparent"
                            placeholder="Nom"
                            defaultValue={waiter ? waiter.email : ""}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend ">
                            <div className="input-group-addon">
                              <i className="fa-solid fa-phone"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            className="form-control pl-15 bg-transparent"
                            placeholder="Nom"
                            defaultValue={waiter ? waiter.mobile : ""}
                            onChange={(e) => setMobile(e.currentTarget.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-actions mt-10">
                  <Link to="/waiters">
                    <button type="button" className="btn btn-primary">
                      {t("Annuler")}
                    </button>
                  </Link>
                  <button type="submit" className="btn btn-danger left-button">
                    {" "}
                    <i className="fa fa-check"></i> {t("Enregistrer")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default EditWaiter;
