import React, { useState } from "react";
import "./MenusStyle/satisfaction.css";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getOrganisationById } from "../Services/OrganizationApi";
import { getSocials } from "../Services/SocialsApi";
import Facebook from "./MenusStyle/facebook.png";
import Instagram from "./MenusStyle/instagram.png";
import Youtube from "./MenusStyle/youtube.png";
import Website from "./MenusStyle/internet.png";
import { addComment } from "../Services/CommentsApi";
import swal from "sweetalert";
import fr from "./MenusStyle/france.png";
import eng from "./MenusStyle/england.png";
import ar from "./MenusStyle/tun.png";
import { changeLanguage } from "../i18n";
import { useTranslation } from "react-i18next";

const Satisfaction = () => {
  let { id } = useParams();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [org, setOrg] = useState();
  const [socials, setSocials] = useState();
  const [table, setTable] = useState(1);
  const [direction, setDirection] = useState("unset");

  const handleRatingChange = (event) => {
    const selectedRating = parseInt(event.target.value, 10);
    setRating(selectedRating);
  };

  const handleFeedbackChange = (event) => {
    const value = event.target.value;
    setFeedback(value);
  };

  const promptForDelete = () => {
    swal({
      title: "Nous avons bien reçu votre avis !",
      icon: "success",
    }).then(function () {
      // window.location = "/categories";
    });
  };
  useEffect(() => {
    getOrganisationById(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
      }
    });
    getSocials(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setSocials(res.data.data);
      }
    });
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    //@ToDo: Test all input errors here.
    addComment(feedback, rating, table, id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        promptForDelete();
      }
    });
    return false;
  };

  const [lang, setLang] = useState("Fr");
  return (
    <section className="satisfaction">
      <div
        className="col-6"
        style={{ textAlign: "right", alignSelf: "flex-end" }}
      >
        <div className="btn-group">
          <button
            className="btn btn-rounded dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            style={{ backgroundColor: "white", display: "flex" }}
          >
            <div className="btn-text">
              {lang === "Fr" ? (
                <>
                  <img
                    src={fr}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  Fr
                </>
              ) : lang === "En" ? (
                <>
                  <img
                    src={eng}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  En
                </>
              ) : lang === "Ar" ? (
                <>
                  <img
                    src={ar}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  Ar
                </>
              ) : null}
            </div>
          </button>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{ backgroundColor: "white" }}
          >
            <a
              className="dropdown-item"
              href="#"
              onClick={(e) => {
                setLang("Fr");
                changeLanguage("fr");
                setDirection("unset");
              }}
            >
              <img src={fr} style={{ width: "30px", marginRight: "10px" }} />
              Fr
            </a>
            <a
              className="dropdown-item"
              href="#"
              onClick={(e) => {
                setLang("En");
                changeLanguage("en");
                setDirection("unset");
              }}
            >
              <img src={eng} style={{ width: "30px", marginRight: "10px" }} />
              En
            </a>
            <a
              className="dropdown-item"
              href="#"
              onClick={(e) => {
                setLang("Ar");
                changeLanguage("ar");
                setDirection("rtl");
              }}
            >
              <img src={ar} style={{ width: "30px", marginRight: "10px" }} />
              Ar
            </a>
          </div>
        </div>
      </div>

      <div
        className="banner"
        style={{ justifyContent: "center", display: "flex", padding: "30px" }}
      >
        {org && org.logo ? (
          <img
            src={process.env.REACT_APP_API_BASE_URL + org.logo}
            width={200}
          />
        ) : (
          ""
        )}
      </div>

      <div className="rating-system" style={{ direction: direction }}>
        <h3 className="rating__title">{org && org.nom} </h3>
        {/* <button onClick={(e) => console.log(org)}></button> */}
        <div className="">
          <form
            className="rating-emojis"
            style={{ display: "block" }}
            onSubmit={onSubmit}
          >
            <h3 className="rating__title">{t("Comment ça etait ?")} </h3>
            <div className="rating__list" style={{ direction: "rtl" }}>
              {[1, 2, 3, 4, 5].map((value) => (
                <div className="rating__item" key={value}>
                  <input
                    className={`rating__input rating--${value}`}
                    id={`rating-${value}-2`}
                    type="radio"
                    value={value}
                    name="rating"
                    checked={rating === value}
                    onChange={handleRatingChange}
                  />
                  <label
                    className={`rating__label rating--${value}${
                      rating === value ? " active" : ""
                    }`}
                    htmlFor={`rating-${value}-2`}
                  >
                    <span style={{ fontSize: "30px" }}>
                      {value === 1 ? "😠" : null}
                      {value === 2 ? "😞" : null}
                      {value === 3 ? "😐" : null}
                      {value === 4 ? "😄" : null}
                      {value === 5 ? "🤩" : null}
                    </span>
                  </label>
                </div>
              ))}
            </div>
            <div
              className="feedback"
              style={{ display: rating !== null ? "block" : "none" }}
            >
              <textarea
                placeholder={t("Pensez-vous......")}
                value={feedback}
                onChange={handleFeedbackChange}
              ></textarea>
              <input
                type="Number"
                placeholder={t("Votre numero de telephone")}
                onChange={(e) => setTable(e.currentTarget.value)}
              />
              <button className="button.not-disabled" type="submit">
                {t("envoyer")}
              </button>
            </div>
          </form>
        </div>
        <footer
          className="footer mt-0"
          style={{
            justifyContent: "center",
            display: "grid",
            alignItems: "center",
          }}
        >
          <div className="social-media mt-10" style={{ justifySelf: "center" }}>
            <nav>
              <Link
                onClick={(e) => (window.location.href = socials.facebook)}
                className="waves-effect waves-circle btn btn-circle btn-xs m-5"
              >
                <img src={Facebook} />
              </Link>
              <Link
                onClick={(e) => (window.location.href = socials.instagram)}
                className="waves-effect waves-circle btn btn-circle btn-xs m-5"
              >
                <img src={Instagram} />{" "}
              </Link>
              <Link
                onClick={(e) => (window.location.href = socials.youtube)}
                className="waves-effect waves-circle btn btn-circle btn-xs m-5"
              >
                <img src={Youtube} />{" "}
              </Link>
              <Link
                onClick={(e) => (window.location.href = socials.website)}
                className="waves-effect waves-circle btn btn-circle btn-xs m-5"
              >
                <img src={Website} />{" "}
              </Link>
            </nav>
          </div>

          <div className="copyright">
            <p>© 2023 SCANINY. {t("Tous droits réservés.")}</p>
          </div>
        </footer>
      </div>
    </section>
  );
};

export default Satisfaction;
