import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../Auth/useUser";

/**
 * If the user is logged in, render the children, otherwise redirect to the login page
 * @returns If the user is logged in, the children are returned. If the user is not logged in, the user is redirected to the login page.
 */
const PrivateRoute = ({ children }) => {
  const user = useUser();
  console.log(user);
  return user && user.result.role === 'user' ? children : <Navigate to="/login" />;
};
export default PrivateRoute;
