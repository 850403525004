import axios from "axios";
import { getSession } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API;

/* Setting the header for the axios request. */
const config = {
    headers: { Authorization: `Bearer ${getSession("token")}` },
};

// export async function getPlans() {
//     try {
//       const response = await axios.get(api_url + "plans", config);
//       return response;
//     } catch (error) {
//       console.error(error);
//     }
// }
export async function getPlans() {

  try {
    const response = await axios.get(api_url + "plans/");
    return response;
  } catch (error) {
    console.error(error);
  }
}