import DashboardLayout from "./Layouts/DashboardLayout";
import NewMenu from "./Layouts/DashboardLayout";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import PrivateRoute from "./Routes/PrivateRoute";
import Main from "./Containers/Main";
import PublicRoute from "./Routes/PublicRoute";
import EmptyLayout from "./Layouts/EmptyLayout";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import Qr from "./Components/Qr";
import MenuFrom from "./Containers/AddMenu";
import MenuList from "./Containers/MenuList";
import CategoryList from "./Containers/CategoriesList";
import CategoryForm from "./Containers/AddCategory";
import EditMenu from "./Containers/EditMenu";
import "font-awesome/css/font-awesome.min.css";
import Galerie from "./Containers/Galerie";
import AddPicture from "./Containers/AddPicture";
import Inscription from "./Pages/Inscription";
import EditCategorie from "./Containers/EditCategorie";
import Plans from "./Pages/Plans";
import Abonnement from "./Containers/Abonnement";
import { useOrganisations } from "./Auth/useOrganisations";
import Ingredients from "./Containers/Ingredient";
import Menu from "./Pages/Menu";
import InscriptionForm from "./Containers/InscriptionForm";
import SignUpStepper from "./Pages/SignUpStepper";
import Socials from "./Containers/Socials";
import Waiters from "./Containers/Waiters";
import EditOrganisation from "./Containers/EditOrganisation";
import AddWaiter from "./Containers/AddWaiter";
import Orders from "./Containers/Orders";
import Menu2 from "./Pages/Menu2";
import ReceptionOrders from "./Pages/ReceptionOrders";
import CategoryMenus from "./Containers/Category'sMenus";
import WaiterRoute from "./Routes/WaiterRoute";
import Receptionist from "./Containers/AddReception";
import Menu3 from "./Pages/Menu3";
import WaiterLayout from "./Layouts/WaiterLayout";
import MenuRedirect from "./Pages/MenuRedirect";
import ForgotPassword from "./Pages/ForgotPassword";
import Popups from "./Containers/AddPopups";
import ReinitialisationPass from "./Pages/ReinistalisationPass";
import PersonalisationMenu from "./Containers/PersonalisationMenu";
import CarteForm from "./Containers/AddCartes";
import Cartes from "./Containers/Cartes";
import MenuStarter from "./Pages/MenuStarter";
import ImpressionCodes from "./Containers/ImpressionCodes";
import Tables from "./Containers/Tables";
import QrCode from "./Pages/QrCode";
import TableOrders from "./Containers/TableOrders";
import TablesQrCodes from "./Containers/ImpressionCodesTables";
import TablesWaiter from "./Containers/TablesWaiter";
import Tips from "./Containers/Tips";
import HappyHour from "./Containers/HappyHour";
import TableOrdersWaiter from "./Containers/TableOrdersWaiter";
import EditCarte from "./Containers/EditCarte";
import EditWaiter from "./Containers/EditWaiter";
import FlouciSuccess from "./Pages/FlouciSuccess";
import Menu4 from "./Pages/Menu4";
import Satisfaction from "./Pages/Satisfaction";
import MenuLanding from "./Pages/NewMenu/MenuLanding";
import Store from "./Pages/NewMenu/Store";
import Cart from "./Pages/NewMenu/Cart";
import Addition from "./Pages/NewMenu/Addition";
import ConfirmCart from "./Pages/NewMenu/CartConfirmed";
import Events from "./Pages/NewMenu/Events";
import Catalogue from "./Pages/NewMenu/Catalogue";

function App() {
  /* Checking if the user has selected the dark mode or not. If yes, it will add the dark-skin class to the body. */

  const [usedOrganisations, setUsedOrganisations] = useOrganisations();
  useEffect(() => {
    if (localStorage.getItem("is_dark") === "true") {
      document.body.classList.add("dark-skin");
    } else {
      document.body.classList.remove("dark-skin");
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          exact
          element={
            <PrivateRoute>
              <DashboardLayout>
                <Main />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/menus"
          exact
          element={
            <PrivateRoute>
              <DashboardLayout>
                <MenuList />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/menus/add"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <MenuFrom />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/waiters"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <Waiters />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route path="menuredirect/:id/:table" element={<MenuRedirect />} />
        <Route
          path="/menustarter/:id/:table"
          element={
            <EmptyLayout>
              <MenuStarter />
            </EmptyLayout>
          }
        />
        <Route
          path="/catalogue/:id"
          element={
            <EmptyLayout>
              <Catalogue />
            </EmptyLayout>
          }
        />
        <Route
          path="/satisfaction/:id"
          element={
            <EmptyLayout>
              <Satisfaction />
            </EmptyLayout>
          }
        />
        <Route
          path="/menu1/:id/:table/:id_category"
          element={
            <EmptyLayout>
              <Menu />
            </EmptyLayout>
          }
        />
        <Route
          path="/menu3/:id/:produit"
          element={
            <EmptyLayout>
              <Menu3 />
            </EmptyLayout>
          }
        />
        <Route
          path="/menu2/:id/:table/:id_category"
          element={
            <EmptyLayout>
              <Menu2 />
            </EmptyLayout>
          }
        />
        <Route
          path="/menu4/:id/:table/:id_category"
          element={
            <EmptyLayout>
              <Menu4 />
            </EmptyLayout>
          }
        />
        <Route
          path="/receptionorders/:id"
          element={
            <WaiterRoute>
              <WaiterLayout>
                <ReceptionOrders />
              </WaiterLayout>
            </WaiterRoute>
          }
        />

        <Route
          path="/addorganization"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <InscriptionForm />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/receptionist"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <Receptionist />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <EmptyLayout>
                <ForgotPassword />
              </EmptyLayout>
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password/:token"
          element={
            <PublicRoute>
              <EmptyLayout>
                <ReinitialisationPass />
              </EmptyLayout>
            </PublicRoute>
          }
        />
        <Route
          path="/ingredients/:id"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <Ingredients />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/menu/edit/:id"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <EditMenu />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <Orders />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/tables/orders/:table"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <TableOrders />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/waitertables/orders/:table"
          element={
            <WaiterRoute>
              <WaiterLayout>
                <TableOrdersWaiter />
              </WaiterLayout>
            </WaiterRoute>
          }
        />

        <Route
          path="/categories"
          exact
          element={
            <PrivateRoute>
              <DashboardLayout>
                <CategoryList />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/cartes"
          exact
          element={
            <PrivateRoute>
              <DashboardLayout>
                <Cartes />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/cartes/add"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <CarteForm />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/happyhour/:id"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <HappyHour />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/socials"
          exact
          element={
            <PrivateRoute>
              <DashboardLayout>
                <Socials />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/tables"
          exact
          element={
            <PrivateRoute>
              <DashboardLayout>
                <ImpressionCodes />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/liste-tables"
          exact
          element={
            <PrivateRoute>
              <DashboardLayout>
                <Tables />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/tips"
          exact
          element={
            <WaiterRoute>
              <WaiterLayout>
                <Tips />
              </WaiterLayout>
            </WaiterRoute>
          }
        />
        <Route
          path="/waitertables"
          exact
          element={
            <WaiterRoute>
              <WaiterLayout>
                <TablesWaiter />
              </WaiterLayout>
            </WaiterRoute>
          }
        />
        <Route
          path="/categories/add"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <CategoryForm />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/waiters/add"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <AddWaiter />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/categories/edit/:id"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <EditCategorie />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/waiters/edit/:id"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <EditWaiter />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/cartes/edit/:id"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <EditCarte />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/categories/:id"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <CategoryMenus />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/organisation/edit"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <EditOrganisation />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/galerie"
          exact
          element={
            <PrivateRoute>
              <DashboardLayout>
                <Galerie />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/addphoto"
          exact
          element={
            <PrivateRoute>
              <DashboardLayout>
                <AddPicture />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/abonnement"
          exact
          element={
            <PrivateRoute>
              <DashboardLayout>
                <Abonnement />
              </DashboardLayout>
            </PrivateRoute>
          }
        />

        <Route
          path="/login"
          element={
            <PublicRoute>
              <EmptyLayout>
                <Login />
              </EmptyLayout>
            </PublicRoute>
          }
        />
        <Route
          path="/flouci_success"
          element={
            <PublicRoute>
              <EmptyLayout>
                <FlouciSuccess />
              </EmptyLayout>
            </PublicRoute>
          }
        />

        <Route
          path="/register"
          element={
            <PublicRoute>
              <EmptyLayout>
                <SignUp />
              </EmptyLayout>
            </PublicRoute>
          }
        />
        <Route
          path="/inscription"
          element={
            <PublicRoute>
              <EmptyLayout>
                <SignUpStepper />
              </EmptyLayout>
            </PublicRoute>
          }
        />
        <Route
          path="/popups"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <Popups />
              </DashboardLayout>
            </PrivateRoute>
          }
        />

        <Route
          path="/personalisationmenu"
          element={
            <PrivateRoute>
              <DashboardLayout>
                <PersonalisationMenu />
              </DashboardLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/plans"
          element={
            <PublicRoute>
              <EmptyLayout>
                <Plans />
              </EmptyLayout>
            </PublicRoute>
          }
        />

        <Route
          path="/qrcode/:org/:id/:color"
          exact
          element={
            <PrivateRoute>
              <EmptyLayout>
                <QrCode />
              </EmptyLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/qrcodes/:id"
          exact
          element={
            <PrivateRoute>
              <EmptyLayout>
                <TablesQrCodes />
              </EmptyLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/newmenu"
          exact
          element={
            <PublicRoute>
              <EmptyLayout>
                <MenuLanding />
              </EmptyLayout>
            </PublicRoute>
          }
        />
        <Route
          path="/menu5/:id/:table/events"
          exact
          element={
            <PublicRoute>
              <EmptyLayout>
                <Events />
              </EmptyLayout>
            </PublicRoute>
          }
        />

        <Route
          path="/menu5/:id/:table"
          exact
          element={
            <EmptyLayout>
              <Store />
            </EmptyLayout>
          }
        />
        <Route
          path="/menu5/:id/:table/:idcategory"
          exact
          element={
            <EmptyLayout>
              <Store />
            </EmptyLayout>
          }
        />
        <Route
          path="/menu5/:id/:table/cart"
          exact
          element={
            <EmptyLayout>
              <Cart />
            </EmptyLayout>
          }
        />
        <Route
          path="/menu5/:id/:table/addition"
          exact
          element={
            <EmptyLayout>
              <Addition />
            </EmptyLayout>
          }
        />
        <Route
          path="/menu5/:id/:table/confirmation"
          exact
          element={
            <EmptyLayout>
              <ConfirmCart />
            </EmptyLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
