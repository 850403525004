import axios from "axios";
import { getSession } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API;

/* Setting the header for the axios request. */
const config = {
  headers: { Authorization: `Bearer ${getSession("token")}` },
};

//to add in every api
const currentOrg = getSession("usedorganisations");

export async function personalisationMenu(
  menu,
  background,
  font,
  fontcolor,
  themecolor,
  productimage,
  options,
  // image
) {
  const frmData = new URLSearchParams();
  frmData.append("menu", menu);
  frmData.append("background", background);
  frmData.append("font", font);
  frmData.append("fontcolor", fontcolor);
  frmData.append("themecolor", themecolor);
  frmData.append("productimage", productimage);
  frmData.append("options", options);
  // frmData.append("image", image);
  frmData.append("organisation_id", currentOrg);
  const httpHeaders = {
    headers: {
      Authorization: `Bearer ${getSession("token")}`,
      "Content-Type": `application/x-www-form-urlencoded; boundary=${frmData._boundary}`,
    },
  };
  try {
    const response = await axios.post(
      api_url + "templatemenu",
      frmData,
      httpHeaders
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function updateMenuPersonalisation(
  menu,
  background,
  font,
  fontcolor,
  themecolor,
  productimage,
  options,
  // image,
  id
) {
  const frmData = new URLSearchParams();
  if (menu) frmData.append("menu", menu);
  if (background) frmData.append("background", background);
  if (font) frmData.append("font", font);
  if (fontcolor) frmData.append("fontcolor", fontcolor);
  if (themecolor) frmData.append("themecolor", themecolor);
  frmData.append("productimage", productimage);
  frmData.append("options", options);
  // frmData.append("image", image);
  frmData.append("id", id);
  frmData.append("organisation_id", currentOrg);

  const httpHeaders = {
    headers: {
      Authorization: `Bearer ${getSession("token")}`,
      "Content-Type": `application/x-www-form-urlencoded; boundary=${frmData._boundary}`,
    },
  };

  try {
    const response = await axios.patch(
      api_url + "templatemenu",
      frmData,
      httpHeaders
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getPersonalisationMenu(id) {
  if (id == null) {
    id = currentOrg;
  }
  try {
    const response = await axios.get(
      api_url + "templatemenu/organisation/" + id
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
