import React, { useEffect, useState } from "react";
import TemplateCleaner from "./TemplateCleaner";
import { Link, useNavigate, useParams } from "react-router-dom";
import NewMenuHeader from "./NewMenuHeader";
import { getOrganisationById } from "../../Services/OrganizationApi";
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { addDemande } from "../../Services/DemandeApi";
import { getActiveHappyHours } from "../../Services/HappyHourApi";
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";

import fr from "../MenusStyle/france.png";
import eng from "../MenusStyle/england.png";
import ar from "../MenusStyle/tun.png";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import swal from "sweetalert";
import GifLoader from "../../Components/loader";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // A function that increment ðŸ‘†ðŸ» the previous state like here
  // is better than directly setting setValue(value + 1)
}

function Addition() {
  const { id, table } = useParams();
  const addition = JSON.parse(localStorage.getItem("addition"));
  const [newAddition, setNewAddition] = useState(); //addition with tips
  const [org, setOrg] = useState();
  const [currency, setCurrency] = useState();
  const [checked, setChecked] = useState("Cash");
  const [chiffreApresVirg, setChiffreApresVirg] = useState();
  const [tip, setTip] = React.useState(parseInt(0));
  const [happyHours, setHappyHours] = useState([]);
  const [layout, setLayout] = useState("unset");
  const [loading, setLoading] = useState(true);

  const promptForAddition = () => {
    Swal.fire("", "Attendez, notre serveur va venir à votre table!", "success");
  };
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(""); // Initialize with an empty string

  const handleRadioChange = (value) => {
    setSelectedPaymentMethod(value);
  };

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(
    localStorage.getItem("selectedLanguage") || "fr"
  );
  const handleLanguageChange = (selectedLanguage) => {
    setLang(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);

    // Use the changeLanguage function from i18next
    changeLanguage(selectedLanguage);

    // You can also trigger any language-specific layout changes here if needed.
  };
  const translation = (titre) => {
    if (lang === "fr") {
      return titre.fr;
    } else if (lang === "en") {
      return titre.en;
    } else return titre.ar;
  };
  const currencyTranslation = (currency) => {
    if (lang === "fr" && currency === "TND") {
      return "TND";
    } else if (lang === "en" && currency === "TND") {
      return "TND";
    } else return " دت";
  };

  const handleToggle = (value) => () => {
    const newChecked = value === checked ? "" : value;
    setChecked(newChecked);
  };
  const [paymentMethods, setPaymenMethods] = useState();

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const forceUpdate = useForceUpdate();

  const callWaiter = () => {
    let tempAddition = addition;
    tempAddition.tips = tempAddition.tips + tip;
    setNewAddition(tempAddition);

    addDemande(atob(table), tip, checked, id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        console.log(res.data.data);
        // alert("Attendez, notre serveur va venir à votre table!");
        promptForAddition();
        localStorage.removeItem("addition");
      }
    });
  };

  function ingredientsPrice(array) {
    return array.reduce((total, product) => total + product.prix, 0);
  }

  const deepArrayCompare = (array1, array2) => {
    if (array1.length !== array2.length) return false;

    for (let i = 0; i < array1.length; i++) {
      if (JSON.stringify(array1[i]) !== JSON.stringify(array2[i])) {
        return false;
      }
    }

    return true;
  };
  function newArray(produits) {
    console.log(produits);
    const result = produits.reduce((acc, curr) => {
      const key = `${curr.products.titre.fr}`;
      const prix = `${curr.products.prix}`;
      const ingredientsArray = curr.ingredients.map((ingredient) => ({
        ingredient,
      }));
      const prixIngredients = ingredientsArray.reduce(
        (total, product) => total + product.ingredient.prix,
        0
      );
      const existingItem = acc.find(
        (item) =>
          item.key === key &&
          deepArrayCompare(item.ingredients, ingredientsArray)
      );
      if (existingItem) {
        existingItem.data.push(curr.products);
      } else {
        acc.push({
          key,
          data: [curr],
          prix,
          ingredients: ingredientsArray,
          prixTotal: parseInt(prix) + parseInt(prixIngredients),
        });
      }
      return acc;
    }, []);

    return result;
  }
  useEffect(() => {
    setTimeout(() => {
      var onPageSetupDoneEvent = new Event("onPageSetupDone");
      document.dispatchEvent(onPageSetupDoneEvent);
      console.log(addition);
    }, 2000);
  }, []);
  useEffect(() => {
    // Function to handle the beforeunload event
    const confirmExit = (e) => {
      if (localStorage.getItem("addition") === null) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      }
    };
    // Attach the event listener when the component mounts
    window.addEventListener("beforeunload", confirmExit);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", confirmExit);
    };
  }, []);

  useEffect(() => {
    changeLanguage(localStorage.getItem("selectedLanguage"));
    if (localStorage.getItem("selectedLanguage") === "ar") {
      setLayout("rtl");
    }
    getOrganisationById(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
        setCurrency(res.data.data.currency);
        setPaymenMethods(res.data.data.paiementmode);
        if (res.data.data.currency === "TND") {
          setChiffreApresVirg(3);
        } else if (
          res.data.data.currency === "Euro" ||
          res.data.data.currency === "Dollar"
        ) {
          setChiffreApresVirg(2);
        }
      }
    });
    getActiveHappyHours(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setHappyHours(res.data.data);
        console.log(res.data.data);
        setLoading(false);
      }
    });
  }, []);

  function getPrice(product, happyHours) {
    const happyHourItem = happyHours.find(
      (item) => item.menu_id._id === product._id
    );

    if (happyHourItem) {
      return product.prix - happyHourItem.reduction;
    } else {
      return product.prix;
    }
  }
  return (
    <>
      <TemplateCleaner />
      <GifLoader loading={loading} />

      <div className="bg-white shadow-sm p-3">
        <div className="d-flex align-items-center">
          <div className="gap-3 d-flex align-items-center">
            <a
              onClick={(e) =>
                (window.location.href = "/menu5/" + id + "/" + table)
              }
            >
              <i className="bi bi-arrow-left d-flex text-danger h2 m-0 back-page"></i>
            </a>
            <h3 className="fw-bold m-0">{t("Addition")}</h3>
          </div>
          <div className="ms-auto gap-3 d-flex align-items-center">
            <div className="btn-group">
              <button
                className="btn btn-rounded dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                style={{
                  backgroundColor: "white",
                  display: "flex",
                  padding: "0",
                }}
              >
                <div className="btn-text">
                  {lang === "fr" ? (
                    <>
                      <img
                        src={fr}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      Fr
                    </>
                  ) : lang === "en" ? (
                    <>
                      <img
                        src={eng}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      En
                    </>
                  ) : lang === "ar" ? (
                    <>
                      <img
                        src={ar}
                        style={{ width: "30px", marginRight: "10px" }}
                      />
                      Ar
                    </>
                  ) : null}
                </div>
              </button>
              <div
                className="dropdown-menu dropdown-menu-right"
                style={{ backgroundColor: "white" }}
              >
                <a
                  className="dropdown-item"
                  onClick={() => {
                    handleLanguageChange("fr");
                    setLayout("unset");
                  }}
                >
                  <img
                    src={fr}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  Fr
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    handleLanguageChange("en");
                    setLayout("unset");
                  }}
                >
                  <img
                    src={eng}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  En
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    handleLanguageChange("ar");
                    setLayout("rtl");
                  }}
                >
                  <img
                    src={ar}
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  Ar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* body */}
      <div className="p-3" style={{ direction: layout }}>
        <div>
          <h2 className="fw-bold mb-1" style={{ textAlign: "center" }}>
            {" "}
            {org && org.nom}{" "}
          </h2>
        </div>
        <div className="row">
          <div className="col-12" style={{ textAlign: "center" }}>
            <h5>
              {t("Table numéro")} : {atob(table)}
            </h5>
          </div>
        </div>
        <hr></hr>
        {/* <button onClick={(e) => console.log(newArray(addition.produits))}>
          check addition
        </button> */}
        {addition.produits &&
          newArray(addition.produits).map((value, index) => {
            const produit = value.data[0].products;
            return (
              <div className="m-0">
                <div className="d-flex align-items-center gap-3 mb-3">
                  <img src="img/veg.jpeg" alt="" className="img-fluid ch-20" />
                  <div className="lh-sm">
                    <h4 className="mb-1"> {translation(produit.titre)} </h4>
                  </div>
                </div>
                {value.data[0].ingredients &&
                  value.data[0].ingredients.map((val, index) => (
                    <p>+{val.titre}</p>
                  ))}
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex gap-2 align-items-center fs-5">
                    <div className="rounded border border-success small px-2 d-flex">
                      {value.data.length}
                    </div>

                    <div> x </div>
                    <div>
                      {" "}
                      {getPrice(produit, happyHours)}{" "}
                      {currencyTranslation(currency)}{" "}
                    </div>
                  </div>
                  <h6 className="fw-bold">
                    {" "}
                    {(getPrice(produit, happyHours) +
                      ingredientsPrice(value.data[0].ingredients)) *
                      value.data.length}{" "}
                    {currencyTranslation(currency)}{" "}
                  </h6>
                </div>
                <hr></hr>
              </div>
            );
          })}

        <div className="">
          <div className="text-muted text-uppercase mb-0 small">
            {t("Date")}
          </div>
          <div className="fs-5">
            {new Date().toLocaleString("en-US", {
              timeZone: "Africa/Tunis",
            })}
          </div>
        </div>
        {/* total price */}
        <div>
          <div className="d-flex justify-content-between mb-1">
            <div> {t("Prix Total")} </div>
            <div>
              {/* <del>$240</del>&nbsp; */}
              {addition.prix.toFixed(chiffreApresVirg)}{" "}
              {currencyTranslation(currency)}
            </div>
          </div>
          <div className="d-flex justify-content-between mb-1">
            <div>{t("Tips")}</div>
            <div>
              {/* <del>$240</del>&nbsp; */}
              {tip ? tip.toFixed(chiffreApresVirg) : 0}{" "}
              {currencyTranslation(currency)}
            </div>
          </div>

          {/* Grand total */}

          <div className="d-flex justify-content-between h3 fw-bold my-3">
            <div>{t("Total")}</div>
            <div>
              {tip
                ? (tip + addition.prix).toFixed(chiffreApresVirg)
                : addition.prix.toFixed(chiffreApresVirg)}{" "}
              {currencyTranslation(currency)}{" "}
            </div>
          </div>
        </div>
      </div>
      {/* PAYMENT METHOD */}
      <div
        className="bg-white p-3 mb-3 shadow-sm"
        style={{ direction: layout }}
      >
        <h6 style={{ color: "black" }}>
          {t("Choisir une methode de paiement")}
        </h6>
        <div className="payment-methods-container">
          {paymentMethods &&
            paymentMethods.map((value) => {
              return (
                <div key={value}>
                  <label
                    // style={{ whiteSpace: "nowrap" }}
                    className={`btn btn-outline-danger btn-sm ${
                      checked === value ? "active" : ""
                    }`}
                  >
                    {value === "Cash" ? (
                      <>
                        <i className="fa-regular fa-money-bill-1"></i> {value}
                      </>
                    ) : value === "Tickets Restau" ? (
                      <>
                        <i className="fa-solid fa-ticket"></i> {value}{" "}
                      </>
                    ) : value === "Credit Card" ? (
                      <>
                        <i className="fa-brands fa-cc-mastercard"></i> {value}
                      </>
                    ) : (
                      <>
                        {" "}
                        <i className="fa-solid fa-money-check-dollar"></i>{" "}
                        {value}
                      </>
                    )}

                    <input
                      type="radio"
                      className="visually-hidden"
                      name="btnradio"
                      checked={checked === value}
                      onChange={() => setChecked(value)}
                    />
                  </label>
                </div>
              );
            })}
        </div>
      </div>
      {/* TIPS */}
      <div
        className="bg-white p-3 mb-3 shadow-sm"
        style={{ direction: layout }}
      >
        <div className="mb-2">
          <h5 className="fw-bold mb-2">{t("Please tip your waiter")}</h5>
        </div>
        <div className="payment-methods-container d-flex">
          <label
            className={`btn btn-outline-danger btn-sm ${
              tip === 0 ? "active" : ""
            }`}
          >
            <i className="fa-solid fa-ban"></i>
            <input
              type="radio"
              className="visually-hidden"
              name="btnradio"
              checked={tip === 0}
              onChange={() => setTip(0)}
            />
          </label>
          <label
            className={`btn btn-outline-danger btn-sm ${
              tip === 2 ? "active" : ""
            }`}
          >
            2{currencyTranslation(currency)}
            <input
              type="radio"
              className="visually-hidden"
              name="btnradio"
              checked={tip === 2}
              onChange={() => setTip(2)}
            />
          </label>
          <label
            className={`btn btn-outline-danger btn-sm ${
              tip === 5 ? "active" : ""
            }`}
          >
            5{currencyTranslation(currency)}
            <input
              type="radio"
              className="visually-hidden"
              name="btnradio"
              checked={tip === 5}
              onChange={() => setTip(5)}
            />
          </label>
          <label
            className={`btn btn-outline-danger btn-sm ${
              tip === 7 ? "active" : ""
            }`}
          >
            7{currencyTranslation(currency)}
            <input
              type="radio"
              className="visually-hidden"
              name="btnradio"
              checked={tip === 7}
              onChange={() => setTip(7)}
            />
          </label>
          <label
            className={`btn btn-outline-danger btn-sm ${
              tip === 10 ? "active" : ""
            }`}
          >
            10{currencyTranslation(currency)}
            <input
              type="radio"
              className="visually-hidden"
              name="btnradio"
              checked={tip === 10}
              onChange={() => setTip(10)}
            />
          </label>
          <input
            type="number"
            className="shadow-none form-control"
            placeholder=""
            value={tip}
            onChange={(e) => setTip(parseInt(e.currentTarget.value))}
          />
        </div>
        <div className="my-3 bg-success-subtle p-2 text-success text-center rounded">
          {t("Bon appétit et à bientôt !")}
        </div>
        <div className="my-3 bg-white-subtle p-2 text-success text-center rounded"></div>
      </div>

      {/* fixed-bottom */}
      <div className="p-3 fixed-bottom">
        <div className="d-grid">
          <button
            onClick={(e) => callWaiter()}
            className="btn btn-danger btn-lg"
          >
            <i className="fa-solid fa-bell-concierge mr-2"></i>
            {t("Demander un serveur")}
          </button>
        </div>
      </div>

      <div
        className="modal fade"
        id="suggestionModal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <h5 className="modal-title"> </h5>
            </div>

            <div className="modal-body p-0"></div>
            <div className="modal-footer d-flex justify-content-between">
              <button
                className="btn btn-danger col-3"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {t("Ok")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <script src="https://cdn.jsdelivr.net/npm/sweetalert2@11"></script>
      <script src="sweetalert2.min.js"></script>
      <link rel="stylesheet" href="sweetalert2.min.css"></link>
    </>
  );
}

export default Addition;
