import React from 'react';
import PropTypes from 'prop-types';
class GifLoader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
  render() {
      const {imageSrc, imageStyle, overlayBackground, loading} = this.props;
      const overlayBlockStyle = {
        zIndex: "99999",
        height: "100vh",
        width: "100%",
        textAlign: "center",
        position: "fixed",
        display: loading ? "block" : "none",
        backgroundColor: overlayBackground,
        top: 0,
        left: 0
    }
    return (
      <div style={overlayBlockStyle} >
          <img style={imageStyle} src={imageSrc} />
      </div>
    );
  }
}
GifLoader.propTypes = {
    imageSrc: PropTypes.string,
    overlayBackground: PropTypes.string,
    imageStyle: PropTypes.object,
    loading: PropTypes.bool.isRequired,
  };
GifLoader.defaultProps = {
    imageSrc: "/images/preloaders/1.gif",
    imageStyle: {marginTop: "20%"},
    overlayBackground: "white"
};
export default GifLoader;