import axios from "axios";
import { getSession } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API;

/* Setting the header for the axios request. */
const config = {
  headers: { Authorization: `Bearer ${getSession("token")}` },
};

//to add in every api
const currentOrg = getSession("usedorganisations");

export async function addCategorie(logo, libelle, ordre, carte_id) {
  const frmData = new FormData();
  frmData.append("logo", logo);
  frmData.append("libelle[fr]", libelle.fr);
  frmData.append("libelle[en]", libelle.en);
  frmData.append("libelle[ar]", libelle.ar);
  frmData.append("ordre", ordre);
  frmData.append("carte_id", carte_id);
  frmData.append("organisation_id", currentOrg);
  const httpHeaders = {
    headers: {
      Authorization: `Bearer ${getSession("token")}`,
      "Content-Type": `multipart/form-data; boundary=${frmData._boundary}`,
    },
  };
  try {
    const response = await axios.post(
      api_url + "categories",
      frmData,
      httpHeaders
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function editCategorie(logo, libelle, ordre, dispo, carte_id, id) {
  const frmData = new FormData();
  frmData.append("logo", logo);
  frmData.append("libelle[fr]", libelle.fr);
  frmData.append("libelle[en]", libelle.en);
  frmData.append("libelle[ar]", libelle.ar);
  frmData.append("ordre", ordre);
  frmData.append("dispo", dispo);
  frmData.append("carte_id", carte_id);
  frmData.append("id", id);
  frmData.append("organisation_id", currentOrg);

  const httpHeaders = {
    headers: {
      Authorization: `Bearer ${getSession("token")}`,
      "Content-Type": `multipart/form-data; boundary=${frmData._boundary}`,
    },
  };


  try {
    const response = await axios.patch(api_url + "categories", frmData, httpHeaders);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getCategories(id) {
  if (id === null) {
    id = currentOrg;
  }
  try {
    const response = await axios.get(api_url + "categories/organisation/"+id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getCategoriesByCarte(id) {
  try {
    const response = await axios.get(api_url + "categories/carte/"+id);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getCategorieById(id) {
  try {
    const response = await axios.get(api_url + "categories/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteCategorie(id) {
  try {
    const response = await axios.delete(api_url + "categories/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
