import React, { useEffect, useState } from "react";
import { getSpecificMenu } from "../Services/MenuApi";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useUser } from "../Auth/useUser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GifLoader from "../Components/loader";
import { addHappyHour } from "../Services/HappyHourApi";
import { useOrganisations } from "../Auth/useOrganisations";
import { useTranslation } from "react-i18next";

function HappyHour() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  let id = useParams();
  const [startDate, setStartDate] = useState(null); //menu_id, start_date, end_date, start_time, end_time, reduction
  const [endDate, setEndDate] = useState([]);
  const [startTime, setStartTime] = useState([]);
  const [endTime, setEndTime] = useState(null);
  const [reduction, setReduction] = useState(null);
  const [menu, setMenu] = useState();

  const user = useUser();
  const [infoUser] = useState(user.result);
  const [loading, setLoading] = useState(true);
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();

  const promptForDelete = () => {
    swal({
      title: "Pouf ! Votre article a été ajouté avec succès !",
      icon: "success",
    }).then(function () {
      window.location = "/menus";
    });
  };
  //   useEffect(() => {
  //     getSpecificMenu(menu_id.id).then((res) => {
  //       const success = res.data.success;
  //       if (success === "0") {
  //         alert("error");
  //       } else {
  //         console.log(res.data.data);
  //         setMenu(res.data.data);
  //       }
  //     });
  //   }, [menu_id.id]);
  const onSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!startDate || !endDate || !startTime || !endTime || !reduction) {
      toast.error("S'il-vous-plaît remplissez tous les champs requis!");
      return false;
    }

    addHappyHour(
      usedOrganisations,
      id.id,
      startDate,
      endDate,
      startTime,
      endTime,
      reduction
    ).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        // const link = "/ingredients/" + res.data.data.id;
        // window.location.href = link;
        promptForDelete();
      }
    });
    return false;
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <section className="content">
      <GifLoader loading={loading} />
      <div className="row">
        <div className="col-12">
          <div className="box">
            <div className="box-body">
              <form onSubmit={onSubmit}>
                <div className="form-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t("Réduction $")}
                        </label>
                        <input
                          value={reduction}
                          onChange={(e) => setReduction(e.target.value)}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t("Date début")}
                        </label>
                        <input
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          type="date"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t("Date fin")}
                        </label>
                        <input
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          type="date"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t("heure début")}
                        </label>
                        <input
                          value={startTime}
                          onChange={(e) => setStartTime(e.target.value)}
                          type="time"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t("heure fin")}
                        </label>
                        <input
                          value={endTime}
                          onChange={(e) => setEndTime(e.target.value)}
                          type="time"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-actions mt-10">
                  <Link to="/">
                    <button type="button" className="btn btn-primary">
                      {t("Annuler")}
                    </button>
                  </Link>
                  <button type="submit" className="btn btn-danger left-button">
                    {" "}
                    <i className="fa fa-check"></i> {t("Ajouter")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default HappyHour;
