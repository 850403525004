import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { addOrganization } from "../Services/OrganizationApi";
import { useUser } from "../Auth/useUser";
import { useOrganisations } from "../Auth/useOrganisations";

function InscriptionForm() {
  const [nom, setNom] = useState();
  const [logo, setLogo] = useState();
  const [numero, setNumero] = useState();
  const [description, setDescription] = useState();
  const [type, setType] = useState();
  const user = useUser();
  const [infoUser] = useState(user.result);

  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();

  const onSubmit = async (e) => {
    e.preventDefault();


    //@ToDo: Test all input errors here.
    addOrganization(nom,
      logo,
      '#FFFFFF',
      numero,
      description,
      infoUser.id,
      "1",
      "",
      "1",
      "TND",
      ["Cash"],
      type,
      "0").then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        alert("ajouté avec succees");
      }
    });
    return false;
  };

  //   useEffect(() => {
  //     document.body.style.backgroundImage = "url(../images/auth-bg/restau.jfif)";
  //   }, []);

  return (
    <div className="container h-p100" style={{ marginTop: "50px" }}>
      <div className="row align-items-center justify-content-md-center h-p100">
        <div className="col-12">
          <div className="row justify-content-center no-gutters">
            <div className="col-lg-12 col-md-5 col-12">
              <div className=" rounded30 shadow-lg">
                <div className="content-top-agile p-20 pb-0">
                  <h2 className="text-primary">
                    Ajouter une nouvelle organization
                  </h2>
                </div>
                <div className="p-40">
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-user" />
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control pl-15 bg-transparent"
                          placeholder="Nom"
                          value={nom}
                          onChange={(e) => setNom(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-addon">
                          <i className="fa fa-phone"></i>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="99 99 99 99"
                          value={numero}
                          onChange={(e) => setNumero(e.currentTarget.value)}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <h5>Choisir votre type de vente</h5>
                      <div className="input-group mb-3">
                        <div className="demo-radio-button">
                          <div
                            className="demo-radio-button"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <input
                              name="type"
                              type="radio"
                              id="type1"
                              className="radio-col-primary"
                              value={"Restaurant"}
                              onClick={(e) => setType(e.currentTarget.value)}
                              defaultChecked={type === "Restaurant"}
                            />
                            <label for="type1">Restaurant</label>
                            <input
                              name="type"
                              type="radio"
                              id="type2"
                              className="radio-col-success"
                              value={"Catalogue"}
                              defaultChecked={type === "Catalogue"}
                              onClick={(e) => setType(e.currentTarget.value)}
                            />
                            <label for="type2">Catalogue</label>
                            <input
                              name="type"
                              type="radio"
                              id="type3"
                              className="radio-col-success"
                              value={"Showroom"}
                              defaultChecked={type === "Showroom"}
                              onClick={(e) => setType(e.currentTarget.value)}
                            />
                            <label for="type3">Showroom</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="font-weight-700 font-size-16">
                        Description
                      </label>
                      <textarea
                        className="form-control p-20"
                        rows="4"
                        value={description}
                        onChange={(e) => setDescription(e.currentTarget.value)}
                      ></textarea>
                    </div>

                    <div className="product-img text-left">
                      <h4 className="box-title" style={{ textAlign: "center" }}>
                        Télecharger votre logo
                      </h4>
                      <div className="product-img text-left">
                        <img
                          src={
                            logo
                              ? URL.createObjectURL(logo)
                              : "../images/product/no-image.png"
                          }
                          alt=""
                          className="mb-15"
                        />
                        <div className="btn btn-info mb-20 choose-file">
                          <input
                            onChange={(e) => setLogo(e.currentTarget.files[0])}
                            type="file"
                            name="logo"
                            className="upload"
                          />
                        </div>
                      </div>
                    </div>

                    {/* choix menu */}

                    <div className="row">
                      {/* /.col */}
                      <div className="col-12 text-center">
                        <button
                          type="submit"
                          className="btn btn-info margin-top-10"
                        >
                          S'inscrire
                        </button>
                      </div>
                      {/* /.col */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InscriptionForm;
