import { useState } from "react";

export const useOrganisations = () => {
  /* A function that returns a token from local storage. */
  const [organisations, setOrganisationsInternal] = useState(() => {
    const org = localStorage.getItem("organisations");
    return JSON.parse(JSON.parse(org, true));
  });
  const [usedOrganisations, setUsedOrganisationsInternal] = useState(() => {
    const org = localStorage.getItem("usedorganisations");
    return org;
  });

  /**
   * It sets the token in local storage and then sets the token in the internal state.
   * @param newToken - The new token to be set.
   */
  const setOrganisations = (org) => {
    localStorage.setItem("organisations", JSON.stringify(org));
    setOrganisationsInternal(org);
    setUsedOrganisationsInternal(org[0]);
  };
  const setUsedOrganisations = (org) => {
    localStorage.setItem("usedorganisations", org);
    setUsedOrganisationsInternal(org);
  };
  return [organisations, setOrganisations, usedOrganisations, setUsedOrganisations];
};