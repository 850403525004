import { QRCodeCanvas } from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getOrganisationById } from "../Services/OrganizationApi";

function QrCode() {
  const { org, id, color } = useParams();
  const [bgColor, setBgColor] = useState();
  const [logo, setLogo] = useState();
  const [visible, setVisible] = useState("flex");
  //   const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    // if (!hasLoaded) {
    //   // Mark that the page has loaded
    //   setHasLoaded(true);
    // } else {
    //   // Reload the page
    //   window.location.reload();
    // }

    getOrganisationById(org).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        // setOrganisation(res.data.data);
        setLogo(res.data.data.logo);
      }
    });
  }, []);

  const handlePrint = () => {
    setVisible("none");
    window.print();
  };

  return (
    <section className="content">
      <div className="print" style={{ width: "10.5cm" }}>
        <div className="overflow-h">
          <div className="box-body p-0">
            <div>
              <div className="" style={{ backgroundColor: "white" }}>
                <div
                  className="box-header with-border"
                  style={{ backgroundColor: "#" + color }}
                >
                  <div className="row">
                    <h4 className="box-title col-8">
                      <img
                        className="rounded img-fluid"
                        src="/images/logo-long.png"
                        style={{ width: "55mm" }}
                      />
                    </h4>
                    <div className="avatar avatar-lg col-4" href="#">
                      <img
                        className="rounded img-fluid"
                        style={{ width: "60px", height: "60px" }}
                        src={
                          logo ? process.env.REACT_APP_API_BASE_URL + logo : ""
                        }
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="box-body"
                  style={{
                    justifyContent: "center",
                    display: "grid",
                    padding: "60px",
                  }}
                >
                  <p>
                    <QRCodeCanvas
                      id="qr-code-canvas"
                      value={
                        document.location.origin +
                        "/menu3" +
                        "/" +
                        org +
                        "/" +
                        id
                      }
                      size={300}
                      bgColor={"#ffffff"}
                      level={"H"}
                      onClick={(e) =>
                        window
                          .open(
                            document.location.origin +
                              "/menu3" +
                              "/" +
                              org +
                              "/" +
                              id,
                            "_blank",
                            "tab"
                          )
                          .focus()
                      }
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </p>
                  <p style={{ textAlign: "center", fontSize: "xxx-large" }}>
                    <i className="fa-solid fa-sort-up"></i>
                  </p>
                  <p style={{ textAlign: "center", fontSize: "large" }}>
                    SCANINY
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="no-print waves-effect btn-primary-light btn-xs mb-5"
          onClick={handlePrint}
          style={{ display: visible }}
        >
          imprimer
        </button>
      </div>
    </section>
  );
}
export default QrCode;
