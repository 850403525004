import React, { useEffect, useState } from "react";
// import { useToken } from "../Auth/useToken";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { getOrganisationById } from "../Services/OrganizationApi";
import { useOrganisations } from "./../Auth/useOrganisations";
import { addWaiter, getWaiters } from "../Services/WaiterApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function AddWaiter() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [nom, setNom] = useState();
  const [prenom, setPrenom] = useState();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [gender, setGender] = useState("homme");
  const [takenTables, setTakenTables] = useState([]);
  const [tablesNumber, setTablesNumber] = useState();
  const [date_naissance, setDateNaissance] = useState();

  const [checked, setChecked] = useState([]);

  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  useEffect(() => {
    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setTablesNumber(res.data.data.tables);
      }
    });
    getWaiters(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        var arr = [];

        for (var i = 0; i < res.data.data.length; i++) {
          arr.push(res.data.data[i].tables);
        }

        var concatenatedTables = [].concat(...arr); // concatenate all the arrays inside takenTables

        setTakenTables(concatenatedTables);
      }
    });
  }, []);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const promptForDelete = () => {
    swal({
      title: "Pouf ! Votre serveur a été ajouté avec succès !",
      icon: "success",
    }).then(function () {
      window.location = "/waiters";
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    //@ToDo: Test all input errors here.
     // Validate required fields
     if (!nom || !prenom || !email || !password || !mobile || !checked  ) {
      toast.error("S'il-vous-plaît remplissez tous les champs requis!");
      return false;
    }
    addWaiter(
      nom,
      prenom,
      email,
      password,
      "waiter",
      mobile,
      checked,
      gender,
      "Restaurant",
      "01/01/2000",
      usedOrganisations
    ).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        alert("ajouté avec succees");
      }
    });
    return false;
  };

  return (
    <section className="content">
      <div className="row">
        <div className="col-12">
          <div className="box">
            <div className="box-body">
              <form onSubmit={onSubmit}>
                <div className="form-group">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-transparent">
                        <i className="ti-user" />
                      </span>
                    </div>
                    <input
                      value={nom}
                      onChange={(e) => setNom(e.currentTarget.value)}
                      type="text"
                      className="form-control pl-15 bg-transparent"
                      placeholder="Nom"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-transparent">
                        <i className="ti-user" />
                      </span>
                    </div>
                    <input
                      value={prenom}
                      onChange={(e) => setPrenom(e.currentTarget.value)}
                      type="text"
                      className="form-control pl-15 bg-transparent"
                      placeholder="Prénom"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="font-weight-700 font-size-16">Vos tables non gardés par seveurs :</label>
                  <List sx={{ width: "100%", maxWidth: 360 }}>
                    {[...Array(tablesNumber).keys()]
                      .filter((value) => !takenTables.includes(value + 1))
                      .map((value) => {
                        const labelId = `checkbox-list-label-${value}`;
                        return (
                          <ListItem key={value} disablePadding>
                            <ListItemButton
                              role={undefined}
                              onClick={handleToggle(value + 1)}
                              dense
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={checked.indexOf(value + 1) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                id={labelId}
                                primary={`Table ${value + 1}`}
                              />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                  </List>
                </div>

                <div className="form-group">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-transparent">
                        <i className="ti-email" />
                      </span>
                    </div>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                      type="email"
                      className="form-control pl-15 bg-transparent"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-addon">
                      <i className="fa fa-phone"></i>
                    </div>
                    <input
                      value={mobile}
                      onChange={(e) => setMobile(e.currentTarget.value)}
                      type="text"
                      className="form-control"
                      placeholder="99 99 99 99"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group mb-3">
                    <div className="demo-radio-button">
                      <div className="demo-radio-button">
                        <input
                          name="group4"
                          type="radio"
                          id="radio_7"
                          className="radio-col-primary"
                          value={"homme"}
                          onClick={(e) => setGender(e.currentTarget.value)}
                          defaultChecked={gender === "homme"}
                        />
                        <label for="radio_7">Homme</label>
                        <input
                          name="group4"
                          type="radio"
                          id="radio_9"
                          className="radio-col-success"
                          value={"femme"}
                          defaultChecked={gender === "femme"}
                          onClick={(e) => setGender(e.currentTarget.value)}
                        />
                        <label for="radio_9">Femme</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-transparent">
                        <i className="ti-lock" />
                      </span>
                    </div>
                    <input
                      value={password}
                      onChange={(e) => setPassword(e.currentTarget.value)}
                      type="password"
                      className="form-control pl-15 bg-transparent"
                      placeholder="Password"
                    />
                  </div>
                </div>

                <div className="row">
                  {/* /.col */}
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-info margin-top-10"
                    >
                      {t('Continuer')}
                    </button>
                  </div>
                  {/* /.col */}
                </div>
              </form>
              {/* <form onSubmit={onSubmit}>
                <div className="form-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          Nom
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={nom}
                          onChange={(e) => setNom(e.currentTarget.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          prenom
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={prenom}
                          onChange={(e) => setPrenom(e.currentTarget.value)}
                        />
                      </div>
                      <List sx={{ width: "100%", maxWidth: 360 }}>
                        {[...Array(tablesNumber).keys()].map((value) => {
                          const labelId = `checkbox-list-label-${value}`;
                          return (
                            <ListItem key={value} disablePadding>
                              <ListItemButton
                                role={undefined}
                                onClick={handleToggle(value + 1)}
                                dense
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={checked.indexOf(value + 1) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={`Table ${value + 1}`}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </div>
                  </div>
                </div>
                <div className="form-actions mt-10">
                  <Link to="/categories">
                    <button type="button" className="btn btn-primary">
                      Annuler
                    </button>
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-danger left-button"
                  >
                    {" "}
                    <i className="fa fa-check"></i> Ajouter
                  </button>
                </div>
              </form> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default AddWaiter;
