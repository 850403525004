import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { updatePassword } from "../Services/UserApi";

function ReinitialisationPass() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const {token} = useParams();
  const promptForDelete = () => {
    swal({
      title: "Votre mot de passe a ete changé avec succes !",
      icon: "success",
    }).then(function () {
      window.location = "/login";
    });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if(!password || !confirmPassword){
      alert('all fields are required!!');
      return false;
    }

    if(password !== confirmPassword){
      alert("passwords are not identical");
      return;
    }
    updatePassword(token, password).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        promptForDelete();
      }
    });
    

    /*
      1 - check if password is not empty
      2 - check if password and confirmation are identical
      3 - send request to update password using the token
      4 - redirect to login with a success message.
    
    */
  };
  return (
    <div className="container h-p100" style={{ marginTop: "150px" }}>
      <div className="row align-items-center justify-content-md-center h-p100">
        <div className="col-12">
          <div className="row justify-content-center no-gutters">
            <div className="col-lg-5 col-md-5 col-12">
              <div className="bg-white rounded30 shadow-lg">
                <div className="content-top-agile p-20 pb-0">
                  <h3 className="mb-0 text-primary">Recover Password</h3>
                </div>
                <div className="p-40">
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-email"></i>
                          </span>
                        </div>
                        <input
                          type="password"
                          className="form-control pl-15 bg-transparent"
                          placeholder="Nouveau mot de passe"
                          onChange={(e) => setPassword(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-email"></i>
                          </span>
                        </div>
                        <input
                          type="password"
                          className="form-control pl-15 bg-transparent"
                          placeholder="Confirmation mot de passe"
                          onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <button
                          type="submit"
                          className="btn btn-info margin-top-10"
                        >
                          Changer mot de passe
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReinitialisationPass;
