import { QRCodeCanvas } from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getOrganisationById } from "../Services/OrganizationApi";

function TablesQrCodes() {
  const { id } = useParams();
  const [color, setColor] = useState();
  const [logo, setLogo] = useState();
  const [visible, setVisible] = useState("flex");
  const [org, setOrg] = useState();
  const [tables, setTables] = useState();
  const [width, setWidth] = useState("130");
  const [height, setHeight] = useState("198");
  //   const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    getOrganisationById(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
        setTables(res.data.data.tables);
        setLogo(res.data.data.logo);
      }
    });
  }, []);

  const handlePrint = () => {
    setVisible("none");
    window.print();
  };

  return (
    <section className="content" style={{ paddingTop: "0px" }}>
      <div className="print" style={{ width: "10.5cm", display: "contents" }}>
        {Array.from({ length: tables }, (_, i) => i).map((item, index) => (
          <>
            {/* <div className="container" style={{ maxWidth: "360px", margin: "0" }}>
              <div
                className="card"
                style={{
                  backgroundColor: "hsl(0, 0%, 100%)",
                  padding: "18px",
                  borderRadius: "17px",
                  textAlign: "center",
                  margin: "0 1em",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    borderRadius: "12px",
                    backgroundColor: "#35d5cc",
                    padding:'10px',
                    display:'flex',
                    justifyContent:'center'
                  }}
                >
                  <QRCodeCanvas
                    id={"qr" + item}
                    value={
                      document.location.origin +
                      "/menuredirect" +
                      "/" +
                      id +
                      "/" +
                      btoa((item + 1).toString())
                    }
                    size={200}
                    bgColor={"#ffffff"}
                    level={"H"}
                    onClick={(e) =>
                      window
                        .open(
                          document.location.origin +
                            "/menuredirect" +
                            "/" +
                            id +
                            "/" +
                            btoa((item + 1).toString()),
                          "_blank",
                          "tab"
                        )
                        .focus()
                    }
                    imageSettings={{
                      src:
                        "https://via.placeholder.com/100/000000/ffffff/?text=" +
                        (item + 1),
                      x: undefined,
                      y: undefined,
                      height: 20,
                      width: 20,
                      excavate: true,
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className="text" style={{ padding: "22px 10px" }}>
                  <h2>Improve your front-end skills by building projects</h2>
                  <p>
                    Scan the QR code to visit Frontend Mentor and take your
                    coding skills to the next level
                  </p>
                </div>
              </div>
            </div> */}
            <div
              className="overflow-h"
              style={{
                // width: width + "mm",
                // height: height + "mm",
                float: "left",
              }}
            >
              <div
                className="box-body p-0"
                style={{
                  width: width + "mm",
                  height: height + "mm",
                  float: "left",
                  backgroundColor: "white",
                }}
              >
                <div>
                  <div className="">
                    <div
                      className="box-header with-border"
                      style={{ backgroundColor: color, height: "50mm" }}
                    >
                      <div className="row">
                        <h4 className="box-title col-8">
                          <img
                            className="rounded img-fluid"
                            src="/images/logo-long.png"
                            style={{ width: "55mm" }}
                          />
                        </h4>
                        <div className="avatar avatar-lg col-4" href="#">
                          <img
                            className="rounded img-fluid"
                            style={{ width: "60px", height: "60px" }}
                            src={
                              logo
                                ? process.env.REACT_APP_API_BASE_URL + logo
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="box-body"
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      <p>
                        <QRCodeCanvas
                          id={"qr" + item}
                          value={
                            org && org.menu === 5
                              ? document.location.origin +
                                "/menu5" +
                                "/" +
                                id +
                                "/" +
                                btoa((item + 1).toString())
                              : document.location.origin +
                                "/menuredirect" +
                                "/" +
                                id +
                                "/" +
                                btoa((item + 1).toString())
                          }
                          size={300}
                          bgColor={"#ffffff"}
                          level={"H"}
                          onClick={(e) =>
                            window
                              .open(
                                org && org.menu === 5
                                  ? document.location.origin +
                                      "/menu5" +
                                      "/" +
                                      id +
                                      "/" +
                                      btoa((item + 1).toString())
                                  : document.location.origin +
                                      "/menuredirect" +
                                      "/" +
                                      id +
                                      "/" +
                                      btoa((item + 1).toString())
                              )
                              .focus()
                          }
                          imageSettings={{
                            src:
                              "https://via.placeholder.com/100/000000/ffffff/?text=" +
                              (item + 1),
                            x: undefined,
                            y: undefined,
                            height: 40,
                            width: 40,
                            excavate: true,
                          }}
                          style={{ cursor: "pointer" }}
                        />
                        {org && org.wifi !== "" ? (
                          <div
                            className="input-group"
                            style={{
                              // width: size,
                              marginTop: "9px",
                              color: "black",
                              // fontSize: wifiFontSize,
                              marginBottom: "15px",
                              // textAlign: textAlignPrint,
                            }}
                          >
                            <div className="input-group-prepend">
                              <span className="input-group-text bg-transparent">
                                <i
                                  className="fa fa-solid fa-wifi"
                                  // style={{ fontSize: wifiFontSize }}
                                ></i>
                              </span>
                            </div>
                            <div
                              className="form-control"
                              style={{
                                color: "black",
                                backgroundColor: "white",
                                // fontSize: wifiFontSize,
                              }}
                            >
                              {org.wifi}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}

        <div className="right-bar">
          <div id="sidebarRight">
            <div className="right-bar-inner">
              <div className="box">
                <div className="box-header with-border">
                  <h4 className="box-title">Options</h4>
                </div>
                <div className="col-8">
                  <div className="form-group">
                    <label className="col-form-label col-md-2">Thème</label>
                    <div className="col-md-10">
                      <input
                        type="color"
                        name="color-choice"
                        id="color-choice"
                        list="color-options"
                        onChange={(e) => setColor(e.currentTarget.value)}
                      />

                      <datalist id="color-options">
                        <option>#660000</option>
                        <option>#ffffff</option>
                        <option>#ffd265</option>
                        <option>#1a2734</option>
                        <option>#0a7b83</option>
                        <option>#383c3d</option>
                      </datalist>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      {/* <div className="input-group-addon">
                        <i className="fa fa-spoon"></i>
                      </div> */}
                      <input
                        type="number"
                        className="form-control"
                        value={width}
                        placeholder="Nombre des tables"
                        onChange={(e) => setWidth(e.currentTarget.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      {/* <div className="input-group-addon">
                        <i className="fa fa-spoon"></i>
                      </div> */}
                      <input
                        type="number"
                        className="form-control"
                        value={height}
                        placeholder="Nombre des tables"
                        onChange={(e) => setHeight(e.currentTarget.value)}
                      />
                    </div>
                  </div>
                </div>

                <button
                  className="no-print waves-effect btn-primary-light btn-xs mb-5"
                  onClick={(e) => {
                    handlePrint();
                    setVisible(true);
                  }}
                  style={{ display: visible }}
                >
                  imprimer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default TablesQrCodes;
