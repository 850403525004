import { FormControlLabel, Switch } from "@mui/material";
import React, { useState } from "react";
import { FormGroup } from "react-bootstrap";

const Repeater = ({ onChange, defaultValue, ...props }) => {
  const [rows, setRows] = useState(
    defaultValue
      ? defaultValue
      : [
          { key: "Modèle", value: "" },
          { key: "Motorisation", value: "" },
          { key: "Boite", value: "" },
          { key: "Année", value: "" },
          { key: "Carburant", value: "" },
          { key: "Kilomètrage", value: "" },
          { key: "Immatriculation", value: "" },
        ]
  );

  const addRow = (key, value) => {
    const newRow = { key, value };
    setRows([...rows, newRow]);
    onChange([...rows, { key: "", value: "" }]);
  };

  const deleteRow = (indexToDelete) => {
    const updatedRows = rows.filter((_, index) => index !== indexToDelete);
    setRows(updatedRows);
    onChange(updatedRows)
  };

  const [isATitle, setIsATitle] = useState(false);
  return (
    <div>
      {rows.map((row, index) => {
        return (
          <div className="items" key={index}>
            <div
              className="item-content"
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                display: "flex",
                width: "100%",
                marginBottom: "5px",
              }}
            >
              <div className="col-5">
                <input
                  type="text"
                  name={"key" + index}
                  className="form-control"
                  value={row.key}
                  onChange={(e) => {
                    const updatedRows = [...rows];
                    updatedRows[index].key = e.currentTarget.value;
                    setRows(updatedRows);
                  }}
                />
              </div>
              <div className="col-5">
                <textarea
                  disabled={isATitle}
                  type="text"
                  name={"value" + index}
                  className="form-control"
                  value={row.value}
                  onChange={(e) => {
                    const updatedRows = [...rows];
                    updatedRows[index].value = e.currentTarget.value;
                    setRows(updatedRows);
                  }}
                ></textarea>
              </div>
              <div className="col-1">
                <i
                  className="fas fa-times-circle"
                  style={{ color: "#e7515a" }}
                  onClick={() => deleteRow(index)}
                ></i>
              </div>
              {/* <div className="col-1">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isATitle}
                        onChange={(e) => setIsATitle(e.target.checked)}
                      />
                    }
                    label="Titre"
                  />
                </FormGroup>
              </div> */}
            </div>
          </div>
        );
      })}
      <button
        className="btn btn-success"
        style={{ width: "100%" }}
        onClick={() => addRow("", "")}
        type="button"
      >
        <i className="fas fa-plus"></i>
      </button>
    </div>
  );
};

export default Repeater;
