import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../Auth/useUser";
import { useOrganisations } from "../../Auth/useOrganisations";
import { editDemande, getDemandes } from "../../Services/DemandeApi";
import io from "socket.io-client";
import notificationSound from "./notification.mp3";
import { useRef } from "react";


function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // A function that increment ðŸ‘†ðŸ» the previous state like here
  // is better than directly setting setValue(value + 1)
}

function HeaderWaiter() {
  const [isDark, setisDark] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const user = useUser();
  const forceUpdate = useForceUpdate();
  const [infoUser] = useState(user.result);
  const audioPlayer = useRef(null);
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  console.log(organisations);
  /**
   * The function clears the local storage and reloads the page after 2 seconds
   */
  const handleLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      window.location.reload(false);
    }, 2000);
  };

  /**
   * If the user has dark mode enabled, remove the dark-skin class from the body element and set the is_dark local storage item to false. If the user has
   * dark mode disabled, add the dark-skin class to the body element and set the is_dark local storage item to true
   */
  const handleSetDarkMode = () => {
    if (isDark) {
      document.body.classList.remove("dark-skin");
      localStorage.setItem("is_dark", false);
      setisDark(!isDark);
    } else {
      document.body.classList.add("dark-skin");
      localStorage.setItem("is_dark", true);
      setisDark(!isDark);
    }
  };
  const updateNotification = (id) => {
    editDemande(id).then((res) => {
      //silence is god
    });
  };
  useEffect(() => {
    getDemandes(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setNotifications(res.data.data);
      }
    });
  }, []);
  //socketClient
  useEffect(() => {
    const socket_url = process.env.REACT_APP_API;
    const socket = io("https://api.demo.menu.cleges.com/", { transports: ['websocket', 'polling', 'flashsocket'] });
    socket.on("demandeNotification", (data) => {
      getDemandes(usedOrganisations).then((res) => {
        const success = res.data.success;
        if (success === "0") {
          alert("error");
        } else {
          setNotifications(res.data.data);
          window.focus();
          forceUpdate();
        }
      });
    });
  }, []);
  return (
    <header className="main-header" style={{ width: "100%" }}>
      <div className="d-flex align-items-center logo-box justify-content-start">
        <a
          href="#"
          className="waves-effect waves-light nav-link d-none d-md-inline-block mx-10 push-btn bg-transparent hover-primary"
          data-toggle="push-menu"
          role="button"
        >
          <span className="icon-Align-left">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
          </span>
        </a>
        {/* Logo */}
        <a href="/" className="logo" style={{paddingLeft:"0"}}>
          {/* logo*/}
          <div className="logo-lg">
            <span className="light-logo" style={{width:"140px"}}>
              <img src="../images/logo-long-dark.png" alt="logo" />
            </span>
            <span className="dark-logo"  style={{width:"140px"}}>
              <img src="../images/logo-long.png" alt="logo" />
            </span>
          </div>
        </a>
      </div>
      {/* Header Navbar */}
      <nav className="navbar navbar-static-top">
        {/* Sidebar toggle button*/}
        <div className="app-menu">
          <ul className="header-megamenu nav">
            <li className="btn-group nav-item d-md-none">
              <a
                href="#"
                className="waves-effect waves-light nav-link push-btn btn-info-light"
                data-toggle="push-menu"
                role="button"
              >
                <span className="icon-Align-left">
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div className="navbar-custom-menu r-side">
          <ul className="nav navbar-nav">
            <li className="btn-group nav-item d-lg-inline-flex d-none">
              <Link
                to="#"
                onClick={handleSetDarkMode}
                className="waves-effect waves-light nav-link full-screen btn-success-light"
                title="Full Screen"
              >
                <i className="icon-Flashlight">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </Link>
            </li>

            <li className="dropdown notifications-menu">
              <span className="label label-danger">{notifications.length}</span>
              <a
                href="#"
                className="waves-effect waves-light dropdown-toggle btn-danger-light"
                data-toggle="dropdown"
                title="Notifications"
              >
                <i className="icon-Notifications">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </a>
              <ul className="dropdown-menu animated bounceIn">
                <li className="header">
                  <div className="p-20">
                    <div className="flexbox">
                      <div>
                        <h4 className="mb-0 mt-0">Notifications</h4>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <ul className="menu sm-scrol">
                    {notifications &&
                      notifications.map((value, index) => (
                        <li>
                          <a href="#" onClick={(e) => editDemande(value.id)}>
                            <i className="fa fa-users text-info" /> Table{" "}
                            {value.table} a la recherche du serveur
                          </a>
                        </li>
                      ))}
                  </ul>
                </li>
              </ul>
            </li>
            <li className="dropdown user user-menu">
              <a
                href="#"
                className="dropdown-toggle p-0 text-dark hover-primary ml-md-30 ml-10"
                data-toggle="dropdown"
                title="User"
              >
                <span className="pl-30 d-md-inline-block d-none">Salut, </span>{" "}
                <strong className="d-md-inline-block d-none">
                  {infoUser?.nom}
                </strong>
                <img
                  src="../images/avatar/avatar-13.png"
                  className="user-image rounded-circle avatar bg-white mx-10"
                  alt="User Image"
                />
              </a>
              <ul className="dropdown-menu animated flipInX">
                <li className="user-body">
                  {organisations &&
                    organisations.map((value, index) => {
                      return (
                        <Link
                          className={
                            "dropdown-item " +
                            (value.id == usedOrganisations ? "active" : "")
                          }
                          to="#"
                          onClick={(e) => {
                            setUsedOrganisations(value.id);
                            window.location.reload();
                          }}
                        >
                          <i className="ti-wallet text-muted mr-2" />{" "}
                          {value.nom}
                        </Link>
                      );
                    })}

                  <div className="dropdown-divider" />
                  <Link className="dropdown-item" to="#" onClick={handleLogout}>
                    <i className="ti-lock text-muted mr-2" /> Deconnexion
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default HeaderWaiter;
