import axios from "axios";
import { getSession } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API;

/* Setting the header for the axios request. */
const config = {
  headers: { Authorization: `Bearer ${getSession("token")}` },
};
const currentOrg = getSession("usedorganisations");

export async function addSocials(facebook, youtube, instagram, website) {
  const body = {
    facebook: facebook,
    youtube: youtube,
    instagram: instagram,
    website: website,
    organisation_id: currentOrg,
  };

  const httpHeaders = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSession("token")}`,
    },
  };

  try {
    const response = await axios.post(api_url + "socials", body, httpHeaders);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getSocials(id) {
  if(id == null){
    id = currentOrg;
  }
  try {
    const response = await axios.get(api_url + "socials/" + id);
    return response;
  } catch (error) {
    console.error(error);
  }
}
