import React, { useEffect, useState } from "react";


function Galerie() {

  return (
<section className="content">
		  <div id="gallery">
			  <div className="box bg-transparent no-shadow b-0">
				<div className="box-body text-center p-0">
					<div className="btn-group">
					  <button className="btn btn-info" id="filter-all">All</button>
					  <button className="btn btn-info" id="filter-studio">Categories</button>
					  <button className="btn btn-info" id="filter-landscape">Menus</button>
					  <button className="btn btn-info" id="filter-landscape">Ingredients</button>

					</div>
				</div>
			  </div>
			  <div className="box bg-transparent no-shadow b-0">
				<div className="box-body">
					<div id="gallery-content">
						<div id="gallery-content-center">
							<a href="../images/gallery/studio1.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio1.jpg" alt="gallery" className="all studio" /> </a>
							<a href="../images/gallery/landscape1.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape1.jpg" className="all landscape" alt="gallery" /> </a>
							<a href="../images/gallery/studio2.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio2.jpg" className="all studio" alt="gallery" /> </a>
							<a href="../images/gallery/studio25.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio25.jpg" className="all studio" alt="gallery" /> </a>
							<a href="../images/gallery/landscape2.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape2.jpg" className="all landscape" alt="gallery" /></a>
							<a href="../images/gallery/studio27.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio27.jpg" className="all studio" alt="gallery" /> </a>
							<a href="../images/gallery/studio3.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio3.jpg" className="all studio" alt="gallery" /> </a>
							<a href="../images/gallery/landscape3.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape3.jpg" className="all landscape" alt="gallery" /> </a>
							<a href="../images/gallery/studio26.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio26.jpg" className="all studio" alt="gallery" /> </a>
							<a href="../images/gallery/studio4.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio4.jpg" className="all studio" alt="gallery" /> </a>
							<a href="../images/gallery/landscape4.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape4.jpg" className="all landscape" alt="gallery" /></a>
							<a href="../images/gallery/studio5.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio5.jpg" className="all studio" alt="gallery" /> </a>
							<a href="../images/gallery/landscape5.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape5.jpg" className="all landscape" alt="gallery" /></a>
							<a href="../images/gallery/studio6.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio6.jpg" className="all studio" alt="gallery" /></a>
							<a href="../images/gallery/landscape6.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape6.jpg" className="all landscape" alt="gallery" /> </a>
							<a href="../images/gallery/studio7.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio7.jpg" className="all studio" alt="gallery" /></a>
							<a href="../images/gallery/landscape7.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape7.jpg" className="all landscape" alt="gallery" /> </a>
							<a href="../images/gallery/studio8.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio8.jpg" className="all studio" alt="gallery" /></a>
							<a href="../images/gallery/landscape8.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape8.jpg" className="all landscape" alt="gallery" /> </a>
							<a href="../images/gallery/studio9.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio9.jpg" className="all studio" alt="gallery" /></a>
							<a href="../images/gallery/landscape9.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape9.jpg" className="all landscape" alt="gallery" /> </a>
							<a href="../images/gallery/studio10.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio10.jpg" className="all studio" alt="gallery" /></a>
							<a href="../images/gallery/landscape10.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape10.jpg" className="all landscape" alt="gallery" /></a>
							<a href="../images/gallery/studio11.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio11.jpg" className="all studio" alt="gallery" /></a>
							<a href="../images/gallery/landscape11.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape11.jpg" className="all landscape" alt="gallery" /></a>
							<a href="../images/gallery/studio12.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio12.jpg" className="all studio" alt="gallery" /></a>
							<a href="../images/gallery/landscape12.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape12.jpg" className="all landscape" alt="gallery" /></a>
							<a href="../images/gallery/studio13.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio13.jpg" className="all studio" alt="gallery" /> </a>
							<a href="../images/gallery/landscape13.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape13.jpg" className="all landscape" alt="gallery" /></a>
							<a href="../images/gallery/studio14.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio14.jpg" className="all studio" alt="gallery" /></a>
							<a href="../images/gallery/landscape14.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape14.jpg" className="all landscape" alt="gallery" /></a>
							<a href="../images/gallery/studio15.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio15.jpg" className="all studio" alt="gallery" /></a>
							<a href="../images/gallery/landscape15.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape15.jpg" className="all landscape" alt="gallery" /></a>
							<a href="../images/gallery/studio16.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio16.jpg" className="all studio" alt="gallery" /></a>
							<a href="../images/gallery/landscape16.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape16.jpg" className="all landscape" alt="gallery" /></a>
							<a href="../images/gallery/studio17.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio17.jpg" className="all studio" alt="gallery" /></a>
							<a href="../images/gallery/landscape17.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape17.jpg" className="all landscape" alt="gallery" /></a>
							<a href="../images/gallery/studio18.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio18.jpg" className="all studio" alt="gallery" /></a>
							<a href="../images/gallery/landscape18.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/landscape18.jpg" className="all landscape" alt="gallery" /></a>
							<a href="../images/gallery/studio19.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio19.jpg" className="all studio" alt="gallery" /></a>
							<a href="../images/gallery/studio20.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio20.jpg" className="all studio" alt="gallery" /></a>
							<a href="../images/gallery/studio21.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio21.jpg" className="all studio" alt="gallery" /></a>
							<a href="../images/gallery/studio22.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio22.jpg" className="all studio" alt="gallery" /></a>
							<a href="../images/gallery/studio23.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio23.jpg" className="all studio" alt="gallery" /></a>
							<a href="../images/gallery/studio24.jpg" data-toggle="lightbox" data-gallery="multiimages" data-title="Image title will be apear here"><img src="../images/gallery/studio24.jpg" className="all studio" alt="gallery" /></a>
						</div>
					</div>
					</div>
				</div>
		  </div>
		</section>
  )
}
export default Galerie;