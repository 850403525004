import axios from "axios";
import { getSession } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API;

/* Setting the header for the axios request. */
const config = {
  headers: { Authorization: `Bearer ${getSession("token")}` },
};

const currentOrg = getSession("usedorganisations");

export async function addMenu(
  titre,
  description,
  info,
  image,
  category_id,
  prix,
  dispo,
  ordre
) {
  const frmData = new FormData();
  frmData.append("titre[fr]", titre.fr);
  frmData.append("titre[en]", titre.en);
  frmData.append("titre[ar]", titre.ar);
  frmData.append("description[fr]", description.fr);
  frmData.append("description[en]", description.en);
  frmData.append("description[ar]", description.ar);
  info.forEach((item) => {
    frmData.append(`info`, JSON.stringify(item));
  });
  for (let i = 0; i < image.length; i++) {
    frmData.append("image", image[i]);
  }

  frmData.append("category_id", category_id);
  frmData.append("prix", prix);
  frmData.append("dispo", true);
  frmData.append("ordre", ordre);
  frmData.append("organisation_id", currentOrg);

  const httpHeaders = {
    headers: {
      Authorization: `Bearer ${getSession("token")}`,
      "Content-Type": `multipart/form-data; boundary=${frmData._boundary}`,
    },
  };

  try {
    const response = await axios.post(api_url + "menu", frmData, httpHeaders);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function editMenu(
  titre,
  description,
  info,
  image,
  prix,
  dispo,
  ordre,
  id
) {
  const frmData = new FormData();
  frmData.append("titre[fr]", titre.fr);
  frmData.append("titre[en]", titre.en);
  frmData.append("titre[ar]", titre.ar);
  frmData.append("description[fr]", description.fr);
  frmData.append("description[en]", description.en);
  frmData.append("description[ar]", description.ar);
  info.forEach((item) => {
    frmData.append(`info`, JSON.stringify(item));
  });
  for (let i = 0; i < image.length; i++) {
    frmData.append("image", image[i]);
  }
  frmData.append("prix", prix);
  frmData.append("dispo", dispo);
  frmData.append("ordre", ordre);
  frmData.append("id", id);
  frmData.append("organisation_id", currentOrg);

  const httpHeaders = {
    headers: {
      Authorization: `Bearer ${getSession("token")}`,
      "Content-Type": `multipart/form-data; boundary=${frmData._boundary}`,
    },
  };

  try {
    const response = await axios.patch(api_url + "menu", frmData, httpHeaders);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getMenus(id) {
  if (id === null) {
    id = currentOrg;
  }
  try {
    const response = await axios.get(
      api_url + "menu/organisation/" + id,
      config
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getMenusByOrg(id) {
  try {
    const response = await axios.get(
      api_url + "menu/organisation/" + id,
      config
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getMenuById(id) {
  try {
    const response = await axios.get(api_url + "menu/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteMenu(id) {
  try {
    const response = await axios.delete(api_url + "menu/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getMenuByCategoryId(id) {
  try {
    const response = await axios.get(api_url + "menu/categories/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getMenuByCarteId(id_org, id) {
  try {
    const response = await axios.get(
      api_url + "menu/carte/" + id_org + "/" + id
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
