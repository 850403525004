import axios from "axios";
import { getSession } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API;

/* Setting the header for the axios request. */
const config = {
  headers: { Authorization: `Bearer ${getSession("token")}` },
};

const currentOrg = getSession("usedorganisations");

export async function registerScan(id) {
    await axios.post(api_url + "tracking/"+id, config);
}

export async function getScanList() {
    const response = await axios.get(api_url + "tracking/"+currentOrg, config);
    return response;
}