import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SignUp from "./SignUp";
import Inscription from "./Inscription";
import { inscription } from "../Services/UserApi";
import { addOrganization } from "../Services/OrganizationApi";
import Plans from "./Plans";
import ChoixMenu from "./ChoixMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SignUpStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [currentUserId, setCurrentUserID] = React.useState("");
  const [currentOrg, setCurrentOrg] = React.useState({});
  const [user, setUser] = React.useState({
    email: "",
    password: "",
    nom: "",
    prenom: "",
    mobile: "",
    gender: "",
    type: "",
    date_naissance: "",
  });
  React.useEffect(() => {
    document.body.style.backgroundImage = "url(../images/auth-bg/bg-1.jpg)";
  }, []);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      window.location = "/login";
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const registerUser = (
    email,
    password,
    nom,
    prenom,
    mobile,
    gender,
    type,
    date_naissance
  ) => {
    setUser({
      email,
      password,
      nom,
      prenom,
      mobile,
      gender,
      type,
      date_naissance,
    });
    handleNext();
  };
  const registerOrg = (
    nom,
    logo,
    color,
    numero,
    description,
    tables,
    background,
    wifi,
    menu,
    currency,
    paiementmode
  ) => {
    inscription(
      user.nom,
      user.prenom,
      user.email,
      user.password,
      "user",
      user.mobile,
      user.gender,
      user.type,
      user.date_naissance
    ).then((res) => {
      if (res?.data?.code === 200) {
        addOrganization(
          nom,
          logo,
          color,
          numero,
          description,
          res.data.data.id,
          tables,
          background,
          wifi,
          menu,
          currency,
          paiementmode
        ).then((res) => {
          const success = res.data.success;
          if (success === "0") {
            alert("error");
          } else {
            setCurrentOrg(res.data.data);
            console.log("org", res.data.data);
            alert("ajouté avec succees");
            handleNext();
          }
        });
      } else {
        if (res) {
          toast.error(res?.data?.errorMessage);
        } else {
          toast.error("User already exist");
          handleBack();
        }
      }
    });
  };
  const steps = [
    "S'inscrire en tant qu'utilisateur",
    "Ajouter votre organization",
    "Paiement",
  ];
  const stepsContent = [
    <SignUp callback={registerUser} user={user} />,
    <Inscription callback={registerOrg} user={user} />,
    <Plans org={currentOrg} />,
  ];
  return (
    <div
      className="bg-white rounded30 shadow-lg"
      style={{ margin: "5px", padding: "30px" }}
    >
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              {stepsContent[activeStep]}
            </Typography>
          </React.Fragment>
        )}
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          {activeStep < 2 ? (
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </div>
  );
}
