import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../Auth/useUser";

/**
 * If the user is logged in, render the children, otherwise redirect to the login page
 * @returns If the user is logged in, the children are returned. If the user is not logged in, the user is redirected to the login page.
 */
const WaiterRoute = ({ children }) => {
  const user = useUser();
  return user && user.result.role === 'waiter' ? children : <Navigate to="/login" />;
};
export default WaiterRoute;