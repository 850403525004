import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationAR from "./locales/ar/translation.json";

// Add more language translations as needed

const resources = {
    en: {
        translation: translationEN,
    },
    fr: {
        translation: translationFR,
    },
    ar: {
        translation: translationAR,
    },
    
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "fr", // Set the default language here
        fallbackLng: "fr", // Set the fallback language here
        interpolation: {
            escapeValue: false,
        },
    });

export const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
};

export default i18n;
