import React, { useEffect, useState } from "react";

import { getScanList } from "../Services/TrackingApi";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { useOrganisations } from "./../Auth/useOrganisations";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getComments } from "../Services/CommentsApi";
import { Rating } from "react-simple-star-rating";

import { LinearProgress, linearProgressClasses, styled } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { getCarts } from "../Services/CartApi";
import { useUser } from "./../Auth/useUser";
import { getOrganisationById } from "../Services/OrganizationApi";
import GifLoader from "../Components/loader";
import { getMenuById } from "../Services/MenuApi";
import { getTablesDemandes } from "../Services/DemandeApi";
import { useTranslation } from "react-i18next";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#01b075" : "#01b075",
  },
}));

function Tips() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [income, setIncome] = useState(0);
  const [currency, setCurrency] = useState();
  const [loading, setLoading] = useState(true);
  const [tables, setTables] = useState([]);
  const [totalIncome, setTotalIncome] = useState(0);

  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  const user = useUser();
  const [infoUser] = useState(user.result);
  useEffect(() => {
    let tempTable = [];
    let income = 0;
    infoUser.tables.map((table) => {
      tempTable[table] = {
        income: 0,
        table: table,
      };
    });

    const promises = infoUser.tables.map((table) =>
      getTablesDemandes(usedOrganisations, table).then((data) => {
        return data;
      })
    );

    Promise.all(promises)
      .then((results) => {
        results.forEach((res) => {
          const success = res.data.success;
          if (success === "0") {
            alert("error");
          } else {
            if (res.data.data.length)
              tempTable[res.data.data[0].table] = {
                income: res.data.data.reduce((acc, obj) => acc + obj.tips, 0),
                table: res.data.data[0].table,
              };
            income += res.data.data.reduce((acc, obj) => acc + obj.tips, 0);
          }
        });
        setTotalIncome(income);
        setTables(tempTable);
      })
      .catch((error) => {
        console.error(error);
      });

    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setCurrency(res.data.data.currency);
        setLoading(false);
      }
    });
  }, [usedOrganisations]);

  return (
    <div className="row">
      <GifLoader loading={loading} />

      {/* Articles total */}
      <div className="col-xxxl-4 col-lg-6 col-12">
        <div className="box">
          <div className="box-body">
            <div className="d-flex align-items-start">
              <div>
                <img
                  src="../images/food/online-order-4.png"
                  className="w-80 mr-20"
                  alt=""
                />
              </div>
              <div>
                <h2 className="my-0 font-weight-700">
                  {totalIncome.toFixed(2)} {currency}
                </h2>
                <p className="text-fade mb-0">{t("Tips total")}</p>
                <p className="font-size-12 mb-0 text-success">
                  <span className="badge badge-pill badge-success-light mr-5">
                    {/* <i className="fa fa-arrow-up" /> */}
                  </span>
                  {/* 8% (15 Days) */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {tables &&
        tables.map((value, index) => {
          return (
            <div className="col-xxxl-4 col-lg-6 col-12" key={index}>
              <div className="box">
                <div className="box-body">
                  <div className="d-flex align-items-start">
                    <div>
                      <img
                        src="../images/food/online-order-4.png"
                        className="w-80 mr-20"
                        alt=""
                      />
                    </div>
                    <div>
                      <h2 className="my-0 font-weight-700">
                        {value.income.toFixed(2)} {currency}
                      </h2>
                      <p className="text-fade mb-0">
                        Revenue de table {value.table}
                      </p>
                      <p className="font-size-12 mb-0 text-success">
                        <span className="badge badge-pill badge-success-light mr-5"></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })} */}
    </div>
  );
}

export default Tips;
