import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteMenu, getMenus } from "../Services/MenuApi";
import swal from "sweetalert";
import { useOrganisations } from "./../Auth/useOrganisations";
import { getIngredientsByMenu } from "../Services/IngredientsApi";
import { getOrganisationById } from "../Services/OrganizationApi";
import { useUser } from "../Auth/useUser";
import { QRCodeCanvas } from "qrcode.react";
import GifLoader from "../Components/loader";
import { Document, PDFDownloadLink, Page, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

function MenuList() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [filter, setFilter] = useState("");
  const [menus, setMenus] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [displayedMenu, setDisplayedMenu] = useState();
  const [rand, setRand] = useState(0);
  const [currency, setCurrency] = useState();
  const [org, setOrg] = useState();
  const user = useUser();
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("ffffff");
  const [infoUser] = useState(user.result);
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();

  const promptForDelete = (menu_id) => {
    swal({
      title: "Etes-vous sûr ?",
      text: "Une fois supprimé, vous le perdrez !",
      icon: "warning",
      buttons: ["Non", "Oui"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteMenu(menu_id).then((res) => {
          if (res && res.data.success) {
            swal("Pouf ! Votre article a été supprimée !", {
              icon: "success",
            });
            setRand(rand + 1);
          } else {
            swal("Erreur lors de la suppression du menu !", {
              icon: "error",
            });
          }
        });
      } else {
        swal("Votre menu est en sécurité !");
      }
    });
  };

  useEffect(() => {
    getMenus(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        console.log(res.data.data);
        setMenus(res.data.data);
      }
    });
    getIngredientsByMenu(menus.menu_id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        console.log(res.data.data);
        setIngredients(res.data.data);
      }
    });
    getOrganisationById(usedOrganisations).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
        setCurrency(res.data.data.currency);
        setLoading(false);
      }
    });
  }, [rand, usedOrganisations]);

  const styles = {
    page: {
      flexDirection: "row",
      backgroundColor: "#ffffff",
      width: "10.5cm",
      height: "100%",
    },
    content: {
      flexGrow: 1,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "10mm",
    },
  };

  const MyDoc = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.content}>{/* Your content goes here */}</View>
      </Page>
    </Document>
  );

  return (
    <section className="content">
      <GifLoader loading={loading} />
      <div className="row">
        <div className="col-8">
          <form>
            <div className="input-group">
              <input
                type="search"
                className="form-control"
                placeholder="Recherche ..."
                aria-label="Search"
                aria-describedby="button-addon2"
                style={{ padding: "10px", marginTop: "9px" }}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
          </form>
        </div>

        <div className="col-4">
          <Link
            to={"/menus/add"}
            className="btn btn-success mt-10 d-block text-center"
            style={{ right: "0", float: "right", marginBottom: "20px" }}
          >
            <i className="fa fa-plus mr-5"></i>
            {t("Ajouter")}
          </Link>
        </div>
      </div>
      <div className="row">
        {menus &&
          menus.map((value, index) => {
            const titre =
              typeof value.titre === "object" ? value.titre.fr : value.titre;

            return (
              <>
                {titre.toLowerCase().includes(filter.toLowerCase()) ? (
                  <div className="col-xxxl-3 col-xl-4 col-lg-6 col-12">
                    <div className="box food-box">
                      <div className="box-body text-center">
                        <div className="menu-item">
                          <Link to={"/menu/edit/" + value.id}>
                            <div style={{ position: "relative" }}>
                              <img
                                className="rounded img-fluid"
                                src={"../images/product/no-image.png"}
                                style={{
                                  opacity:
                                    value.image && value.image.length
                                      ? "0"
                                      : "100%",
                                }}
                              />
                              {value.image && value.image.length ? (
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "calc(100% + 90px)",
                                    backgroundImage:
                                      "url(" +
                                      process.env.REACT_APP_API_BASE_URL +
                                      value.image[0] +
                                      ")",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                  }}
                                ></div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Link>
                        </div>
                        <div className="menu-details text-center">
                          <Link to={"/menu/edit/" + value.id}>
                            <h4 className="mt-20 mb-10">{titre}</h4>
                          </Link>
                          {/* {org && org.type === "Restaurant" ? (
                            <p>
                              {value.category_id
                                ? "Categorie / " + value.category_id.libelle.fr
                                : "uncategorized"}
                            </p>
                          ) : null} */}
                        </div>
                        <div className="act-btn d-flex justify-content-between">
                          <div className="text-center mx-5">
                            <button
                              className="waves-effect waves-circle btn btn-circle btn-success-light btn-xs mb-5"
                              data-toggle="modal"
                              data-target="#modal-center"
                              onClick={(e) => setDisplayedMenu(value)}
                            >
                              <i className="fa fa-eye"></i>
                            </button>
                            <small className="d-block">Voir</small>
                          </div>
                          <div className="text-center mx-5">
                            <Link
                              to={"/menu/edit/" + value.id}
                              className="waves-effect waves-circle btn btn-circle btn-danger-light btn-xs mb-5"
                            >
                              <i className="fa fa-edit"></i>
                            </Link>
                            <small className="d-block">Modifier</small>
                          </div>
                          <div className="text-center mx-5">
                            <button
                              className="waves-effect waves-circle btn btn-circle btn-primary-light btn-xs mb-5"
                              onClick={(e) => promptForDelete(value.id)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                            <small className="d-block">{t("Delete")}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* <button onClick={(e)=> console.log(value.titre)}>test</button> */}
              </>
            );
          })}
      </div>
      {/* Modal */}
      <div className="modal center-modal fade" id="modal-center" tabindex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t("Details de l'article")}</h5>
              <button type="button" className="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="box box-body b-1 text-center no-shadow">
                    <img
                      src={
                        displayedMenu &&
                        displayedMenu.image &&
                        displayedMenu.image.length
                          ? process.env.REACT_APP_API_BASE_URL +
                            displayedMenu.image[0]
                          : "../images/product/no-image.png"
                      }
                      id="product-image"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="col-md-8 col-sm-6">
                  <h2 className="box-title mt-0">
                    {displayedMenu && displayedMenu.titre.fr}
                  </h2>
                  {displayedMenu && displayedMenu.prix !== 0 ? (
                    <h1 className="pro-price mb-0 mt-20">
                      {displayedMenu && displayedMenu.prix} {currency}
                    </h1>
                  ) : null}

                  <p>{displayedMenu && displayedMenu.description.fr}</p>
                  {org && org.type === "Showroom" ? (
                    <div className="row d-print-none">
                      <div className="col-8">
                        <div className="form-group">
                          <label className="col-form-label col-md-2">
                            {t("Thème")}
                          </label>
                          <div className="col-md-10">
                            <input
                              type="color"
                              name="color-choice"
                              id="color-choice"
                              list="color-options"
                              onChange={(e) => setColor(e.currentTarget.value)}
                            />

                            <datalist id="color-options">
                              <option>#660000</option>
                              <option>#ffffff</option>
                              <option>#ffd265</option>
                              <option>#1a2734</option>
                              <option>#0a7b83</option>
                              <option>#383c3d</option>
                            </datalist>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {displayedMenu &&
                  (org.type === "Showroom" || org.type === "Events") ? (
                    <>
                      <div className="print">
                        <div className="overflow-h">
                          <div className="box-body p-0">
                            <div>
                              <div
                                className=""
                                style={{ backgroundColor: "white" }}
                              >
                                <div
                                  className="box-header with-border"
                                  style={{ backgroundColor: color }}
                                >
                                  <div className="row">
                                    <h4 className="box-title col-8">
                                      <img
                                        className="rounded img-fluid"
                                        src="/images/logo-long.png"
                                        style={{ width: "55mm" }}
                                      />
                                    </h4>
                                    <div
                                      className="avatar avatar-lg col-4"
                                      href="#"
                                    >
                                      <img
                                        className="rounded img-fluid"
                                        src={
                                          process.env.REACT_APP_API_BASE_URL +
                                          org.logo
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="box-body"
                                  style={{
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                >
                                  <p>
                                    <QRCodeCanvas
                                      id="qr-code-canvas"
                                      value={
                                        document.location.origin +
                                        "/menu3" +
                                        "/" +
                                        usedOrganisations +
                                        "/" +
                                        displayedMenu.id
                                      }
                                      size={200}
                                      bgColor={"#ffffff"}
                                      level={"H"}
                                      onClick={(e) =>
                                        window
                                          .open(
                                            document.location.origin +
                                              "/menu3" +
                                              "/" +
                                              usedOrganisations +
                                              "/" +
                                              displayedMenu.id,
                                            "_blank",
                                            "tab"
                                          )
                                          .focus()
                                      }
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <Link
                        to={
                          "/qrcode/" +
                          usedOrganisations +
                          "/" +
                          displayedMenu.id +
                          "/" +
                          color.substring(1)
                        }
                      >
                        Impression
                      </Link> */}
                      <button
                        className="waves-effect btn-primary-light btn-xs mb-5"
                        style={{ float: "right", marginTop: "10px" }}
                        onClick={(e) => {
                          window.location.href =
                            "/qrcode/" +
                            usedOrganisations +
                            "/" +
                            displayedMenu.id +
                            "/" +
                            color.substring(1);
                        }}
                      >
                        {t("Impression")}
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer modal-footer-uniform">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              {org && org.type === "Restaurant" ? (
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={(e) => {
                    window.location.href = "/happyhour/" + displayedMenu.id;
                  }}
                >
                  {t("Happy Hour !")}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* /.modal */}
    </section>
  );
}
export default MenuList;
