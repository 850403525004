import axios from "axios";
import { getSession } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API;

/* Setting the header for the axios request. */
const config = {
  headers: { Authorization: `Bearer ${getSession("token")}` },
};

//to add in every api
const currentOrg = getSession("usedorganisations");

export async function addDemande(table, tips, paymentmode, organisation_id) {
  const body = {
    table: table,
    tips: tips,
    paymentmode: paymentmode,
    organisation_id: organisation_id,
  };
  const httpHeaders = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(api_url + "demande", body, httpHeaders);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function editDemande(id) {
  const body = {};
  const httpHeaders = {
    Authorization: `Bearer ${getSession("token")}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.patch(
      api_url + "demande/" + id,
      body,
      httpHeaders
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getDemandes(id) {
  if (id == null) {
    id = currentOrg;
  }
  try {
    const response = await axios.get(api_url + "demande/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getTablesDemandes(id , table) {
  if (id == null) {
    id = currentOrg;
  }
  try {
    const response = await axios.get(api_url + "demande/" + id +'/' + table, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}