import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../Auth/useUser";

/**
 * If the user is logged in, redirect to the home page, otherwise render the children
 * @returns If the user is logged in, the user is redirected to the home page. If the user is not logged in, the children are rendered.
 */
function PublicRoute({ children }) {
  const user = useUser();
  return user && user.result.role === 'user' ? <Navigate to="/" /> : user && user.result.role === 'waiter' ? <Navigate to='/receptionorders/org_id' /> : children;
}

export default PublicRoute;
