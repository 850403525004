import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function TipsPopUp({ confirm, ...props }) {
  const [open, setOpen] = React.useState(false);
  const [tip, setTip] = React.useState(2);
  const [disabled, setDisabled] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    confirm(0);
    setOpen(false);
    setTip("");
  };
  const handleAgree = () => {
    // send tips to menu component
    if (tip !== "" && !isNaN(tip)) {
      confirm(parseFloat(tip));
    } else {
      confirm(tip);
    }
    setDisabled(true);
    setOpen(false);
    setTip("");
  };

  return (
    <div>
      <Button
        className="waves-effect waves-light btn btn-rounded btn-warning mb-5"
        variant="outlined"
        onClick={handleClickOpen}
        disabled={disabled}
      >
        <i class="fa-solid fa-bell-concierge mr-2"></i>
        Demander un serveur
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ color: "black", width: "80vw", textAlign: "center" }}
        >
          {"Voulez-vous ajouter des tips ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p class="text-fade w-p85 mx-auto">
              <div
                class="input-group"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <ul class="icolors">
                  <li
                    className={
                      "bg-warning rounded-circle " +
                      (tip === 2 ? " checked" : "")
                    }
                    style={{
                      width: "40px",
                      height: "40px",
                      lineHeight: "15px",
                      cursor: "pointer",
                      border: "2px solid transparent",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={(e) => setTip(2)}
                  >
                    2
                  </li>
                  <li
                    className={
                      "bg-warning rounded-circle " +
                      (tip === 5 ? " checked" : "")
                    }
                    style={{
                      width: "40px",
                      height: "40px",
                      lineHeight: "15px",
                      cursor: "pointer",
                      border: "2px solid transparent",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={(e) => setTip(5)}
                  >
                    5
                  </li>
                  <li
                    className={
                      "bg-warning rounded-circle " +
                      (tip === 7 ? " checked" : "")
                    }
                    style={{
                      width: "40px",
                      height: "40px",
                      lineHeight: "15px",
                      cursor: "pointer",
                      border: "2px solid transparent",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={(e) => setTip(7)}
                  >
                    7
                  </li>
                  <li
                    className={
                      "bg-warning rounded-circle" +
                      (tip === 10 ? " checked" : "")
                    }
                    style={{
                      width: "40px",
                      height: "40px",
                      lineHeight: "15px",
                      cursor: "pointer",
                      border: "2px solid transparent",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={(e) => setTip(10)}
                  >
                    10
                  </li>
                </ul>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    className="waves-effect waves-circle btn btn-circle btn-primary-light btn-xs"
                    onClick={() => setTip(tip - 1)}
                    disabled={tip <= 2}
                    style={{ width: "40px", height: "30px" }}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    className="form-control text-center"
                    min={2}
                    value={tip}
                    onChange={(e) => setTip(e.target.value)}
                    style={{
                      backgroundColor: "white",
                      color: "#737373",
                      border: "0.1px solid #d4d4d4",
                      margin: "3px 5px",
                    }}
                  />
                  <button
                    className="waves-effect waves-circle btn btn-circle btn-primary-light btn-xs"
                    onClick={() => setTip(tip + 1)}
                    style={{ width: "40px", height: "30px" }}
                  >
                    +
                  </button>
                </div>
              </div>
            </p>
            {/*  */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Non</Button>
          <Button onClick={handleAgree} autoFocus>
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
