import React, { useEffect, useState } from "react";


function Abonnement() {
  return (
    <section className="content">
      <div className="row">
        <div className="col-lg-12">
          <div className="box">
            <div className="box-body ribbon-box">
              <div className="ribbon ribbon-dark">Dark Ribbon</div>
              <p className="mb-0">
                Quisque nec turpis at urna dictum luctus. Suspendisse convallis
                dignissim eros at volutpat. In egestas mattis dui. Aliquam
                mattis dictum aliquet. Nulla sapien mauris, eleifend et sem ac,
                commodo dapibus odio.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Abonnement;
