import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { editCategorie, getCategorieById } from "../Services/CategrieApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GifLoader from "../Components/loader";
import { editCarte, getCarteById } from "../Services/CartesApi";
import { useTranslation } from "react-i18next";

function EditCarte() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  let id = useParams();
  const [currentCategory, setCurrentCategory] = useState(null);
  const [logo, setLogo] = useState(null);
  const defaultLibelle = {
    fr:
      currentCategory && typeof currentCategory.libelle === "object"
        ? currentCategory.libelle.fr
        : "",
    en:
      currentCategory && typeof currentCategory.libelle === "object"
        ? currentCategory.libelle.en
        : "",
    ar:
      currentCategory && typeof currentCategory.libelle === "object"
        ? currentCategory.libelle.ar
        : "",
  };
  const [libelle, setLibelle] = useState(defaultLibelle);
  const [loading, setLoading] = useState(true);
  const promptForDelete = () => {
    swal({
      title: "Pouf ! Votre catégorie a été mise à jour",
      icon: "success",
    }).then(function () {
      window.location = "/categories";
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // Validate libelle
    if (!libelle) {
      toast.error("Veuillez entrer un nom de catégorie valide.");
      return false;
    }
    //@ToDo: Test all input errors here.
    editCarte(libelle, logo, id.id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        promptForDelete();
      }
    });
    return false;
  };

  useEffect(() => {
    getCarteById(id.id).then((res) => {
      const success = res.data.success;
      if (success === false) {
        //error
        alert("error");
      } else {
        setCurrentCategory(res.data.data);
        setLibelle(res.data.data.libelle);
        setLoading(false);
      }
    });
  }, [id]);
  return (
    <section className="content">
      <GifLoader loading={loading} />
      <div className="row">
        <div className="col-12">
          <div className="box">
            <div className="box-body">
              <form onSubmit={onSubmit}>
                <div className="form-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          {t("Titre")}
                        </label>
                        <input
                          value={libelle.fr}
                          onChange={(e) =>
                            setLibelle({ ...libelle, fr: e.target.value })
                          }
                          type="text"
                          className="form-control"
                          placeholder="Libelle (fr)"
                        />
                        <input
                          value={libelle.en}
                          onChange={(e) =>
                            setLibelle({ ...libelle, en: e.target.value })
                          }
                          type="text"
                          className="form-control"
                          placeholder="Libelle (en)"
                        />
                        <input
                          value={libelle.ar}
                          onChange={(e) =>
                            setLibelle({ ...libelle, ar: e.target.value })
                          }
                          type="text"
                          className="form-control"
                          placeholder="Titre (ar)"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h4 className="box-title mt-20">{t("Image")}</h4>
                      <div className="product-img text-left">
                        <img
                          src={
                            logo
                              ? URL.createObjectURL(logo)
                              : currentCategory && currentCategory.logo
                              ? process.env.REACT_APP_API_BASE_URL +
                                currentCategory.logo
                              : "/../images/product/no-image.png"
                          }
                          alt=""
                          className="mb-15"
                        />
                        <p>Changer l'image</p>
                        <div className="btn btn-info mb-20 choose-file">
                          <input
                            type="file"
                            name="logo"
                            className="upload"
                            onChange={(e) => setLogo(e.currentTarget.files[0])}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-actions mt-10">
                  <Link to="/categories">
                    <button type="button" className="btn btn-primary">
                      Annuler
                    </button>
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-danger left-button"
                    onClick={(e) => promptForDelete()}
                  >
                    {" "}
                    <i className="fa fa-check"></i> {t("Enregistrer")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default EditCarte;
