import React, { useEffect, useState } from "react";
import "./MenusStyle/menu2.css";
import { useOrganisations } from "../Auth/useOrganisations";
import { getMenuById, getMenus } from "../Services/MenuApi";
import { Link, useParams } from "react-router-dom";
import { getOrganisationById } from "../Services/OrganizationApi";
import { registerScan } from "../Services/TrackingApi";
import { Rating } from "react-simple-star-rating";
import swal from "sweetalert";

import { addProductComment } from "../Services/CommentsApi";
import { getSocials } from "../Services/SocialsApi";
import {
  Button,
  CssBaseline,
  Drawer,
  Skeleton,
  styled,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { addCart } from "../Services/CartApi";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import props from "prop-types";
import { Global } from "@emotion/react";
import { Swal } from "sweetalert2";
import Facebook from "./MenusStyle/facebook.png";
import Instagram from "./MenusStyle/instagram.png";
import Youtube from "./MenusStyle/youtube.png";
import Website from "./MenusStyle/internet.png";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // A function that increment ðŸ‘†ðŸ» the previous state like here
  // is better than directly setting setValue(value + 1)
}

function Menu3() {
  const { id, produit } = useParams(); //id org from url
  const [product, setProduct] = useState([]);
  const [categoryListing, setCategoryListing] = useState([]);
  const [categories, setCategories] = useState([]);
  const [org, setOrg] = useState();
  const [comment, setComment] = useState();
  const [star, setStar] = useState(1);
  const [infoArray, setInfoArray] = useState([]);
  const [theme, setTheme] = useState();
  const [currentImage, setCurrentImage] = useState(
    "../images/product/no-image.png"
  );
  const [socials, setSocials] = useState({
    facebook: "",
    youtube: "",
    instagram: "",
    website: "",
  });

  const promptForDelete = () => {
    swal({
      title: "Nous avons bien reçu votre avis !",
      icon: "success",
    }).then(function () {
      // window.location = "/categories";
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    //@ToDo: Test all input errors here.
    addProductComment(comment, star, produit, id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        promptForDelete();
      }
    });
    return false;
  };

  const handleRating = (rate) => {
    setStar(rate);
  };
  const [
    organisations,
    setOrganisations,
    usedOrganisations,
    setUsedOrganisations,
  ] = useOrganisations();
  let initialized = false;

  useEffect(() => {
    setUsedOrganisations(id);
    if (!initialized) {
      registerScan(id);
      initialized = true;
    }
    getOrganisationById(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setOrg(res.data.data);
        setTheme(res.data.data.color);
      }
    });
    getSocials(id).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setSocials(res.data.data);
      }
    });
    //get menu by id menu
    getMenuById(produit).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setProduct(res.data.data);
        if (res.data.data.image.length) {
          setCurrentImage(
            process.env.REACT_APP_API_BASE_URL + res.data.data.image[0]
          );
        }
        setInfoArray(res.data.data.info);
      }
    });
  }, []);

  return (
    <div className="" style={{ width: "100%" }}>
      <div className="box" style={{ marginBottom: "0px" }}>
        <div className="box-body" style={{ backgroundColor: theme }}>
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div
                className="box box-body b-1 text-center no-shadow"
                style={{ backgroundColor: "white" }}
              >
                <img
                  src={currentImage}
                  id="product-image"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="pro-photos">
                {product.image &&
                  product.image.map((value, index) => (
                    <div className="photos-item item-active">
                      <img
                        src={process.env.REACT_APP_API_BASE_URL + value}
                        alt=""
                        onClick={(e) => {
                          setCurrentImage(
                            process.env.REACT_APP_API_BASE_URL + value
                          );
                        }}
                      />
                    </div>
                  ))}
              </div>
              <div className="clear"></div>
            </div>
            <div className="col-md-8 col-sm-6">
              <h2
                className="product-title"
                style={{ color: theme === "#ffffff" ? "black" : "white" }}
              >
                {product && product.titre && product.titre.fr}
              </h2>

              {/* to do reviews of product */}
              {product && product.prix ? (
                <h1
                  className="pro-price mb-0 mt-20"
                  style={{ color: theme === "#ffffff" ? "black" : "white" }}
                >
                  {product && product.prix} {org && org.currency}
                  {/* <span className="old-price">&#36;540</span>
                    <span className="text-danger">50% off</span> */}
                </h1>
              ) : (
                <></>
              )}

              <p style={{ color: theme === "#ffffff" ? "black" : "white" }}>
                {product && product.description && product.description.fr}
              </p>
              {/* <div className="row">
                    <div className="col-sm-12">
                      <h6>Color</h6>
                      <div className="input-group">
                        <ul className="icolors">
                          <li className="bg-danger rounded-circle"></li>
                          <li className="bg-info rounded-circle"></li>
                          <li className="bg-primary rounded-circle active"></li>
                        </ul>
                      </div>
                      <h6 className="mt-20">Available Size</h6>
                      <p className="mb-0">
                        <span className="badge badge-pill badge-lg badge-default">
                          S
                        </span>
                        <span className="badge badge-pill badge-lg badge-default">
                          M
                        </span>
                        <span className="badge badge-pill badge-lg badge-default">
                          L
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="gap-items">
                    <button className="btn btn-success">
                      <i className="mdi mdi-shopping"></i> Buy Now!
                    </button>
                    <button className="btn btn-primary">
                      <i className="mdi mdi-cart-plus"></i> Add To Cart
                    </button>
                    <button className="btn btn-info">
                      <i className="mdi mdi-compare"></i> Compare
                    </button>
                    <button className="btn btn-danger">
                      <i className="mdi mdi-heart"></i> Wishlist
                    </button>
                  </div>
                  <h4 className="box-title mt-20">Key Highlights</h4>
                  <ul className="list-icons list-unstyled">
                    <li>
                      <i className="fa fa-check text-danger float-none"></i> Party
                      Wear
                    </li>
                    <li>
                      <i className="fa fa-check text-danger float-none"></i> Nam
                      libero tempore, cum soluta nobis est
                    </li>
                    <li>
                      <i className="fa fa-check text-danger float-none"></i> Omnis
                      voluptas as placeat facere possimus omnis voluptas.
                    </li>
                  </ul> */}
            </div>
            {infoArray && infoArray.length ? (
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h4
                  className="mt-40"
                  style={{ color: theme === "#ffffff" ? "black" : "white" }}
                >
                  Informations Générales
                </h4>
                <div className="table-responsive">
                  <table
                    className="table"
                    style={{ color: theme === "#ffffff" ? "black" : "white" }}
                  >
                    <tbody>
                      {infoArray &&
                        infoArray.map((value, index) => (
                          <tr>
                            <td
                              colSpan={value.value ? 1 : 2}
                              style={{
                                whiteSpace: "unset",
                                fontWeight: "bold",
                              }}
                            >
                              {value.key}
                            </td>
                            {value.value ? (
                              <td style={{ whiteSpace: "unset" }}>
                                {" "}
                                {value.value}
                              </td>
                            ) : (
                              <></>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
            <footer
              className="footer"
              style={{ margin: "0px 70px 19px 70px", textAlign: "center" }}
            >
              <form onSubmit={onSubmit}>
                <div className="mx-auto mt-20">
                  <h3 className="text-uppercase text-white">Votre avis</h3>
                  <div>
                    <Rating
                      onClick={handleRating}
                      initialValue={1}
                      ratingValue={star}
                      size={20}
                      label
                      transition
                      fillColor="orange"
                      emptyColor="gray"
                      className="foo" // Will remove the inline style if applied
                      value={star}
                      onChange={(e) => setStar(e.currentTarget.value)}
                    />
                    {/* Use rating value */}
                  </div>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control p-10"
                      placeholder="Pensez-vous......"
                      value={comment}
                      onChange={(e) => setComment(e.currentTarget.value)}
                      style={{ backgroundColor: "white" }}
                    />
                    {/* {comment === !null ? setSendReviewButton(true): setSendReviewButton(false)} */}

                    <div className="input-group-append">
                      <button type="submit" className="btn btn-success">
                        Envoyer
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <div className="social-media mt-10">
                {/* {JSON.stringify(socials)} */}
                <nav>
                  <Link
                    onClick={(e) => (window.location.href = socials.facebook)}
                    className="waves-effect waves-circle btn btn-circle btn-xs m-5"
                  >
                    <img src={Facebook} />
                  </Link>
                  <Link
                    onClick={(e) => (window.location.href = socials.instagram)}
                    className="waves-effect waves-circle btn btn-circle btn-xs m-5"
                  >
                    <img src={Instagram} />{" "}
                  </Link>
                  <Link
                    onClick={(e) => (window.location.href = socials.youtube)}
                    className="waves-effect waves-circle btn btn-circle btn-xs m-5"
                  >
                    <img src={Youtube} />{" "}
                  </Link>
                  <Link
                    onClick={(e) => (window.location.href = socials.website)}
                    className="waves-effect waves-circle btn btn-circle btn-xs m-5"
                  >
                    <img src={Website} />{" "}
                  </Link>
                </nav>
              </div>

              <div className="copyright">
                <p>© 2023 SCANINY. Tous droits réservés.</p>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Menu3;
