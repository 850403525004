import axios from "axios";
import { getSession } from "../Utils/SessionUtils";

const api_url = process.env.REACT_APP_API;

/* Setting the header for the axios request. */
const config = {
  headers: { Authorization: `Bearer ${getSession("token")}` },
};

const currentOrg = getSession("usedorganisations");

export async function addIngredient(titre, prix, menu_id) {
  const body = {
    titre: titre,
    prix: prix,
    menu_id: menu_id,
  };
  const httpHeaders = {
    headers: {
      Authorization: `Bearer ${getSession("token")}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.post(
      api_url + "ingredients",
      body,
      httpHeaders
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function editIngredient(titre, prix, menu_id, id) {
  const frmData = new FormData();
  frmData.append("titre", titre);
  frmData.append("prix", prix);
  frmData.append("menu_id", menu_id);
  frmData.append("id", id);

  const httpHeaders = {
    headers: {
      Authorization: `Bearer ${getSession("token")}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.patch(
      api_url + "ingredients",
      frmData,
      httpHeaders
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getIngredientsByMenu(menu_id) {
  try {
    const response = await axios.get(
      api_url + "ingredients/menu/" + menu_id,
      config
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getIngredientById(id) {
  try {
    const response = await axios.get(api_url + "ingredients/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getAllIngredients(id) {
  try {
    const response = await axios.get(api_url + "ingredients/organisation/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteIngredient(id) {
  try {
    const response = await axios.delete(api_url + "ingredients/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
