import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useToken } from "../Auth/useToken";
import { getUserOrganisations } from "../Services/OrganizationApi";
import { login } from "../Services/UserApi";
import { useOrganisations } from "./../Auth/useOrganisations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const navigate = useNavigate();
  const [token, setToken] = useToken();
  const [organisations, setOrganisations] = useOrganisations();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const notify = () => toast.error("Email / mot de passe invalide !");

  /* A react hook that is used to perform side effects in function components. */
  useEffect(() => {
    document.body.style.backgroundImage = "url(../images/auth-bg/bg-1.jpg)";
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    if (email != null || password != null) {
      login(email, password).then((res) => {
        //vérifier si l'email et mot pass incorrect
        //vérifier si actif ou non
        //verfier si compte vérifier ou non
        const token = res?.data?.data?.token;
        const success = res?.data?.success;
        console.log(res);
        if (res?.data?.code === 200) {
          setToken(token);
          getUserOrganisations(res?.data?.data?.user?.id).then((res2) => {
            setOrganisations(JSON.stringify(res2?.data?.data));
          });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(res?.data?.errorMessage);
          // notify();
          // alert("error");
        }
      });
    } else {
      alert("veuillez remplir tous les champs");
    }
  };
  return (
    <div className="container h-p100" style={{ marginTop: "150px" }}>
      <div className="row align-items-center justify-content-md-center h-p100">
        <div className="col-12">
          <div className="row justify-content-center no-gutters">
            <div className="col-lg-5 col-md-5 col-12">
              <div className="bg-white rounded30 shadow-lg">
                <div className="content-top-agile p-20 pb-0">
                  <h2 className="text-primary">Se connecter</h2>
                  <p className="mb-0">
                    Connectez-vous pour continuer sur Clediss QR MENU
                  </p>
                </div>

                <div className="p-40">
                  <form onSubmit={handleLogin}>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-user" />
                          </span>
                        </div>
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="text"
                          className="form-control pl-15 bg-transparent"
                          placeholder="email"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text  bg-transparent">
                            <i className="ti-lock" />
                          </span>
                        </div>
                        <input
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          className="form-control pl-15 bg-transparent"
                          placeholder="Mot de passe"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="checkbox">
                          <input type="checkbox" id="basic_checkbox_1" />
                          <label htmlFor="basic_checkbox_1">
                            Souviens-toi de moi
                          </label>
                        </div>
                      </div>
                      {/* /.col */}
                      <div className="col-6">
                        <div className="fog-pwd text-right">
                          <Link
                            to={"/forgot-password"}
                            className="hover-warning"
                          >
                            <i className="ion ion-locked" /> Mot de passe
                            oublié?
                          </Link>
                          <br />
                        </div>
                      </div>
                      {/* /.col */}
                      <div className="col-12 text-center">
                        <button type="submit" className="btn btn-danger mt-10">
                          Continuer
                        </button>
                      </div>
                      {/* /.col */}
                    </div>
                  </form>
                  <div className="text-center">
                    <p className="mt-15 mb-0">
                      Vous n'avez pas de compte ?{" "}
                      <Link to="/inscription" className="text-warning ml-5">
                        S'inscrire
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
