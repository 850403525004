import { useState } from "react";

export const useToken = () => {
  /* A function that returns a token from local storage. */
  const [token, setTokenInternal] = useState(() => {
    return localStorage.getItem("token");
  });

  /**
   * It sets the token in local storage and then sets the token in the internal state.
   * @param newToken - The new token to be set.
   */
  const setToken = (newToken) => {
    localStorage.setItem("token", newToken);
    setTokenInternal(newToken);
  };
  return [token, setToken];
};
