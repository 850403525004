import React from "react";
import { ToastContainer } from "react-toastify";

document.body.classList.remove("pg-auth");

function EmptyLayout(props) {
  return (
    <div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <React.Fragment>{props.children}</React.Fragment>
    </div>
  );
}

export default EmptyLayout;
