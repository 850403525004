import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SignUp({ callback, user, ...props }) {
  const [nom, setNom] = useState(user.nom);
  const [prenom, setPrenom] = useState(user.prenom);
  const [password, setPassword] = useState(user.password);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [email, setEmail] = useState(user.email);
  const [mobile, setMobile] = useState(user.mobile);
  const [gender, setGender] = useState(user.gender ? user.gender : "homme");
  useEffect(() => {});
  const onSubmit = async (e) => {
    e.preventDefault();
    // Validate email
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(email)) {
      toast.error("Email invalide !!");
      return false;
    }

    // Validate password
    if (password.length < 8) {
      toast.error("Mot de passe doit être de 8 caractères minimum !! ");
      return false;
    }
    // Validate password
    if (passwordConfirm !== password) {
      toast.error("Les deux mots de passe sont pas identique !");
      return false;
    }

    // Validate mobile number
    const mobileRegex = /^\d{8}$/;
    if (!mobileRegex.test(mobile)) {
      toast.error("Mobile invalide !! ");
      return false;
    }

    // Validate other fields as needed
    if (!nom || !prenom || !gender) {
      toast.error("S'il-vous-plaît remplissez tous les champs requis.");
      return false;
    }
    callback(email, password, nom, prenom, mobile, gender);
    return false;
  };

  useEffect(() => {
    document.body.style.backgroundImage = "url(../images/auth-bg/bg-1.jpg)";
  }, []);

  return (
    <div className="container h-p100" style={{ marginTop: "50px" }}>
      <div className="row align-items-center justify-content-md-center h-p100">
        <div className="col-12">
          <div className="row justify-content-center no-gutters">
            <div className="col-lg-5 col-md-5 col-12">
              <div className="bg-white rounded30 shadow-lg">
                <div className="content-top-agile p-20 pb-0">
                  <h2 className="text-primary">Commencez avec nous</h2>
                  <p className="mb-0">Enregistrez une nouvelle adhésion</p>
                </div>
                <div className="p-40">
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-user" />
                          </span>
                        </div>
                        <input
                          value={nom}
                          onChange={(e) => setNom(e.currentTarget.value)}
                          type="text"
                          className="form-control pl-15 bg-transparent"
                          placeholder="Nom"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-user" />
                          </span>
                        </div>
                        <input
                          value={prenom}
                          onChange={(e) => setPrenom(e.currentTarget.value)}
                          type="text"
                          className="form-control pl-15 bg-transparent"
                          placeholder="Prénom"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-email" />
                          </span>
                        </div>
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.currentTarget.value)}
                          type="email"
                          className="form-control pl-15 bg-transparent"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-addon">
                          <i className="fa fa-phone"></i>
                        </div>
                        <input
                          value={mobile}
                          onChange={(e) => setMobile(e.currentTarget.value)}
                          type="text"
                          className="form-control"
                          placeholder="99 99 99 99"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="demo-radio-button">
                          <div className="demo-radio-button">
                            <input
                              name="group4"
                              type="radio"
                              id="radio_7"
                              className="radio-col-primary"
                              value={"homme"}
                              onClick={(e) => setGender(e.currentTarget.value)}
                              defaultChecked={gender === "homme"}
                            />
                            <label for="radio_7">Homme</label>
                            <input
                              name="group4"
                              type="radio"
                              id="radio_9"
                              className="radio-col-success"
                              value={"femme"}
                              defaultChecked={gender === "femme"}
                              onClick={(e) => setGender(e.currentTarget.value)}
                            />
                            <label for="radio_9">Femme</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-lock" />
                          </span>
                        </div>
                        <input
                          value={password}
                          onChange={(e) => setPassword(e.currentTarget.value)}
                          type="password"
                          className="form-control pl-15 bg-transparent"
                          placeholder="Mot de passe"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-lock" />
                          </span>
                        </div>
                        <input
                          value={passwordConfirm}
                          onChange={(e) => setPasswordConfirm(e.currentTarget.value)}
                          type="password"
                          className="form-control pl-15 bg-transparent"
                          placeholder="Confirmer votre mot de passe"
                        />
                      </div>
                    </div>
                    <div className="row">
                      {/* /.col */}
                      <div className="col-12 text-center">
                        <button
                          type="submit"
                          className="btn btn-info margin-top-10"
                        >
                          Continuer
                        </button>
                      </div>
                      {/* /.col */}
                    </div>
                  </form>
                  <div className="text-center">
                    <p className="mt-15 mb-0">
                      Vous avez déjà un compte?
                      <Link to="/login" className="text-danger ml-5">
                        {" "}
                        Se connecter
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
