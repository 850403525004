import React, { useEffect, useState } from "react";

// import { useToken } from "../Auth/useToken";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { addSocials, getSocials } from "../Services/SocialsApi";
import GifLoader from "../Components/loader";
import { useTranslation } from "react-i18next";

function Socials() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [facebook, setFacebook] = useState();
  const [youtube, setYoutube] = useState();
  const [instagram, setInstagram] = useState();
  const [website, setWebsite] = useState();
  const [loading, setLoading] = useState(true);
  const [socials, setSocials] = useState({
    facebook: "",
    youtube: "",
    instagram: "",
    website: "",
  });

  useEffect(() => {
    getSocials(null).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        setSocials(res.data.data);
        setFacebook(res.data.data.facebook);
        setInstagram(res.data.data.instagram);
        setYoutube(res.data.data.youtube);
        setWebsite(res.data.data.website);
        setLoading(false);
      }
    });
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    // const promptForDelete = () => {
    //   swal({
    //     title: "Pouf ! Votre catégorie a été ajoutée avec succès !",
    //     icon: "success",
    //   }).then(function () {
    //     window.location = "/categories";
    //   });
    // };
    //@ToDo: Test all input errors here.
    addSocials(facebook, youtube, instagram, website).then((res) => {
      const success = res.data.success;
      if (success === "0") {
        alert("error");
      } else {
        // alert("ajouté avec succees");
        // promptForDelete();
      }
    });
    return false;
  };
  return (
    <section className="content">
      <GifLoader loading={loading} />
      <div className="row">
        <div className="col-12">
          <div className="box">
            <div className="box-body">
              <form onSubmit={onSubmit}>
                <div className="form-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          Facebook
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={facebook}
                          onChange={(e) => setFacebook(e.currentTarget.value)}
                          defaultValue={socials.facebook}
                        />
                      </div>
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          Instagram
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={instagram}
                          onChange={(e) => setInstagram(e.currentTarget.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          Website
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={website}
                          onChange={(e) => setWebsite(e.currentTarget.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label className="font-weight-700 font-size-16">
                          YouTube
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={youtube}
                          onChange={(e) => setYoutube(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-actions mt-10">
                  <button
                    type="submit"
                    className="btn btn-danger left-button"
                    // onClick={(e) => promptForDelete()}
                  >
                    {" "}
                    <i className="fa fa-check"></i> {t("Ajouter")}
                  </button>
                  <Link>
                    <button type="button" className="btn btn-primary">
                      {t("Annuler")}
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Socials;
