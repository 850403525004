import axios from "axios";
import { getSession } from "../Utils/SessionUtils";
import EditOrganisation from "./../Containers/EditOrganisation";

const api_url = process.env.REACT_APP_API;

/* Setting the header for the axios request. */
const config = {
  headers: { Authorization: `Bearer ${getSession("token")}` },
};

export async function addOrganization(
  nom,
  logo,
  color,
  numero,
  description,
  user_id,
  tables,
  wifi,
  menu,
  currency,
  paiementmode,
  type,
  isActive
) {
  const frmData = new FormData();
  frmData.append("nom", nom);
  frmData.append("logo", logo);
  frmData.append("color", color);
  frmData.append("numero", numero);
  frmData.append("description", description);
  frmData.append("user_id", user_id);
  frmData.append("tables", tables);
  frmData.append("wifi", wifi);
  frmData.append("menu", menu);
  frmData.append("currency", currency);
  paiementmode.forEach((item) => {
    frmData.append(`paiementmode`, item);
  });
  frmData.append("type", type);
  frmData.append("isActive", isActive);

  const httpHeaders = {
    headers: {
      Authorization: `Bearer ${getSession("token")}`,
      "Content-Type": `multipart/form-data; boundary=${frmData._boundary}`,
    },
  };
  try {
    const response = await axios.post(
      api_url + "organisation",
      frmData,
      httpHeaders
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getUserOrganisations(userId) {
  const response = await axios.get(
    api_url + "organisation/user/" + userId,
    config
  );

  return response;
}
export async function getEvents(id) {
  try {
    const response = await axios.get(api_url + "organisation/events/" + id);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function editOrganisation(
  nom,
  numero,
  description,
  tables,
  wifi,
  logo,
  color,
  menu,
  currency,
  message,
  paiementmode,
  id
) {
  const frmData = new FormData();
  frmData.append("nom", nom);
  frmData.append("numero", numero);
  frmData.append("description", description);
  frmData.append("tables", tables);
  frmData.append("wifi", wifi);
  frmData.append("logo", logo);
  frmData.append("color", color);
  frmData.append("menu", menu);
  frmData.append("currency", currency);
  frmData.append("message", message);
  paiementmode.forEach((item) => {
    frmData.append(`paiementmode`, item);
  });
  frmData.append("id", id);
  const httpHeaders = {
    headers: {
      Authorization: `Bearer ${getSession("token")}`,
      "Content-Type": `multipart/form-data; boundary=${frmData._boundary}`,
    },
  };

  try {
    const response = await axios.patch(
      api_url + "organisation",
      frmData,
      httpHeaders
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getOrganisationById(id) {
  const response = await axios.get(api_url + "organisation/" + id);
  return response;
}
